import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "../Pages/HomePage";
import Registration from "../Pages/Registration";
import Programme from "../Pages/Programme";
import NavigationBar from "../components/NavigationBar";
import RegistrationDashboard from "../Pages/RegistrationDashboard";
import Printout from "../Pages/Printout";
import NotFound from "../Pages/NotFound";
import Notification from "../Pages/Notification";
import RetrieveRegistrationPage from "../Pages/RetrieveRegistrationPage";
import JambDataPage from "../Pages/JambDataPage";

const myRoutes = [
  { path: "/", component: HomePage },
  { path: "/registration", component: Notification },
  { path: "/secretregistration", component: Registration },
  { path: "/programme", component: Programme },
  { path: "/registrationdashboard", component: RegistrationDashboard },
  { path: "/printout/:id", component: Printout },
  { path: "/retrieveregistration", component: RetrieveRegistrationPage },
  { path: "/jambpage", component: JambDataPage },
  { path: "*", component: NotFound },
];

export default function MainRoute() {
  return (
    <BrowserRouter>
      <NavigationBar />
      <Routes>
        {myRoutes.map((myRoute) => (
          <Route path={myRoute.path} element={<myRoute.component />} />
        ))}
      </Routes>
    </BrowserRouter>
  );
}
