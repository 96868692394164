import "./App.css";
import MainRoute from "./Route/Route";
import "bootstrap/dist/css/bootstrap.min.css";
import { AlertContext } from "./contexts/AlertContext";
import React, { useState } from "react";
import { MySnackBarContext } from "./components/MySnackBar";

function App() {
  const [alertData, setAlertData] = useState({
    open: false,
    severity: "",
    message: "",
  });
  return (
    <>
      <AlertContext.Provider value={{ alertData, setAlertData }}>
        <MainRoute />

        <MySnackBarContext alertData={alertData} setAlertData={setAlertData} />
      </AlertContext.Provider>
    </>
  );
}

export default App;
