import React from "react";
import {
  aimText,
  themeText,
  headerText,
  venueText,
  introductionTexts,
  registerTexts,
  objectivesTexts,
  dateText,
} from "../utils/texts";
import { green } from "@mui/material/colors";
import { purple } from "@mui/material/colors";
import { Typography } from "@mui/material";
import img3 from "../assets/images/img3.jpeg";
import img1 from "../assets/images/img1.jpeg";
export default function HomePage() {
  return (
    <div className="mb-5">
      <div className="homeBanner d-flex align-items-center">
        <div className="container text-white mt-5 mb-5">
          {/* mobile */}
          <div className="d-sm-block d-md-none text-center">
            <div className="mb-2">
              <Typography variant="h5" fontWeight={700}>
                JAMB EQUAL OPPORTUNITY GROUP
              </Typography>
            </div>
            <hr />

            <Typography gutterBottom>{headerText}</Typography>
            <Typography color={green[500]} fontWeight={700} fontSize={20}>
              {themeText}
            </Typography>
          </div>
          {/* ipads */}
          <div className="d-none d-md-block d-lg-none text-center">
            <div className="mb-2">
              <Typography variant="h4" fontWeight={700}>
                JAMB EQUAL OPPORTUNITY GROUP
              </Typography>
            </div>
            <hr />

            <Typography gutterBottom>{headerText}</Typography>
            <Typography color={green[500]} fontWeight={700} fontSize={23}>
              {themeText}
            </Typography>
            <Typography color={purple[500]} fontWeight={600} fontSize={26}>
              {venueText}
            </Typography>
            <Typography color={purple[500]} fontWeight={600} fontSize={26}>
              {dateText}
            </Typography>
          </div>

          {/* desktop */}
          <div className="d-none d-lg-block">
            <div className="col-lg-6 ">
              <div className="mb-2">
                <Typography variant="h3" fontWeight={700}>
                  JAMB EQUAL OPPORTUNITY GROUP
                </Typography>
              </div>
              <hr />

              <Typography gutterBottom>{headerText}</Typography>
              <Typography color={green[500]} fontWeight={700} fontSize={23}>
                {themeText}
              </Typography>
              <Typography fontWeight={600} fontSize={26}>
                {venueText}
              </Typography>
              <Typography fontWeight={600} fontSize={26}>
                {dateText}
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-lg-6">
              <p style={{ fontWeight: 700, fontSize: 24, color: "#543293" }}>
                INTRODUCTION
              </p>

              <p>{introductionTexts.p1}</p>
              <button className="btn btn-link">read more</button>
            </div>
            <div className="col-lg-6">
              <img
                className="img-fluid rounded-3 shadow"
                src={img3}
                alt="blind 1"
              />
            </div>
          </div>
          <div className="mt-5">
            <p
              className="text-center"
              style={{ fontWeight: 700, fontSize: 24, color: "#543293" }}
            >
              AIMS & OBJECTIVES
            </p>
            <div className="mt-2">
              <div className="col-lg-4">
                <p>{aimText}</p>
                <hr />
              </div>
              <div className="mt-2">
                <div className="row">
                  <div className="col-lg-8">
                    {objectivesTexts.map((text, i) => (
                      <p>
                        {i + 1}. {text}
                      </p>
                    ))}
                  </div>
                  <div className="col-lg-4">
                    <img
                      className="img-fluid rounded-3 shadow"
                      src={img1}
                      alt="blind 2"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5">
            <p
              className="text-center"
              style={{ fontWeight: 700, fontSize: 24, color: "#543293" }}
            >
              REGISTRATION
            </p>
            <div className="mt-2">
              <div className="col-lg-4">
                <p> </p>
                <hr />
              </div>
              <div className="mt-2">
                <div className="row">
                  <div className="col-lg-4">
                    <p>{registerTexts.p1}</p>
                    <button className="btn btn-link">
                      Click here to register
                    </button>
                  </div>
                  <div className="col-lg-8">
                    <a href="../Registration">
                      {" "}
                      <img
                        className="img-fluid rounded-3 shadow"
                        src="https://img.freepik.com/premium-vector/register-now-icon-flat-style-registration-vector-illustration-isolated-background-member-notification-sign-business-concept_157943-834.jpg?size=626&ext=jpg"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
