import React, { useState, useContext } from "react";

import {
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { titles } from "../utils/texts";

import {
  InstitutionType,
  OtherInsitutionsRoles,
  OtherInstitutions,
  coeParticipants,
  degAwardingParticipants,
  ieiParticipants,
  institutions,
  polyTechnicParticipants,
  states,
  universityParticipants,
} from "./institutions";
import { httpService } from "../httpService";
import { AlertContext } from "../contexts/AlertContext";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { jambDepartments, jambRoles } from "./jambData";

export default function Registration() {
  const [registrationData, setRegistrationData] = useState({});
  const [belong, setBelong] = useState("");
  const [error, setError] = useState("");
  const [showTextBox, setShowTextBox] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otherRole, setOtherRole] = useState("");

  const navigate = useNavigate();

  const { setAlertData } = useContext(AlertContext);
  const handleChange = (event) => {
    if (event.target.name === "role" && event.target.value !== "Others") {
      setOtherRole("");
    }
    setRegistrationData({
      ...registrationData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // if (!registrationData.organisation || !registrationData.institution)
    //   return Swal.fire({
    //     icon: "error",
    //     title: "No Organisation or Institution",
    //     text: "Please indicate an organisation or institution you are coming from",
    //   });
    Swal.fire({
      icon: "question",
      title: "Register?",
      text: "Do you wish to proceed with your registration?",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);

        if (otherRole) {
          const newData = { ...registrationData, role: otherRole };
          const { data, error } = await httpService.post(
            "/api/register",
            newData
          );

          if (data) {
            navigate(`/printout/${data}`);
          }
          if (error)
            setAlertData({ message: error, severity: "error", open: true });
        } else {
          const { data, error } = await httpService.post(
            "/api/register",
            registrationData
          );

          if (data) {
            navigate(`/printout/${data}`);
          }
          if (error)
            setAlertData({ message: error, severity: "error", open: true });
        }

        setLoading(false);
      }
    });
  };

  return (
    <>
      <div>
        <div className="">
          <div className="container pb-3 pt-3">
            <h3>Registration Page</h3>
          </div>
        </div>
        <div className="container mt-5 mb-5">
          <div className="mt-4">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-lg-4">
                  <div className="mb-3">
                    <Typography fontWeight={700} gutterBottom>
                      Do you belong to a University, Polytechnic or College of
                      Education?
                    </Typography>
                    <div className="mt-2">
                      <RadioGroup row>
                        <FormControlLabel
                          value="yes"
                          onChange={(e) => {
                            setBelong(e.target.value);
                            setRegistrationData({
                              ...registrationData,
                              organisation: undefined,
                            });
                          }}
                          control={<Radio />}
                          label="YES"
                        />{" "}
                        <FormControlLabel
                          value="no"
                          onChange={(e) => {
                            setBelong(e.target.value);
                            setRegistrationData({
                              ...registrationData,
                              institution: undefined,
                              institutionId: undefined,
                              state: undefined,
                              category: undefined,
                            });
                          }}
                          control={<Radio />}
                          label="NO"
                        />
                      </RadioGroup>
                    </div>
                  </div>
                  {belong === "yes" && (
                    <>
                      <div className="mb-3">
                        <TextField
                          select
                          fullWidth
                          required
                          label="State"
                          helperText="Select state of institution"
                          onChange={(e) => {
                            setRegistrationData({
                              ...registrationData,
                              state: e.target.value.ST_NAME,
                              stateId: e.target.value.ST_ID,
                              region: e.target.value.REGION,
                            });
                          }}
                        >
                          {states.map((c, i) => (
                            <MenuItem key={i} value={c}>
                              {c.ST_NAME}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                      <div className="mb-3">
                        <TextField
                          select
                          required
                          fullWidth
                          label="Institution Category"
                          helperText="Select Institution category"
                          onChange={(e) =>
                            setRegistrationData({
                              ...registrationData,
                              category: e.target.value,
                            })
                          }
                        >
                          {InstitutionType.map((c) => (
                            <MenuItem value={c}>{c}</MenuItem>
                          ))}
                        </TextField>
                      </div>{" "}
                      <div className="mb-3">
                        <TextField
                          select
                          required
                          helperText={"Select Institution"}
                          label="Institution"
                          fullWidth
                          onChange={(e) =>
                            setRegistrationData({
                              ...registrationData,
                              institution: e.target.value.INName,
                              institutionId: e.target.value.INID,
                            })
                          }
                        >
                          {institutions
                            .filter((c) => {
                              return (
                                c.category === registrationData.category &&
                                c.InSt === registrationData.stateId
                              );
                            })
                            .map((d) => (
                              <MenuItem value={d}> {d.INName}</MenuItem>
                            ))}
                        </TextField>
                      </div>
                      <div className="mb-3">
                        <div>
                          {registrationData.institution &&
                          registrationData.category ? (
                            <>
                              <div className="mb-3">
                                {registrationData.category ===
                                InstitutionType[0] ? (
                                  <TextField
                                    fullWidth
                                    label="Role"
                                    helperText="Select Roles"
                                    select
                                    name="role"
                                    onChange={handleChange}
                                  >
                                    {universityParticipants.map((c) => (
                                      <MenuItem value={c.role}>
                                        {c.role}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                ) : null}
                                {registrationData.category ===
                                InstitutionType[1] ? (
                                  <TextField
                                    fullWidth
                                    label="Role"
                                    helperText="Select Roles"
                                    select
                                    name="role"
                                    onChange={handleChange}
                                  >
                                    {degAwardingParticipants.map((c) => (
                                      <MenuItem value={c.role}>
                                        {c.role}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                ) : null}
                                {registrationData.category ===
                                InstitutionType[2] ? (
                                  <TextField
                                    fullWidth
                                    label="Role"
                                    helperText="Select Roles"
                                    select
                                    name="role"
                                    onChange={handleChange}
                                  >
                                    {polyTechnicParticipants.map((c) => (
                                      <MenuItem value={c.role}>
                                        {c.role}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                ) : null}
                                {registrationData.category ===
                                InstitutionType[3] ? (
                                  <TextField
                                    fullWidth
                                    label="Role"
                                    helperText="Select Roles"
                                    select
                                    name="role"
                                    onChange={handleChange}
                                  >
                                    {coeParticipants.map((c) => (
                                      <MenuItem value={c.role}>
                                        {c.role}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                ) : null}{" "}
                                {registrationData.category ===
                                InstitutionType[4] ? (
                                  <TextField
                                    fullWidth
                                    label="Role"
                                    helperText="Select Roles"
                                    select
                                    name="role"
                                    onChange={handleChange}
                                  >
                                    {ieiParticipants.map((c) => (
                                      <MenuItem value={c.role}>
                                        {c.role}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                ) : null}
                              </div>
                              <div>
                                {registrationData.role === "Others" && (
                                  <TextField
                                    fullWidth
                                    label="Please specify"
                                    onChange={(e) =>
                                      setOtherRole(e.target.value)
                                    }
                                  />
                                )}
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </>
                  )}
                  {belong === "no" && (
                    <>
                      <div className="mb-3">
                        <TextField
                          fullWidth
                          select
                          label="Organisation or Institution"
                          name="organisation"
                          onChange={(e) => {
                            if (e.target.value !== "OTHERS") {
                              setRegistrationData({
                                ...registrationData,
                                institution: undefined,
                                organisation: e.target.value,
                              });
                              setShowTextBox(false);
                            } else {
                              setShowTextBox(true);
                            }
                          }}
                        >
                          {OtherInstitutions.map((c, i) => (
                            <MenuItem key={i} value={c}>
                              {c}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                      {/* {registrationData.organisation === "JAMB" && <div></div>} */}
                      {showTextBox && (
                        <div className="mb-3">
                          <TextField
                            multiline
                            fullWidth
                            required
                            label="Organisation name"
                            name="organisation"
                            onChange={handleChange}
                          />
                        </div>
                      )}
                      <div className="mb-3">
                        <TextField
                          fullWidth
                          select
                          label="Select Role"
                          name="role"
                          onChange={handleChange}
                        >
                          {registrationData.organisation === "JAMB" &&
                            jambRoles.map((c, i) => (
                              <MenuItem value={c} key={i}>
                                {c}
                              </MenuItem>
                            ))}
                          {registrationData.organisation !== "JAMB" &&
                            OtherInsitutionsRoles.map((c, i) => (
                              <MenuItem key={i} value={c}>
                                {c}
                              </MenuItem>
                            ))}
                        </TextField>
                      </div>
                      {registrationData.organisation === "JAMB" && (
                        <div>
                          <div className="mb-3">
                            <TextField
                              onChange={handleChange}
                              name="department"
                              fullWidth
                              label="Department"
                              select
                            >
                              {jambDepartments.map((c, i) => (
                                <MenuItem key={i} value={c}>
                                  {c}
                                </MenuItem>
                              ))}
                            </TextField>
                          </div>
                          <div className="mb-3">
                            <TextField
                              fullWidth
                              label="ID Card"
                              name="idCard"
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div className="col-lg-4">
                  <div className="mb-3">
                    <TextField
                      label="Title"
                      fullWidth
                      select
                      name="title"
                      onChange={handleChange}
                    >
                      {titles.map((c) => (
                        <MenuItem value={c}>{c}</MenuItem>
                      ))}
                    </TextField>
                  </div>
                  <div className="mb-3">
                    <TextField
                      label="First Name"
                      fullWidth
                      required
                      name="firstName"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3">
                    <TextField
                      label="Last Name"
                      fullWidth
                      name="lastName"
                      required
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3">
                    <TextField
                      label="Email"
                      fullWidth
                      name="email"
                      required
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3">
                    <TextField
                      label="Phone Number"
                      fullWidth
                      required
                      type="number"
                      helperText={error}
                      error={error ? true : false}
                      value={registrationData.phoneNumber}
                      onChange={(e) => {
                        if (e.target.value.charAt(0) === "0") {
                          if (e.target.value.length < 12)
                            setRegistrationData({
                              ...registrationData,
                              phoneNumber: e.target.value,
                            });
                          else e.target.value = "";
                        } else e.target.value = "";
                      }}
                      onBlur={(e) => {
                        if (e.target.value.length !== 11) {
                          setError("Phone number is incomplete");
                        } else setError("");
                      }}
                    />
                  </div>

                  <div>
                    <LoadingButton
                      disabled={error ? true : false}
                      variant="contained"
                      fullWidth
                      type="submit"
                      loading={loading}
                    >
                      register
                    </LoadingButton>
                  </div>
                </div>
                <div className="col-lg-4">
                  <img
                    className="img-fluid"
                    src="https://images.pexels.com/photos/7265503/pexels-photo-7265503.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
