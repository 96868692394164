import { LoadingButton } from "@mui/lab";
import { TextField, Typography } from "@mui/material";
import React, { useState, useContext } from "react";
import { httpService } from "../httpService";
import { useNavigate } from "react-router-dom";
import { AlertContext } from "../contexts/AlertContext";

export default function RetrieveRegistrationPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const { setAlertData } = useContext(AlertContext);
  const getData = async () => {
    setLoading(true);
    const { data, error } = await httpService.post(
      "/api/retrieveregistration",
      {
        email: email.trim(),
      }
    );

    if (data) {
      navigate(`/printout/${data}`);
    }
    if (error) {
      setAlertData({ open: true, message: error, severity: "error" });
    }
    setLoading(false);
  };
  return (
    <div>
      <div className="mt-5 container">
        <Typography variant="h5" fontWeight={700} color="GrayText">
          Retrieve Registration
        </Typography>

        <div className="mt-4 col-lg-4">
          <div className="mb-3">
            <TextField
              fullWidth
              label="Email Address"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <LoadingButton
            loading={loading}
            onClick={getData}
            variant="contained"
          >
            get registration
          </LoadingButton>
        </div>
      </div>
    </div>
  );
}
