export const introductionTexts = {
  p1: "In 2017, under the leadership of Professor Is-haq Oloyede, OFR, FNAL, HLR, the Joint Admissions and Matriculation Board set up the JAMB Equal Opportunity Group (JEOG) under the chairmanship of Prof Peter Okebukola, OFR, with the singular mandate to assist JAMB conduct the Unified Tertiary Matriculation Examination (UTME) for blind candidates and others with disabilities such as autism and Down Syndrome. This is in furtherance of the goal of the Oloyede-led JAMB to ensure that no eligible Nigerian is denied the opportunity of taking the UTME regardless of disability. Prof Oloyede is of the strong view that a level playing field should be provided for all candidates as long as they are intellectually capable. He believes that any candidate who satisfies the minimum conditions for admission into the university/polytechnic/college of education of their choice, (he/she) should have a good chance of admission placement.",
};

export const headerText =
  "First National Workshop on Equal Opportunity of Access to Higher Education in Nigeria";
export const themeText =
  "THEME: Towards Increasing Equal Opportunity of Access to Higher Education in Nigeria";
export const venueText = "Venue: Ladi Kwali Hall, Sheraton Hotel, Abuja.";
export const dateText = "Date: 25th - 26th September, 2023";
export const aimText =
  "Our aim is to improve access to higher education for persons with Persons with Disabilities (PWD and to improve the learning environment in tertiary institutions for such persons";

export const objectivesTexts = [
  "To undertake situation analysis of opportunities and access to higher education of Persons with Disabilities (PWD especially the blind, albinos, persons with autism, Down syndrome and physically challenged and in correctional centres;",
  "to review the conduct of the Unified Tertiary Matriculation Examinations (UTME) for candidates with special needs; ",
  "to discuss the challenges facing candidates with special needs in gaining admission into Nigerian tertiary institutions;",
  "to share experiences on modalities of admission and retention of persons with disabilities",
  "to discuss the challenges confronting students with disabilities in the Nigerian higher education system;",
  "to propose sustainable and creative solutions to the challenge posed by the increasing demand for access to higher education by persons with disabilities; and",
  "to propose changes that can be made to national policies that will potentially address unequal access to higher education, especially by the blind, albinos, persons with autism, Down syndrome the physically challenged and others in correctional centres",
];

export const titles = ["Prof.", "Dr.", "Mr.", "Mrs.", "Ms."];
export const instcat = [
  "University",
  "Polytechnic",
  "College of Education",
  "Nigeria Association of the Blind",
  "Joint National Association of Persons with Disabilities",
  "JAMB",
  "WAEC",
  "NECO",
  "NABTEB",
  "The Albino Foundation",
  "Nigeria National Association of Deaf",
  "National Parents-Teachers Association of Nigeria",
  "Staff unions of universities, polytechnics and colleges of education",
  "NANS",
  "OTHERS",
];

export const registerTexts = [""];
