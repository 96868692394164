import React, { useState } from "react";

export default function Programme() {
  return (
    <>
      <div>
        <div className="">
          <div className="container pb-3 pt-3">
            <h3>Programme Outline</h3>
          </div>
        </div>

        <div className="container mt-5 mb-5">
          <div className="mt-12">
            <div className="">
              <div className="container pb-3 pt-3">
                <h3>September 24, 2023 - Arrival</h3>
              </div>
            </div>
            <div className="">
              <div className="container pb-3 pt-3">
                <h3>Day 1- September 25, 2022</h3>
              </div>
            </div>
            <div class="table-responsive">
              <table className="table table-bordered table-lg table-success table-striped">
                <thead>
                  <tr>
                    <th scope="col">Time</th> <th scope="col">Event</th>
                  </tr>{" "}
                </thead>
                <tbody>
                  <tr scope="row">
                    <td>8:00 a.m. </td> <td>Registration </td>
                  </tr>
                  <tr scope="row">
                    <td>9:00 a.m. </td> <td>Participants are seated </td>
                  </tr>
                  <tr scope="row">
                    <td>10:00 a.m. - 11.30a.m. Opening ceremony </td>{" "}
                    <td>
                      <b>National Anthem</b>
                      <b>Welcome address</b> by the JAMB Registrar <br />
                      <b>Goodwill messages</b>
                      <br />
                      <b>Keynote address</b> by the Honourable Minister of
                      Education
                      <br /> <br />
                      <b>JAMB Awards-</b> (1) Top three Universities,
                      Polytechnics and Colleges of Education with highest
                      admission of blind and special group candidates in 2022{" "}
                      <br />
                      <b>
                        Unveiling of the Roadmap for Inclusive Access to Quality
                        Higher Education in Nigeria (2024-2028 Strategic Plan){" "}
                      </b>
                    </td>
                  </tr>
                  <tr scope="row">
                    <td>11:30 a.m. to 12 noon </td> <td>Tea/coffee break </td>
                  </tr>
                  <tr scope="row">
                    <td>12:00 – 1.00 p.m. </td>{" "}
                    <td>
                      {" "}
                      <b>First Plenary Session </b> <br /> <br />
                      <b>Chair:</b> Chairman, Senate Committee on Tertiary
                      Institutions and TETFund <br />
                      <b> Co-Chair:</b> Professor Ruqayyatu Ahmed Rufa’i
                      <br />
                      <br /> <br />
                      <b>Title:</b> Equal Opportunity of Access to Higher
                      Education in Nigeria and the Role of JAMB <br /> Speaker:
                      Professor Is-haq Oloyede, Registrar, JAMB <br /> <br />
                      Discussions and Recommendations
                      <br />
                    </td>
                  </tr>
                  <tr scope="row">
                    <td>1:00 to 2:00 p.m.</td> <td>Lunch break</td>
                  </tr>
                  <tr scope="row">
                    {" "}
                    <td>2:15 to 3:30 p.m.</td>
                    <td>
                      <b> Second Plenary Session</b>
                      <br /> <br />
                      <b>Chair:</b> Chairman, House Committee on Tertiary
                      Institutions and TETFund
                      <br /> <b>Co-Chair:</b> : Professor Chinwe Obaji
                      <br /> <b>Rapporteur:</b> JAMB
                      <br /> <br /> <b>Title:</b> Equal Opportunity of Access to
                      Higher Education in Nigeria Perspectives from regulatory
                      agencies
                      <br />
                      • Universities- Executive Secretary, NUC
                      <br />
                      • Polytechnics- Executive Secretary, NBTENUC
                      <br />• Colleges of Education- Executive Secretary, NCCE
                    </td>
                  </tr>
                  <tr scope="row">
                    <td>3:45 to 5:00 p.m.</td>{" "}
                    <td>
                      <b>Third Plenary Session</b>
                      <br />
                      Panel Discussion <br /> <br />
                      <b> Topic:</b> Field Experiences on Challenges and
                      Solutions to Assuring Equal Opportunity of Access to
                      Higher Education in Nigeria
                      <br />
                      <b> Chair:</b> The Chairman, House of Representatives
                      Committee on Disability matters <br />
                      <b> Moderator:</b> Peter A. Okebukola, Chairman, JAMB
                      Equal Opportunity Group <br />
                      <br />
                      <br />
                      <b>Discussants</b>
                      <br />
                      1. Professor Muhammad Yahuza Bello, Vice-Chairman, JAMB
                      Equal Opportunity Group
                      <br />
                      2. Director, ITS, JAMB <br />
                      3. Coordinator/Head of Operations, JAMB PTC, Mrs. Shanpepe
                      Ahee
                      <br />
                      4. National President, Joint National Association of
                      Persons with Disabilities
                      <br />
                      5. President, The Albino Foundation, Mr. Jake Epelle
                      <br />
                      6. President Nigeria Association of the Blind, Mr.
                      Ishiyaku Adamu
                      <br />
                      <br />
                      <br />
                      General Discussions and Recommendations
                    </td>
                  </tr>
                  <tr scope="row">
                    <td>5:00 p.m. </td> <td>End of Day 1</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="container mt-5 mb-5">
          <div className="mt-12">
            <div className="">
              <div className="container pb-3 pt-3">
                <h3>Day 2: September 26, 2022</h3>
              </div>
            </div>
            <div class="table-responsive">
              <table className="table table-bordered table-lg table-success table-striped">
                <thead>
                  <tr>
                    <th scope="col">Time</th> <th scope="col">Event</th>
                  </tr>{" "}
                </thead>
                <tbody>
                  <tr scope="row">
                    <td>9:00 a.m. </td> <td>Participants are seated </td>
                  </tr>
                  <tr scope="row">
                    <td>9:00 a.m. -10.30a.m. </td>{" "}
                    <td>
                      <b>Fourth Plenary Session </b> <br /> <br />
                      <b>Chairman:</b> Chairman, Committee of Vice-Chancellors
                      of Nigerian Universities
                      <br />
                      <b>Co-Chairman:</b> Jake Epelle, President, The Albino
                      Foundation <br />
                      <b>Rapporteur:</b>JAMB <br /> <br />
                      <b>Topic: </b> Experiences of Blind and other Special
                      Needs Students in Higher Institutions in Nigeria:
                      Challenges and Solutions to Retention and Graduation
                      <br /> <br />
                      <b>Speakers</b> <br />
                      1. A male blind student in a Nigerian university,
                      polytechnic or college of education
                      <br />
                      2. A female blind student in a Nigerian university,
                      polytechnic or college of education
                      <br />
                      3. A male deaf student in a Nigerian university,
                      polytechnic or college of education
                      <br />
                      4. A female deaf student in a Nigerian university,
                      polytechnic or college of education
                      <br />
                      5. Two students with other disabilities in a Nigerian
                      university, polytechnic or college of education
                      Discussions and Recommendations
                      <br />
                    </td>
                  </tr>
                  <tr scope="row">
                    <td>10:30 a.m. - 11.30a.m. </td>{" "}
                    <td>
                      <b>Fifth Plenary Session</b>
                      <br /> <br />
                      <b>Chairman: </b>Professor Taoheed Adedoja, former
                      Minister of Sports <br />
                      <b>Co-Chairman: </b>Emeritus Professor Mosto Onuoha,
                      former President, Nigerian Academy of Science <br />
                      <b>Topic:</b> Role of Stakeholders in the Realisation of
                      the JAMB Equal Opportunity Agenda <br /> <br />
                      <b>Speakers</b>
                      <br />
                      1. National Parents-Teachers Association of Nigeria
                      (NAPTAN)
                      <br />
                      2. President, Nigerian Guild of Editors or CEO of a media
                      conglomerate
                      <br />
                      3. National President, NANS <br />
                      4. Principal, FCT School for the blind children
                      <br />
                    </td>
                  </tr>
                  <tr scope="row">
                    <td>11:30 a.m. to 12 noon </td> <td>Tea/coffee break </td>
                  </tr>
                  <tr scope="row">
                    <td>12:00 – 1.00 p.m. </td>{" "}
                    <td>
                      {" "}
                      <b>Sixth Plenary Session </b> <br /> <br />
                      <b>Chair:</b> Professor N.Y.S. Ijaya, Al Hikmah
                      University, Ilorin <br />
                      <b>Co-Chair:</b> Professor Uchenna Nzewi, University of
                      Nigeria, Nsukka
                      <br /> <br />
                      <b>Title:</b>
                      Inclusivity in higher education in Nigeria: Practical
                      steps towards implementing the UN Convention on the Rights
                      of Persons with Disabilities and Relevant National
                      Policies. <br /> <br />
                      <b>Speakers:</b> <br />
                      1. Representative of UNESCO Multisectoral Office, Abuja
                      <br />
                      2. President of the Nigeria Association of the Blind
                      <br />
                      3. President, the Nigeria National Association of the Deaf
                      <br />
                      4. National President, Joint National Association of
                      Persons with Disabilities
                    </td>
                  </tr>
                  <tr scope="row">
                    <td>1:00 – 2.00 p.m. </td>{" "}
                    <td>
                      {" "}
                      <b>Seventh Plenary Session</b> <br /> <br />
                      <b>Chair and Presenter:</b> Emeritus Professor Peter
                      Okebukola, Chairman, JAMB Equal Opportunity Group (JAMB)
                      <br />
                      <br /> <br />
                      <b>Title:</b>
                      Highlights of the 2024-2028 Roadmap (Strategic Plan) for
                      Inclusive Access to Quality Higher Education in Nigeria
                      <br /> <br />
                      <b>Discussants:</b> <br />
                      1. Prof. Taoheed Adedoja
                      <br />
                      2. Professor Muhammad Yahuza Bello
                      <br />
                      3. Professor Mosto Onuoha
                      <br />
                    </td>
                  </tr>
                  <tr scope="row">
                    <td>2:00 to 3:00 p.m.</td> <td>Lunch break</td>
                  </tr>
                  <tr scope="row">
                    {" "}
                    <td>3:00 to 4:00 p.m.</td>
                    <td>
                      Eihth Plenary Session
                      <br /> <br />
                      <b>Chair:</b> Professor Sunday Ododo, JEOG Abuja Centre
                      Coordinator
                      <br /> <b>Co-Chair:</b> : Professor Salisu Shehu, JEOG
                      Bauchi Centre Coordinator
                      <br />
                      <br /> <b>Title:</b> Challenges and solutions to the
                      conduct of UTME for blind candidates and other special
                      groups
                      <br /> <br />
                      <b>Speakers</b>
                      1. Prof. Aderinoye Rashid A. (Ado-Ekiti Centre)
                      <br />
                      2. Prof. Asabe Kabir (Kebbi Centre)
                      <br />
                      3. Prof. M. N. Maiturare (Jos Centre)
                      <br />
                      4. Prof. Muhammad I. Yakasai (Yola Centre)
                    </td>
                  </tr>
                  <tr scope="row">
                    <td>4:00 to 4:45 p.m.</td>{" "}
                    <td>
                      <b>Eighth Plenary (Closing) Session</b>
                      <br /> <br />
                      <b>Chair</b>Professor Is-haq Oloyede, Registrar/CEO JAMB{" "}
                      <br />
                      1. Closing statements
                      <br />
                      2. Communique and Draft Action Plan- Rapporteur-General,
                      Professor Afis Oladosu
                      <br />
                      3. Conference adjourns
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="">
                <div className="container pb-3 pt-3">
                  <h3> September 27, 2023 - Departure</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
