const rawInstitutions = [
  {
    INID: 1101,
    INABV: "ABU",
    INName: "AHMADU BELLO UNIVERSITY, ZARIA, KADUNA STATE",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 11,
    Inst_Cat: "FU",
    category: "University",
  },
  {
    INID: 1102,
    INABV: "ABUJA",
    INName: "UNIVERSITY OF ABUJA, GWAGWALADA, ABUJA, FCT",
    InSt: 37,
    AFFL_ST_ID: 37,
    INTYP: 11,
    Inst_Cat: "FU",
    category: "University",
  },
  {
    INID: 1103,
    INABV: "BAYERO",
    INName: "BAYERO UNIVERSITY, KANO, KANO STATE",
    InSt: 19,
    AFFL_ST_ID: 19,
    INTYP: 11,
    Inst_Cat: "FU",
    category: "University",
  },
  {
    INID: 1104,
    INABV: "BENIN",
    INName: "UNIVERSITY OF BENIN, BENIN CITY, EDO STATE",
    InSt: 12,
    AFFL_ST_ID: 12,
    INTYP: 11,
    Inst_Cat: "FU",
    category: "University",
  },
  {
    INID: 1105,
    INABV: "CALABAR",
    INName: "UNIVERSITY OF CALABAR, CALABAR, CROSS RIVER STATE",
    InSt: 9,
    AFFL_ST_ID: 9,
    INTYP: 11,
    Inst_Cat: "FU",
    category: "University",
  },
  {
    INID: 1106,
    INABV: "FED-GASHUA",
    INName: "FEDERAL UNIVERSITY, GASHUA, YOBE STATE",
    InSt: 35,
    AFFL_ST_ID: 35,
    INTYP: 11,
    Inst_Cat: "FU",
    category: "University",
  },
  {
    INID: 1107,
    INABV: "FED-GUSAU",
    INName: "FEDERAL UNIVERSITY, GUSAU, ZAMFARA STATE",
    InSt: 36,
    AFFL_ST_ID: 36,
    INTYP: 11,
    Inst_Cat: "FU",
    category: "University",
  },
  {
    INID: 1108,
    INABV: "FED-KEBBI",
    INName: "FEDERAL UNIVERSITY, BIRNIN-KEBBI, KEBBI STATE",
    InSt: 21,
    AFFL_ST_ID: 21,
    INTYP: 11,
    Inst_Cat: "FU",
    category: "University",
  },
  {
    INID: 1109,
    INABV: "FED-LOKOJA",
    INName: "FEDERAL UNIVERSITY, LOKOJA, KOGI STATE",
    InSt: 22,
    AFFL_ST_ID: 22,
    INTYP: 11,
    Inst_Cat: "FU",
    category: "University",
  },
  {
    INID: 1110,
    INABV: "FED-LAFIA",
    INName: "FEDERAL UNIVERSITY, LAFIA, NASARAWA STATE",
    InSt: 25,
    AFFL_ST_ID: 25,
    INTYP: 11,
    Inst_Cat: "FU",
    category: "University",
  },
  {
    INID: 1111,
    INABV: "FED-KASHERE",
    INName: "FEDERAL UNIVERSITY, KASHERE, GOMBE STATE",
    InSt: 15,
    AFFL_ST_ID: 15,
    INTYP: 11,
    Inst_Cat: "FU",
    category: "University",
  },
  {
    INID: 1112,
    INABV: "FED-WUKARI",
    INName: "FEDERAL UNIVERSITY, WUKARI, TARABA STATE",
    InSt: 34,
    AFFL_ST_ID: 34,
    INTYP: 11,
    Inst_Cat: "FU",
    category: "University",
  },
  {
    INID: 1113,
    INABV: "FED-DUTSINMA",
    INName: "FEDERAL UNIVERSITY, DUTSIN-MA, KATSINA STATE",
    InSt: 20,
    AFFL_ST_ID: 20,
    INTYP: 11,
    Inst_Cat: "FU",
    category: "University",
  },
  {
    INID: 1114,
    INABV: "FED-DUTSE",
    INName: "FEDERAL UNIVERSITY, DUTSE, JIGAWA STATE",
    InSt: 17,
    AFFL_ST_ID: 17,
    INTYP: 11,
    Inst_Cat: "FU",
    category: "University",
  },
  {
    INID: 1115,
    INABV: "AEFUNA",
    INName: "ALEX EKWUEME FEDERAL UNIVERSITY, NDUFU-ALIKE, EBONYI STATE",
    InSt: 11,
    AFFL_ST_ID: 11,
    INTYP: 11,
    Inst_Cat: "FU",
    category: "University",
  },
  {
    INID: 1116,
    INABV: "FED-OYE EKITI",
    INName: "FEDERAL UNIVERSITY, OYE-EKITI, EKITI STATE",
    InSt: 13,
    AFFL_ST_ID: 13,
    INTYP: 11,
    Inst_Cat: "FU",
    category: "University",
  },
  {
    INID: 1117,
    INABV: "FED-OTUOKE",
    INName: "FEDERAL UNIVERSITY, OTUOKE, BAYELSA STATE",
    InSt: 6,
    AFFL_ST_ID: 6,
    INTYP: 11,
    Inst_Cat: "FU",
    category: "University",
  },
  {
    INID: 1118,
    INABV: "IBADAN",
    INName: "UNIVERSITY OF IBADAN, IBADAN, OYO STATE",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 11,
    Inst_Cat: "FU",
    category: "University",
  },
  {
    INID: 1119,
    INABV: "ILORIN",
    INName: "UNIVERSITY OF ILORIN, ILORIN, KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 23,
    INTYP: 11,
    Inst_Cat: "FU",
    category: "University",
  },
  {
    INID: 1120,
    INABV: "JOS",
    INName: "UNIVERSITY OF JOS, JOS, PLATEAU STATE",
    InSt: 31,
    AFFL_ST_ID: 31,
    INTYP: 11,
    Inst_Cat: "FU",
    category: "University",
  },
  {
    INID: 1121,
    INABV: "LAGOS",
    INName: "UNIVERSITY OF LAGOS, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 11,
    Inst_Cat: "FU",
    category: "University",
  },
  {
    INID: 1122,
    INABV: "MAIDUGURI",
    INName: "UNIVERSITY OF MAIDUGURI, BORNO STATE",
    InSt: 8,
    AFFL_ST_ID: 8,
    INTYP: 11,
    Inst_Cat: "FU",
    category: "University",
  },
  {
    INID: 1123,
    INABV: "NDA",
    INName: "NIGERIAN DEFENCE ACADEMY, KADUNA, KADUNA STATE",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 11,
    Inst_Cat: "FU",
    category: "University",
  },
  {
    INID: 1124,
    INABV: "OAU",
    INName: "OBAFEMI AWOLOWO UNIVERSITY, ILE-IFE, OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 11,
    Inst_Cat: "FU",
    category: "University",
  },
  {
    INID: 1125,
    INABV: "PHC",
    INName: "UNIVERSITY OF PORT-HARCOURT, RIVERS STATE",
    InSt: 32,
    AFFL_ST_ID: 32,
    INTYP: 11,
    Inst_Cat: "FU",
    category: "University",
  },
  {
    INID: 1126,
    INABV: "POL-ACAD",
    INName: "NIGERIAN POLICE ACADEMY, WUDIL, KANO STATE",
    InSt: 19,
    AFFL_ST_ID: 19,
    INTYP: 11,
    Inst_Cat: "FU",
    category: "University",
  },
  {
    INID: 1127,
    INABV: "UDU",
    INName: "USMANU  DANFODIYO UNIVERSITY, SOKOTO, SOKOTO STATE",
    InSt: 33,
    AFFL_ST_ID: 33,
    INTYP: 11,
    Inst_Cat: "FU",
    category: "University",
  },
  {
    INID: 1128,
    INABV: "UNIZIK",
    INName: "NNAMDI AZIKIWE UNIVERSITY, AWKA, ANAMBRA STATE",
    InSt: 4,
    AFFL_ST_ID: 4,
    INTYP: 11,
    Inst_Cat: "FU",
    category: "University",
  },
  {
    INID: 1129,
    INABV: "UNN",
    INName: "UNIVERSITY OF NIGERIA, NSUKKA, ENUGU STATE",
    InSt: 14,
    AFFL_ST_ID: 14,
    INTYP: 11,
    Inst_Cat: "FU",
    category: "University",
  },
  {
    INID: 1130,
    INABV: "UYO",
    INName: "UNIVERSITY OF UYO, AKWA IBOM STATE",
    InSt: 3,
    AFFL_ST_ID: 3,
    INTYP: 11,
    Inst_Cat: "FU",
    category: "University",
  },
  {
    INID: 1131,
    INABV: "JSTU",
    INName: "JOSEPH SARWUAN TARKA UNIVERSITY, MAKURDI, BENUE STATE",
    InSt: 7,
    AFFL_ST_ID: 7,
    INTYP: 11,
    Inst_Cat: "FU",
    category: "University",
  },
  {
    INID: 1132,
    INABV: "FUNAAB",
    INName: "FEDERAL UNIVERSITY OF AGRICULTURE, ABEOKUTA, OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 27,
    INTYP: 11,
    Inst_Cat: "FU",
    category: "University",
  },
  {
    INID: 1133,
    INABV: "UMUDIKE",
    INName: "MICHAEL OKPARA UNIVERSITY OF AGRICULTURE, UMUDIKE, ABIA STATE",
    InSt: 1,
    AFFL_ST_ID: 1,
    INTYP: 11,
    Inst_Cat: "FU",
    category: "University",
  },
  {
    INID: 1134,
    INABV: "BAUCHI",
    INName: "ABUBAKAR TAFAWA BALEWA UNIVERSITY, BAUCHI, BAUCHI STATE",
    InSt: 5,
    AFFL_ST_ID: 5,
    INTYP: 11,
    Inst_Cat: "FU",
    category: "University",
  },
  {
    INID: 1135,
    INABV: "FUPRE",
    INName: "FEDERAL UNIVERSITY OF PETROLEUM RESOURCES, EFFURUN, DELTA STATE",
    InSt: 10,
    AFFL_ST_ID: 10,
    INTYP: 11,
    Inst_Cat: "FU",
    category: "University",
  },
  {
    INID: 1136,
    INABV: "FUTA",
    INName: "FEDERAL UNIVERSITY OF TECHNOLOGY, AKURE, ONDO STATE",
    InSt: 28,
    AFFL_ST_ID: 28,
    INTYP: 11,
    Inst_Cat: "FU",
    category: "University",
  },
  {
    INID: 1137,
    INABV: "FUTO",
    INName: "FEDERAL UNIVERSITY OF TECHNOLOGY, OWERRI, IMO STATE",
    InSt: 16,
    AFFL_ST_ID: 16,
    INTYP: 11,
    Inst_Cat: "FU",
    category: "University",
  },
  {
    INID: 1138,
    INABV: "FUT-MINNA",
    INName: "FEDERAL UNIVERSITY OF TECHNOLOGY, MINNA, NIGER STATE",
    InSt: 26,
    AFFL_ST_ID: 26,
    INTYP: 11,
    Inst_Cat: "FU",
    category: "University",
  },
  {
    INID: 1139,
    INABV: "MAUTY",
    INName: "MODIBBO ADAMA UNIVERSITY, YOLA, ADAMAWA STATE",
    InSt: 2,
    AFFL_ST_ID: 2,
    INTYP: 11,
    Inst_Cat: "FU",
    category: "University",
  },
  {
    INID: 1140,
    INABV: "NOUN",
    INName: "NATIONAL OPEN UNIVERSITY OF NIGERIA, JABI, ABUJA-FCT",
    InSt: 37,
    AFFL_ST_ID: 37,
    INTYP: 11,
    Inst_Cat: "FU",
    category: "University",
  },
  {
    INID: 1141,
    INABV: "MARITIME-U",
    INName: "NIGERIAN MARITIME UNIVERSITY, OKERENKOKO, DELTA STATE",
    InSt: 10,
    AFFL_ST_ID: 10,
    INTYP: 11,
    Inst_Cat: "FU",
    category: "University",
  },
  {
    INID: 1142,
    INABV: "NAUB",
    INName: "NIGERIAN ARMY UNIVERSITY, BIU, BORNO STATE",
    InSt: 8,
    AFFL_ST_ID: 8,
    INTYP: 11,
    Inst_Cat: "FU",
    category: "University",
  },
  {
    INID: 1143,
    INABV: "MAID-SDWCH",
    INName: "UNIVERSITY OF MAIDUGURI SANDWICH PROGRAMME, BORNO STATE",
    InSt: 8,
    AFFL_ST_ID: 8,
    INTYP: 11,
    Inst_Cat: "FU",
    category: "University",
  },
  {
    INID: 1144,
    INABV: "FUHSO",
    INName: "FEDERAL UNIVERSITY OF HEALTH SCIENCES, OTUKPO, BENUE STATE",
    InSt: 7,
    AFFL_ST_ID: 7,
    INTYP: 11,
    Inst_Cat: "FU",
    category: "University",
  },
  {
    INID: 1145,
    INABV: "FUA-ZURU",
    INName: "FEDERAL UNIVERSITY OF AGRICULTURE, ZURU, KEBBI STATE",
    InSt: 21,
    AFFL_ST_ID: 21,
    INTYP: 11,
    Inst_Cat: "FU",
    category: "University",
  },
  {
    INID: 1146,
    INABV: "FUHSA",
    INName: "FEDERAL UNIVERSITY OF HEALTH SCIENCES, AZARE, BAUCHI STATE",
    InSt: 5,
    AFFL_ST_ID: 5,
    INTYP: 11,
    Inst_Cat: "FU",
    category: "University",
  },
  {
    INID: 1147,
    INABV: "FUHS-ILA",
    INName: "FEDERAL UNIVERSITY OF HEALTH SCIENCES, ILA-ORANGUN, OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 11,
    Inst_Cat: "FU",
    category: "University",
  },
  {
    INID: 1148,
    INABV: "FUT-BABURA",
    INName: "FEDERAL UNIVERSITY OF TECHNOLOGY, BABURA, JIGAWA STATE",
    InSt: 17,
    AFFL_ST_ID: 17,
    INTYP: 11,
    Inst_Cat: "FU",
    category: "University",
  },
  {
    INID: 1201,
    INABV: "AAU",
    INName: "AMBROSE ALLI UNIVERSITY, EKPOMA, EDO STATE",
    InSt: 12,
    AFFL_ST_ID: 12,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1202,
    INABV: "AAUA",
    INName: "ADEKUNLE AJASIN UNIVERSITY, AKUNGBA-AKOKO, ONDO STATE",
    InSt: 28,
    AFFL_ST_ID: 28,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1203,
    INABV: "ABSU",
    INName: "ABIA STATE UNIVERSITY, UTURU, ABIA STATE",
    InSt: 1,
    AFFL_ST_ID: 1,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1204,
    INABV: "ADSU",
    INName: "ADAMAWA STATE UNIVERSITY, MUBI, ADAMAWA STATE",
    InSt: 2,
    AFFL_ST_ID: 2,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1205,
    INABV: "AKSU",
    INName: "AKWA IBOM STATE  UNIVERSITY, IKOT-AKPADEN, AKWA IBOM STATE",
    InSt: 3,
    AFFL_ST_ID: 3,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1206,
    INABV: "COOU",
    INName: "CHUKWUEMEKA ODUMEGWU OJUKWU UNIVERSITY, ULI, ANAMBRA STATE",
    InSt: 4,
    AFFL_ST_ID: 4,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1207,
    INABV: "BASU",
    INName: "BAUCHI STATE UNIVERSITY, GADAU, BAUCHI STATE",
    InSt: 5,
    AFFL_ST_ID: 5,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1208,
    INABV: "BSU",
    INName: "BENUE STATE UNIVERSITY, MAKURDI, BENUE STATE",
    InSt: 7,
    AFFL_ST_ID: 7,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1209,
    INABV: "CRUTECH",
    INName: "CROSS RIVER UNIVERSITY OF TECHNOLOGY, CALABAR, CROSS RIVERS STATE",
    InSt: 9,
    AFFL_ST_ID: 9,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1210,
    INABV: "DELSU",
    INName: "DELTA STATE UNIVERSITY, ABRAKA, DELTA STATE",
    InSt: 10,
    AFFL_ST_ID: 10,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1211,
    INABV: "EBSU",
    INName: "EBONYI STATE UNIVERSITY, ABAKALIKI, EBONYI STATE",
    InSt: 11,
    AFFL_ST_ID: 11,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1212,
    INABV: "EKSU",
    INName: "EKITI STATE UNIVERSITY, ADO-EKITI, EKITI STATE",
    InSt: 13,
    AFFL_ST_ID: 13,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1213,
    INABV: "ESUTECH",
    INName:
      "ENUGU STATE UNIVERSITY OF SCIENCE AND TECHNOLOGY, ENUGU, ENUGU STATE",
    InSt: 14,
    AFFL_ST_ID: 14,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1214,
    INABV: "GSU",
    INName: "GOMBE STATE UNIVERSITY,  TUDUN WADA, GOMBE, GOMBE STATE",
    InSt: 15,
    AFFL_ST_ID: 15,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1215,
    INABV: "IAUED",
    INName:
      "IGNATIUS AJURU UNIVERSITY OF EDUCATION, PORT HARCOURT, RIVERS STATE",
    InSt: 32,
    AFFL_ST_ID: 32,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1216,
    INABV: "IMSU",
    INName: "IMO STATE UNIVERSITY, OWERRI, IMO STATE",
    InSt: 16,
    AFFL_ST_ID: 16,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1217,
    INABV: "SLUK",
    INName: "SULE LAMIDO UNIVERSITY, KAFIN HAUSA, JIGAWA STATE",
    InSt: 17,
    AFFL_ST_ID: 17,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1218,
    INABV: "KASU",
    INName: "KADUNA STATE UNIVERSITY, KADUNA, KADUNA STATE",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1219,
    INABV: "PAAU",
    INName: "PRINCE ABUBAKAR AUDU UNIVERSITY, ANYIGBA, KOGI STATE",
    InSt: 22,
    AFFL_ST_ID: 22,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1220,
    INABV: "KSUSTA",
    INName:
      "KEBBI STATE UNIVERSITY OF SCIENCE AND TECHNOLOGY, ALIERO, KEBBI STATE",
    InSt: 21,
    AFFL_ST_ID: 21,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1221,
    INABV: "KUST",
    INName: "KANO UNIVERSITY OF SCIENCE AND TECHNOLOGY, WUDIL, KANO STATE",
    InSt: 19,
    AFFL_ST_ID: 19,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1222,
    INABV: "KWASU",
    INName: "KWARA STATE UNIVERSITY, MALETE, ILORIN, KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 23,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1223,
    INABV: "IBBU-LAPAI",
    INName: "IBRAHIM BADAMASI BABANGIDA UNIVERSITY, LAPAI, NIGER STATE",
    InSt: 26,
    AFFL_ST_ID: 26,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1224,
    INABV: "LASU",
    INName: "LAGOS STATE UNIVERSITY, OJO, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1225,
    INABV: "LAUTECH",
    INName: "LADOKE AKINTOLA UNIVERSITY OF TECHNOLOGY, OGBOMOSO, OYO STATE",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1226,
    INABV: "NASARAWA",
    INName: "NASARAWA STATE UNIVERSITY, KEFFI, NASARAWA STATE",
    InSt: 25,
    AFFL_ST_ID: 25,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1227,
    INABV: "NDU",
    INName: "NIGER DELTA UNIVERSITY, WILBERFORCE ISLAND, BAYELSA  STATE",
    InSt: 6,
    AFFL_ST_ID: 6,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1228,
    INABV: "MAITAMA",
    INName: "YUSUF MAITAMA SULE UNIVERSITY, KANO, KANO STATE",
    InSt: 19,
    AFFL_ST_ID: 19,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1229,
    INABV: "OOU",
    INName: "OLABISI ONABANJO UNIVERSITY, AGO-IWOYE, OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 27,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1230,
    INABV: "OSUSTECH",
    INName:
      "ONDO STATE UNIVERSITY OF SCIENCE AND TECHNOLOGY, OKITIPUPA, ONDO STATE",
    InSt: 28,
    AFFL_ST_ID: 28,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1231,
    INABV: "PLASU",
    INName: "PLATEAU STATE UNIVERSITY, BOKKOS, PLATEAU STATE",
    InSt: 31,
    AFFL_ST_ID: 31,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1232,
    INABV: "RSU",
    INName: "RIVERS STATE UNIVERSITY, PORT HARCOURT, RIVERS STATE",
    InSt: 32,
    AFFL_ST_ID: 32,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1233,
    INABV: "SOSU",
    INName: "SOKOTO STATE UNIVERSITY, SOKOTO, SOKOTO STATE",
    InSt: 33,
    AFFL_ST_ID: 33,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1234,
    INABV: "TASUED",
    INName: "TAI SOLARIN UNIVERSITY OF EDUCATION, IJAGUN, OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 27,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1235,
    INABV: "TSUJ",
    INName: "TARABA STATE UNIVERSITY, JALINGO, TARABA STATE",
    InSt: 34,
    AFFL_ST_ID: 34,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1236,
    INABV: "UMYUK",
    INName: "UMARU MUSA YARADUA UNIVERSITY, KATSINA, KATSINA STATE",
    InSt: 20,
    AFFL_ST_ID: 20,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1237,
    INABV: "UNIOSUN",
    INName: "OSUN STATE UNIVERSITY, OSOGBO, OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1238,
    INABV: "YSU",
    INName: "YOBE STATE UNIVERSITY, DAMATURU, YOBE STATE",
    InSt: 35,
    AFFL_ST_ID: 35,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1246,
    INABV: "OSUMS",
    INName:
      "ONDO STATE UNIVERSITY OF MEDICAL SCIENCES, LAJE ROAD, ONDO, ONDO STATE",
    InSt: 28,
    AFFL_ST_ID: 28,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1247,
    INABV: "EUI",
    INName: "EDO UNIVERSITY, IYAMHO, EDO STATE",
    InSt: 12,
    AFFL_ST_ID: 12,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1248,
    INABV: "UNI-AFRICA",
    INName: "UNIVERSITY OF AFRICA, TORU-ORUA, BAYELSA STATE",
    InSt: 6,
    AFFL_ST_ID: 6,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1249,
    INABV: "KOMU",
    INName: "KINGSLEY OZUMBA (K.O) MBADIWE UNIVERSITY, OGBOKO, IMO STATE",
    InSt: 16,
    AFFL_ST_ID: 16,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1251,
    INABV: "ZAMFARA",
    INName: "ZAMFARA STATE UNIVERSITY, TALATA MAFARA, ZAMFARA STATE",
    InSt: 36,
    AFFL_ST_ID: 36,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1252,
    INABV: "GSUST",
    INName:
      "GOMBE STATE UNIVERSITY OF SCIENCE AND TECHNOLOGY, KUMO, GOMBE STATE",
    InSt: 15,
    AFFL_ST_ID: 15,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1253,
    INABV: "BOSU",
    INName: "BORNO STATE UNIVERSITY, MAIDUGURI, BORNO STATE",
    InSt: 8,
    AFFL_ST_ID: 8,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1254,
    INABV: "BMU",
    INName: "BAYELSA MEDICAL UNIVERSITY, YENAGOA, BAYELSA STATE",
    InSt: 6,
    AFFL_ST_ID: 6,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1255,
    INABV: "UAGRIC-IMO",
    INName:
      "UNIVERSITY OF AGRICULTURE AND ENVIRONMENTAL SCIENCES, UMUAGWO, IMO STATE",
    InSt: 16,
    AFFL_ST_ID: 16,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1256,
    INABV: "CUST",
    INName:
      "CONFLUENCE UNIVERSITY OF SCIENCE AND TECHNOLOGY, OSARA, KOGI STATE",
    InSt: 22,
    AFFL_ST_ID: 22,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1257,
    INABV: "DANUMS",
    INName:
      "DAVID NWEZE UMAHI FEDERAL UNIVERSITY OF MEDICAL SCIENCES, UBURU, EBONYI STATE",
    InSt: 11,
    AFFL_ST_ID: 11,
    INTYP: 11,
    Inst_Cat: "FU",
    category: "University",
  },
  {
    INID: 1258,
    INABV: "DELSUT",
    INName:
      "DELTA STATE UNIVERSITY OF SCIENCE AND TECHNOLOGY, OZORO, DELTA STATE",
    InSt: 10,
    AFFL_ST_ID: 10,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1259,
    INABV: "UDA",
    INName: "UNIVERSITY OF DELTA, AGBOR, DELTA STATE",
    InSt: 10,
    AFFL_ST_ID: 10,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1260,
    INABV: "DOU",
    INName: "DENNIS OSADEBAY UNIVERSITY, ASABA, DELTA STATE",
    InSt: 10,
    AFFL_ST_ID: 10,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1261,
    INABV: "LASUSTECH",
    INName:
      "LAGOS STATE UNIVERSITY OF SCIENCE AND TECHNOLOGY, IKORODU, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1262,
    INABV: "LASUED",
    INName: "LAGOS STATE UNIVERSITY OF EDUCATION, OTO/IJANIKIN, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1263,
    INABV: "OAUSTECH",
    INName:
      "OLUSEGUN AGAGU UNIVERSITY OF SCIENCE AND TECHNOLOGY, OKITIPUPA, ONDO STATE",
    InSt: 28,
    AFFL_ST_ID: 28,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1301,
    INABV: "ABUAD",
    INName: "AFE BABALOLA UNIVERSITY, ADO-EKITI, EKITI STATE",
    InSt: 13,
    AFFL_ST_ID: 13,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1302,
    INABV: "ACHIEVERS",
    INName: "ACHIEVERS UNIVERSITY, OWO, ONDO STATE",
    InSt: 28,
    AFFL_ST_ID: 28,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1303,
    INABV: "AL-HIKMAH",
    INName: "AL-HIKMAH UNIVERSITY, ILORIN, KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 23,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1304,
    INABV: "AUE",
    INName: "ADELEKE UNIVERSITY, EDE, OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1305,
    INABV: "AUN",
    INName: "AMERICAN UNIVERSITY OF NIGERIA, YOLA, ADAMAWA STATE",
    InSt: 2,
    AFFL_ST_ID: 2,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1306,
    INABV: "AJAYI",
    INName: "AJAYI CROWTHER UNIVERSITY, OYO, OYO STATE",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1307,
    INABV: "BABCOCK",
    INName: "BABCOCK UNIVERSITY, ILISHAN-REMO, OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 27,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1308,
    INABV: "BAZE",
    INName: "BAZE UNIVERSITY, ABUJA, FCT",
    InSt: 37,
    AFFL_ST_ID: 37,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1309,
    INABV: "BELLS",
    INName: "BELLS UNIVERSITY OF TECHNOLOGY, OTA, OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 27,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1310,
    INABV: "BINGHAM",
    INName: "BINGHAM UNIVERSITY, KARU, NASARAWA STATE",
    InSt: 25,
    AFFL_ST_ID: 25,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1311,
    INABV: "BIU",
    INName: "BENSON IDAHOSA UNIVERSITY, BENIN CITY, EDO STATE",
    InSt: 12,
    AFFL_ST_ID: 12,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1312,
    INABV: "BOWEN",
    INName: "BOWEN UNIVERSITY, IWO, OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1313,
    INABV: "CALEB",
    INName: "CALEB UNIVERSITY, IMOTA, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1314,
    INABV: "CARITAS",
    INName: "CARITAS UNIVERSITY, AMORJI-NIKE, ENUGU, ENUGU STATE",
    InSt: 14,
    AFFL_ST_ID: 14,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1315,
    INABV: "CRAWFORD",
    INName:
      "CRAWFORD UNIVERSITY OF APOSTOLIC FAITH MISSION FAITH CITY, IGBESA, OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 27,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1316,
    INABV: "CUAB",
    INName: "CRESCENT UNIVERSITY, ABEOKUTA, OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 27,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1317,
    INABV: "COVENANT",
    INName: "COVENANT UNIVERSITY, CANAANLAND, OTA, OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 27,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1318,
    INABV: "FOUNTAIN",
    INName: "FOUNTAIN UNIVERSITY, OSOGBO, OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1319,
    INABV: "GOUU",
    INName: "GODFREY OKOYE UNIVERSITY, URGWUOMU- NIKE, ENUGU STATE",
    InSt: 14,
    AFFL_ST_ID: 14,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1320,
    INABV: "IGBINEDION",
    INName: "IGBINEDION UNIVERSITY, OKADA, BENIN CITY, EDO STATE",
    InSt: 12,
    AFFL_ST_ID: 12,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1321,
    INABV: "JABU",
    INName: "JOSEPH AYO BABALOLA UNIVERSITY, IKEJI-ARAKEJI, OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1322,
    INABV: "AL-QALAM",
    INName: "AL-QALAM UNIVERSITY, KATSINA, KATSINA STATE",
    InSt: 20,
    AFFL_ST_ID: 20,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1323,
    INABV: "KWARARAFA",
    INName: "KWARARAFA UNIVERSITY, WUKARI, TARABA STATE",
    InSt: 34,
    AFFL_ST_ID: 34,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1324,
    INABV: "LANDMARK",
    INName: "LANDMARK UNIVERSITY, OMU-ARAN, KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 23,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1325,
    INABV: "LCITY",
    INName: "LEAD CITY UNIVERSITY, IBADAN, OYO STATE",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1326,
    INABV: "MADONNA",
    INName: "MADONNA UNIVERSITY, ELELE, RIVERS STATE",
    InSt: 32,
    AFFL_ST_ID: 32,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1327,
    INABV: "MKAR",
    INName: "UNIVERSITY OF MKAR, GBOKO, BENUE STATE",
    InSt: 7,
    AFFL_ST_ID: 7,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1328,
    INABV: "NOVENA",
    INName: "NOVENA UNIVERSITY, OGUME, DELTA STATE",
    InSt: 10,
    AFFL_ST_ID: 10,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1329,
    INABV: "NILE",
    INName: "NILE UNIVERSITY OF NIGERIA, ABUJA, FCT",
    InSt: 37,
    AFFL_ST_ID: 37,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1330,
    INABV: "OBONG",
    INName: "OBONG UNIVERSITY, OBONG, NTAK, AKWA IBOM STATE",
    InSt: 3,
    AFFL_ST_ID: 3,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1331,
    INABV: "ODUDUWA",
    INName: "ODUDUWA UNIVERSITY, IPETUMODU, OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1332,
    INABV: "PANATLANT",
    INName:
      "PAN-ATLANTIC UNIVERSITY, KM 52 LEKKI-EPE EXPRESSWAY, IBEJU-LEKKI, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1333,
    INABV: "PAUL",
    INName: "PAUL UNIVERSITY, AWKA, ANAMBRA STATE",
    InSt: 4,
    AFFL_ST_ID: 4,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1334,
    INABV: "RHEMA",
    INName: "RHEMA UNIVERSITY, ABA, ABIA STATE",
    InSt: 1,
    AFFL_ST_ID: 1,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1335,
    INABV: "REDEEMERS",
    INName: "REDEEMERS UNIVERSITY, EDE, OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1336,
    INABV: "RENAISANCE",
    INName: "RENAISSANCE UNIVERSITY, OJIAGU-AGBANI, ENUGU, ENUGU STATE",
    InSt: 14,
    AFFL_ST_ID: 14,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1337,
    INABV: "SALEM",
    INName: "SALEM UNIVERSITY, LOKOJA, KOGI STATE",
    InSt: 22,
    AFFL_ST_ID: 22,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1338,
    INABV: "ELIZADE",
    INName: "ELIZADE UNIVERSITY, ILARA-MOKIN, ONDO STATE",
    InSt: 28,
    AFFL_ST_ID: 28,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1339,
    INABV: "EVANGEL",
    INName: "EVANGEL UNIVERSITY, AKAEZE, EBONYI STATE",
    InSt: 11,
    AFFL_ST_ID: 11,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1340,
    INABV: "GREGORY",
    INName: "GREGORY UNIVERSITY, UTURU, ABIA STATE",
    InSt: 1,
    AFFL_ST_ID: 1,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1341,
    INABV: "MCPHERSON",
    INName: "McPHERSON UNIVERSITY, SERIKI SOTAYO, OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 27,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1342,
    INABV: "SOUTHWEST",
    INName: "SOUTHWESTERN UNIVERSITY, OKUN-OWA, OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 27,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1343,
    INABV: "SAU",
    INName: "SAMUEL ADEGBOYEGA UNIVERSITY, OGWA, EDO STATE",
    InSt: 12,
    AFFL_ST_ID: 12,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1344,
    INABV: "TANSIAN",
    INName: "TANSIAN UNIVERSITY, OBA, ANAMBRA STATE",
    InSt: 4,
    AFFL_ST_ID: 4,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1345,
    INABV: "WELLSPRING",
    INName: "WELLSPRING UNIVERSITY, IRHIHI-OGBANEKI, BENIN CITY, EDO STATE",
    InSt: 12,
    AFFL_ST_ID: 12,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1346,
    INABV: "WESTERNDEL",
    INName: "WESTERN DELTA UNIVERSITY, OGHARA, DELTA STATE",
    InSt: 10,
    AFFL_ST_ID: 10,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1347,
    INABV: "WESLEYUNI",
    INName: "WESLEY UNIVERSITY, ONDO, ONDO STATE",
    InSt: 28,
    AFFL_ST_ID: 28,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1348,
    INABV: "VERITAS",
    INName: "VERITAS UNIVERSITY,  BWARI, ABUJA, FCT",
    InSt: 37,
    AFFL_ST_ID: 37,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1351,
    INABV: "SUMIT_OFFA",
    INName: "SUMMIT UNIVERITY, OFFA, KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 23,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1352,
    INABV: "CHRISLAND",
    INName: "CHRISLAND UNIVERSITY, OWODE, OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 27,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1353,
    INABV: "MOUNT_TOP",
    INName: "MOUNTAIN TOP UNIVERSITY, MAKOGI-OBA, OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 27,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1354,
    INABV: "EDWIN-CLARK",
    INName: "EDWIN CLARK UNIVERSITY, KIAGBODO, DELTA STATE",
    InSt: 10,
    AFFL_ST_ID: 10,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1355,
    INABV: "HALLMARK",
    INName: "HALLMARK UNIVERSITY,IJEBU-ITELE,OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 27,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1356,
    INABV: "RITMAN",
    INName: "RITMAN UNIVERSITY, IKOT-EKPENE, AKWA IBOM STATE",
    InSt: 3,
    AFFL_ST_ID: 3,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1357,
    INABV: "MC-IBRU",
    INName: "MICHAEL AND CECILIA IBRU UNIVERSITY, OWHRODE, DELTA STATE",
    InSt: 10,
    AFFL_ST_ID: 10,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1358,
    INABV: "AUGUSTINE",
    INName: "AUGUSTINE UNIVERSITY, ILARA, LAGOS, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1359,
    INABV: "CHRISTOPHER",
    INName: "CHRISTOPHER UNIVERSITY, MOWE, OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 27,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1360,
    INABV: "HEZEKIAH-UNI",
    INName: "HEZEKIAH UNIVERSITY, UMUDI, IMO STATE",
    InSt: 16,
    AFFL_ST_ID: 16,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1361,
    INABV: "UNIKINGS",
    INName: "KINGS UNIVERSITY, ODE-OMU, OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1362,
    INABV: "ARTHUR-JARVIS",
    INName: "ARTHUR JARVIS UNIVERSITY, AKPABUYO, CROSS RIVER STATE",
    InSt: 9,
    AFFL_ST_ID: 9,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1363,
    INABV: "ANCHOR-UNI",
    INName: "ANCHOR UNIVERSITY, AYOBO, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1364,
    INABV: "CROWN-HILL",
    INName: "CROWN HILL UNIVERSITY, EIYENKORIN, KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 23,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1365,
    INABV: "DOMINICAN-UNI",
    INName: "DOMINICAN UNIVERSITY, IBADAN, OYO STATE",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1366,
    INABV: "CLIFFORD",
    INName: "CLIFFORD UNIVERSITY, OWERRINTTA, ABIA STATE",
    InSt: 1,
    AFFL_ST_ID: 1,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1367,
    INABV: "COAL-CITY",
    INName: "COAL CITY UNIVERSITY, ENUGU, ENUGU STATE",
    InSt: 14,
    AFFL_ST_ID: 14,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1368,
    INABV: "KOLA-DAISI",
    INName: "KOLADAISI UNIVERSITY, IBADAN, OYO STATE",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1369,
    INABV: "LEGACY-UNI",
    INName: "LEGACY UNIVERSITY, OKIJA, ANAMBRA STATE",
    InSt: 4,
    AFFL_ST_ID: 4,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1370,
    INABV: "TECH-UNI",
    INName: "FIRST TECHNICAL UNIVERSITY, IBADAN, OYO STATE",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 13,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 1371,
    INABV: "ATIBA-UNI",
    INName: "ATIBA UNIVERSITY, OYO, OYO STATE",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1372,
    INABV: "PAMO",
    INName: "PAMO UNIVERSITY OF MEDICAL SCIENCE, PORT HARCOURT, RIVERS STATE",
    InSt: 32,
    AFFL_ST_ID: 32,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1373,
    INABV: "ADUN",
    INName: "ADMIRALTY UNIVERSITY OF NIGERIA, IBUSA, DELTA STATE",
    InSt: 10,
    AFFL_ST_ID: 10,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1374,
    INABV: "SPIRITAN",
    INName: "SPIRITAN UNIVERSITY, NNEOCHI, ABIA STATE",
    InSt: 1,
    AFFL_ST_ID: 1,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1375,
    INABV: "SKYLINE",
    INName: "SKYLINE UNIVERSITY NIGERIA, KANO, KANO STATE",
    InSt: 19,
    AFFL_ST_ID: 19,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1376,
    INABV: "PC-UNI",
    INName: "PRECIOUS CORNERSTONE UNIVERSITY, IBADAN, OYO STATE",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1377,
    INABV: "EKO-UNI",
    INName:
      "EKO UNIVERSITY OF MEDICINE AND HEALTH SCIENCE, IJANIKIN, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1378,
    INABV: "GREENFIELD",
    INName: "GREENFIELD UNIVERSITY, KASARAMI, KADUNA STATE",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1379,
    INABV: "DOMINION",
    INName: "DOMINION UNIVERSITY, IBADAN, OYO STATE",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1380,
    INABV: "TRINITY",
    INName: "TRINITY UNIVERSITY, YABA, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1381,
    INABV: "WESTLAND",
    INName: "WESTLAND UNIVERSITY, IWO, OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1382,
    INABV: "ISTIQAMA",
    INName: "AL-ISTIQAMA UNIVERSITY, SUMAILA, KANO STATE",
    InSt: 19,
    AFFL_ST_ID: 19,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1383,
    INABV: "EDUSOKO",
    INName: "EDUSOKO UNIVERSITY, BIDA, NIGER STATE",
    InSt: 26,
    AFFL_ST_ID: 26,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1384,
    INABV: "APU",
    INName: "AHMAN PATEGI UNIVERSITY, PATIGI, KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 23,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1385,
    INABV: "TAU",
    INName: "THOMAS ADEWUMI UNIVERSITY, OKO-IRESE, KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 23,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1386,
    INABV: "MEWAR",
    INName: "MEWAR UNIVERSITY NIGERIA, MASAKA, NASARAWA STATE",
    InSt: 25,
    AFFL_ST_ID: 25,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1387,
    INABV: "BOUEDST",
    INName:
      "BAMIDELE OLUMILUA UNIVERSITY OF EDU. SCIENCE AND TECH. IKERE-EKITI,  EKITI STATE",
    InSt: 13,
    AFFL_ST_ID: 13,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1388,
    INABV: "KKU",
    INName: "KARL KUMM UNIVERSITY, VOM, PLATEAU STATE",
    InSt: 31,
    AFFL_ST_ID: 31,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1389,
    INABV: "MAAUN",
    INName: "MARYAM ABACHA AMERICAN UNIVERSITY OF NIGERIA, KANO, KANO STATE",
    InSt: 19,
    AFFL_ST_ID: 19,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1390,
    INABV: "CUN",
    INName: "CLARETIAN UNIVERSITY, NEKEDE, IMO STATE",
    InSt: 16,
    AFFL_ST_ID: 16,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1391,
    INABV: "MUDIAME",
    INName: "MUDIAME UNIVERSITY, IRRUA, EDO STATE",
    InSt: 12,
    AFFL_ST_ID: 12,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1392,
    INABV: "PHILOMATH",
    INName: "PHILOMATH UNIVERSITY,  KUJE, ABUJA, FCT",
    InSt: 37,
    AFFL_ST_ID: 37,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1393,
    INABV: "MARANATHA",
    INName: "MARANATHA UNIVERSITY,  IBEJU LEKKI, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1394,
    INABV: "CCU",
    INName: "CAPITAL CITY UNIVERSITY, WARAWA, KANO, KANO STATE",
    InSt: 19,
    AFFL_ST_ID: 19,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1395,
    INABV: "UOO",
    INName: "UNIVERSITY OF OFFA, OFFA, KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 23,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1396,
    INABV: "KUM",
    INName: "KHADIJA UNIVERSITY, MAJIA, JIGAWA STATE",
    InSt: 17,
    AFFL_ST_ID: 17,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1397,
    INABV: "AVE-MARIA",
    INName: "AVE-MARIA UNIVERSITY, PIYANKO, NASARAWA STATE",
    InSt: 25,
    AFFL_ST_ID: 25,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1398,
    INABV: "TOPFAITH",
    INName: "TOPFAITH UNIVERSITY, MKPATAK, AKWA IBOM STATE",
    InSt: 3,
    AFFL_ST_ID: 3,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1399,
    INABV: "HAVILLA",
    INName: "HAVILLA UNIVERSITY, NDE-IKOM, CROSS RIVER STATE",
    InSt: 9,
    AFFL_ST_ID: 9,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1401,
    INABV: "ADEYEMIDEG",
    INName: "ADEYEMI COLLEGE OF EDUCATION, ONDO STATE. (AFFL TO OAU, ILE-IFE)",
    InSt: 28,
    AFFL_ST_ID: 29,
    INTYP: 14,
    Inst_Cat: "FD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1402,
    INABV: "AUCHIPOLY-DEG",
    INName:
      "AUCHI POLYTECHNIC, AUCHI, EDO STATE. (AFFL TO NNAMDI AZIKIWE UNIVERSITY, AWKA)",
    InSt: 12,
    AFFL_ST_ID: 4,
    INTYP: 14,
    Inst_Cat: "FD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1403,
    INABV: "EDANKPA",
    INName: "COLLEGE OF EDUCATION, ANKPA, KOGI STATE (AFFL TO ATBU, BAUCHI)",
    InSt: 22,
    AFFL_ST_ID: 5,
    INTYP: 14,
    Inst_Cat: "SD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1404,
    INABV: "EDALV",
    INName:
      "ALVAN IKOKU COLLEGE OF EDUCATION, IMO STATE, (AFFL TO UNIV OF NIGERA, NSUKKA)",
    InSt: 16,
    AFFL_ST_ID: 14,
    INTYP: 14,
    Inst_Cat: "FD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1405,
    INABV: "EDKAN",
    INName:
      "FEDERAL COLLEGE OF EDUCATION, KANO, (AFFL TO AHMADU BELLO UNIVERSITY, ZARIA)",
    InSt: 19,
    AFFL_ST_ID: 18,
    INTYP: 14,
    Inst_Cat: "FD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1406,
    INABV: "FCEZARIA-DEG",
    INName:
      "FEDERAL COLLEGE OF EDUCATION, ZARIA, KADUNA STATE (AFFL TO ABU, ZARIA)",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 14,
    Inst_Cat: "FD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1407,
    INABV: "KADPOLY",
    INName:
      "KADUNA POLYTECHNIC, KADUNA , KADUNA STATE (AFFL TO FED UNIV OF TECH, MINNA)",
    InSt: 18,
    AFFL_ST_ID: 26,
    INTYP: 14,
    Inst_Cat: "FD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1408,
    INABV: "YABA-DEG",
    INName:
      "YABA COLLEGE OF TECHNOLOGY, YABA, LAGOS STATE (AFFL TO UNIV OF NIG, NSUKKA)",
    InSt: 24,
    AFFL_ST_ID: 14,
    INTYP: 14,
    Inst_Cat: "FD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1409,
    INABV: "AGBORCOE",
    INName:
      "COLLEGE OF EDUCATION, AGBOR, DELTA STATE. (AFFL TO DELTA STATE UNIV, ABRAKA)",
    InSt: 10,
    AFFL_ST_ID: 10,
    INTYP: 14,
    Inst_Cat: "SD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1410,
    INABV: "AOCOE",
    INName:
      "ADENIRAN OGUNSANYA COL OF EDU, OTTO-IJANIKIN, LAGOS (AFFL TO EKITI STATE UNIV)",
    InSt: 24,
    AFFL_ST_ID: 13,
    INTYP: 14,
    Inst_Cat: "SD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1411,
    INABV: "APOSTOLIC",
    INName:
      "THE APOSTOLIC CHURCH THEOLOGICAL SEMINARY, AMUMARA (AFFL TO UNIV OF UYO)",
    InSt: 3,
    AFFL_ST_ID: 3,
    INTYP: 14,
    Inst_Cat: "PD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1412,
    INABV: "ASSEMBLIES",
    INName:
      "ASSEMBLIES OF GOD DIVINITY SCHOOL, OLD UMUAHIA, (AFFL TO UNIV OF UYO) ABIA STATE",
    InSt: 1,
    AFFL_ST_ID: 3,
    INTYP: 14,
    Inst_Cat: "PD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1413,
    INABV: "AVCTA",
    INName:
      "ARCHBISHOP VINING COLLEGE OF TECH, AKURE, (AFFL TO UNIV OF IBADAN), ONDO STATE",
    InSt: 28,
    AFFL_ST_ID: 30,
    INTYP: 14,
    Inst_Cat: "PD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1414,
    INABV: "BAPTIST",
    INName:
      "BAPTIST COLLEGE OF THEOLOGY, OBINZE, OWERRI, (AFFL TO UNIPORT), IMO STATE",
    InSt: 16,
    AFFL_ST_ID: 32,
    INTYP: 14,
    Inst_Cat: "PD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1415,
    INABV: "BMSE",
    INName:
      "BIGARD MEMORIAL SEMINARY, ENUGU (AFFLIATED TO UNIVERSITY OF IBADAN), ENUGU STATE",
    InSt: 14,
    AFFL_ST_ID: 30,
    INTYP: 14,
    Inst_Cat: "PD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1416,
    INABV: "CALVARY",
    INName:
      "CALVARY COL. OF THEOLOGY, RUKPOKWU, PORT HARCOURT (AFFL TO UNICAL), RIVERS STATE",
    InSt: 32,
    AFFL_ST_ID: 9,
    INTYP: 14,
    Inst_Cat: "PD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1417,
    INABV: "CAILS-DEG",
    INName:
      "COLLEGE OF ARABIC AND ISLAMIC LEGAL STUDIES, ILORIN (AFF TO BAYERO), KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 19,
    INTYP: 14,
    Inst_Cat: "SD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1418,
    INABV: "COEA",
    INName: "COLLEGE OF EDUCATION, AZARE (AFFLIATED TO UNIMAID), BAUCHI STATE",
    InSt: 5,
    AFFL_ST_ID: 8,
    INTYP: 14,
    Inst_Cat: "SD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1419,
    INABV: "AKWANGA-DEG",
    INName:
      "COLLEGE OF EDUCATION, AKWANGA (AFFL TO ABU, ZARIA), NASARAWA STATE",
    InSt: 25,
    AFFL_ST_ID: 18,
    INTYP: 14,
    Inst_Cat: "SD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1420,
    INABV: "CIWA",
    INName:
      "CATHOLIC INSTITUTION OF WEST AFRICA, PORT HARCOURT (AFF TO UNICAL), RIVERS STATE",
    InSt: 32,
    AFFL_ST_ID: 9,
    INTYP: 14,
    Inst_Cat: "PD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1421,
    INABV: "CLARETIAN",
    INName:
      "CLARETIAN INSTITUTE OF PHILOSOPHY, NEKEDE, IMO STATE (AFFL TO IMO STATE UNIVERSI",
    InSt: 16,
    AFFL_ST_ID: 16,
    INTYP: 14,
    Inst_Cat: "PD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1422,
    INABV: "EDAKOKA",
    INName:
      "FEDERAL COLLEGE OF EDUCATION (TECH), AKOKA, (AFFL TO UNIBEN), LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 12,
    INTYP: 14,
    Inst_Cat: "FD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1423,
    INABV: "EACOE-DEG",
    INName:
      "EMMANUEL ALAYANDE COLLEGE OF EDUCATION, OYO. (AFF TO EKSU), OYO STATE",
    InSt: 30,
    AFFL_ST_ID: 13,
    INTYP: 14,
    Inst_Cat: "SD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1424,
    INABV: "ECWA",
    INName:
      "ECWA THEOLOGICAL SEMINARY, IGBAJA (AFF TO UNIVERSITY OF IBADAN), KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 30,
    INTYP: 14,
    Inst_Cat: "PD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1425,
    INABV: "ESCOE",
    INName:
      "ENUGU STATE COL OF EDUC.(TECH). (AFF TO NNAMDI AZIKIWE UNIV), ENUGU STATE",
    InSt: 14,
    AFFL_ST_ID: 4,
    INTYP: 14,
    Inst_Cat: "SD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1427,
    INABV: "FCEABEO-DEG",
    INName:
      "FEDERAL COLLEGE OF EDUCATION, ABEOKUTA (AFF TO UNIVERSITY OF IBADAN), OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 30,
    INTYP: 14,
    Inst_Cat: "FD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1428,
    INABV: "FCEOKENE-DEG",
    INName:
      "FEDERAL COLLEGE OF EDUCATION, OKENE, (AFFL. TO UNIVERSITY OF IBADAN), KOGI STATE",
    InSt: 22,
    AFFL_ST_ID: 30,
    INTYP: 14,
    Inst_Cat: "FD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1429,
    INABV: "HARVEST",
    INName:
      "WORLD HARVEST BIBLE COLLEGE AND SEMINARY, CALABAR, (AFFILIATED TO UNICAL)",
    InSt: 9,
    AFFL_ST_ID: 9,
    INTYP: 14,
    Inst_Cat: "PD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1430,
    INABV: "FCEKAT-DEG",
    INName:
      "FEDERAL COLLEGE OF EDUCATION, KATSINA, (AFFL TO BAYERO UNI, KANO), KATSINA STATE",
    InSt: 20,
    AFFL_ST_ID: 19,
    INTYP: 14,
    Inst_Cat: "FD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1431,
    INABV: "FC-GOMBE",
    INName:
      "FEDERAL COLLEGE OF EDUCATION (TECHNICAL), (AFFL TO ATBU, BAUCHI), GOMBE STATE",
    InSt: 15,
    AFFL_ST_ID: 5,
    INTYP: 14,
    Inst_Cat: "FD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1432,
    INABV: "KONT_DEG",
    INName:
      "FEDERAL COLLEGE OF EDUCATION, KONTAGORA, (AFFL TO ABU, ZARIA), NIGER STATE",
    InSt: 26,
    AFFL_ST_ID: 18,
    INTYP: 14,
    Inst_Cat: "FD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1433,
    INABV: "FCEPANKSHIN-DEG",
    INName:
      "FEDERAL COLLEGE OF EDUCATION, PANKSHIN, (AFFL TO UNI OF JOS), PLATEAU STATE",
    InSt: 31,
    AFFL_ST_ID: 31,
    INTYP: 14,
    Inst_Cat: "FD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1434,
    INABV: "ILA-DEG",
    INName:
      "OSUN STATE COLLEGE OF EDUCATION, ILA-ORANGUN(AFF TO EKITI STATE UNI), OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 13,
    INTYP: 14,
    Inst_Cat: "SD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1435,
    INABV: "POTISKUM_D",
    INName:
      "FEDERAL COLLEGE OF EDUCATION, (TECH), POTISKUM (AFF TO ATBU, BAUCHI), YOBE STATE",
    InSt: 35,
    AFFL_ST_ID: 5,
    INTYP: 14,
    Inst_Cat: "FD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1436,
    INABV: "IMMANUEL",
    INName:
      "IMMANUEL COLLEGE OF TECH AND CHRISTIAN EDUC. IBADAN (AFFL TO UNIV OF IBADAN)",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 14,
    Inst_Cat: "PD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1437,
    INABV: "IKERE-DEG",
    INName:
      "COLLEGE OF EDUCATION, IKERE EKITI. (AFFL TO UNIV OF NIGERIA), EKITI STATE",
    InSt: 13,
    AFFL_ST_ID: 14,
    INTYP: 14,
    Inst_Cat: "SD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1438,
    INABV: "METHODIST",
    INName:
      "METHODIST THEOLOGICAL INSTITUTE, UMUAHIA, (AFFL TO UNIPORT), ABIA STATE",
    InSt: 1,
    AFFL_ST_ID: 32,
    INTYP: 14,
    Inst_Cat: "PD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1439,
    INABV: "MOCPEN",
    INName:
      "MICHAEL OTEDOLA COL OF PRIMARY EDUC. EPE, (AFF TO UI), LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 30,
    INTYP: 14,
    Inst_Cat: "PD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1440,
    INABV: "KWCOE",
    INName:
      "KWARA STATE COLLEGE OF EDU. (TECH), LAFIAGI (AFFL TO ABU, ZARIA), KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 18,
    INTYP: 14,
    Inst_Cat: "SD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1441,
    INABV: "NATIONAL",
    INName:
      "NATIONAL MISSIONARY SEMINARY OF ST-PAUL, GWAGWALADA. (AFFL TO UNIPORT), FCT",
    InSt: 37,
    AFFL_ST_ID: 32,
    INTYP: 14,
    Inst_Cat: "PD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1442,
    INABV: "NBTSO",
    INName:
      "NIGERIA BAPTIST THEOLOGICAL SEMINARY, OGBOMOSHO, (AFFL TO UI), OYO STATE",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 14,
    Inst_Cat: "PD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1443,
    INABV: "NFI-DEG",
    INName:
      "NATIONAL FILM INSTITUTE, JOS, (AFFILIATED TO UNIVERSITY OF JOS), PLATEAU STATE",
    InSt: 31,
    AFFL_ST_ID: 31,
    INTYP: 14,
    Inst_Cat: "FD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1445,
    INABV: "NTA_TVC",
    INName:
      "NIGERIAN TELEVISION AUTHORITY (TELEVISION COL) (AFF TO ABU, ZARIA), KADUNA STATE",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 14,
    Inst_Cat: "FD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1446,
    INABV: "NSUGBE",
    INName:
      "NWAFOR ORIZU COLLEGE OF EDUCATION, NSUGBE. (AFFL TO UNN), ANAMBRA STATE",
    InSt: 4,
    AFFL_ST_ID: 14,
    INTYP: 14,
    Inst_Cat: "SD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1447,
    INABV: "OSCOE",
    INName:
      "OSUN STATE COLLEGE OF EDUCATION, ILESA, (AFFL TO UNIV OF IBADAN), OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 30,
    INTYP: 14,
    Inst_Cat: "SD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1448,
    INABV: "POPE",
    INName:
      "POPE JOHN MAJOR SEMINARY, OKPUN, AWKA, (AFFL TO NNAMDI AZIKIWE UNIV, AWKA)",
    InSt: 4,
    AFFL_ST_ID: 4,
    INTYP: 14,
    Inst_Cat: "PD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1449,
    INABV: "SAMUEL",
    INName:
      "THE SAMUEL BILL THEOLOGICAL COLLEGE, ABAK, AKWA IBOM STATE (AFFL TO UNIV OF UYO)",
    InSt: 3,
    AFFL_ST_ID: 3,
    INTYP: 14,
    Inst_Cat: "PD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1450,
    INABV: "SHAGARI",
    INName:
      "SHEHU SHAGARI COLLEGE OF EDUCATION, SOKOTO, (AFFL TO AHMADU BELLO UNIV, ZARIA)",
    InSt: 33,
    AFFL_ST_ID: 18,
    INTYP: 14,
    Inst_Cat: "SD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1451,
    INABV: "SPIRITAN-E",
    INName:
      "SPIRITAN INT’L SCHOOL OF THEOLOGY, ATTAKWU, ENUGU, (AFFL TO UNIV OF NIG, NSUKKA)",
    InSt: 14,
    AFFL_ST_ID: 14,
    INTYP: 14,
    Inst_Cat: "PD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1452,
    INABV: "SPIRITANSK",
    INName:
      "SPIRITAN SCHOOL OF PHILOSOPHY, ISSIENU, NSUKKA, (AFFL TO UNN), ENUGU STATE",
    InSt: 14,
    AFFL_ST_ID: 14,
    INTYP: 14,
    Inst_Cat: "PD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1453,
    INABV: "SACOEDEG",
    INName:
      "ST. AUGUSTINE’S COLLEGE OF EDUCATION, AKOKA, LAGOS, (AFFL TO UNIV OF IBADAN)",
    InSt: 24,
    AFFL_ST_ID: 30,
    INTYP: 14,
    Inst_Cat: "PD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1454,
    INABV: "ST-JOSEPH",
    INName:
      "ST-JOSEPH MAJOR SEMINARY, IKOT-EKPENE, AKWA IBOM STATE (AFFL TO UNIV OF CALABAR)",
    InSt: 3,
    AFFL_ST_ID: 9,
    INTYP: 14,
    Inst_Cat: "PD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1455,
    INABV: "STPET PAUL",
    INName:
      "ST. PETER AND PAUL SEMINARY BODIJA, IBADAN (AFFL TO UNIV OF IBADAN) OYO STATE",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 14,
    Inst_Cat: "PD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1456,
    INABV: "SWSO",
    INName:
      "SEAT OF WISDOM SEMINARY, OWERRI, IMO STATE (AFFL TO IMO STATE UNIVERSITY)",
    InSt: 16,
    AFFL_ST_ID: 16,
    INTYP: 14,
    Inst_Cat: "PD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1457,
    INABV: "EDASAB",
    INName:
      "FEDERAL COLLEGE OF EDUCATION (TECHNICAL), ASABA  (AFFL TO UNIBEN), DELTA STATE",
    InSt: 10,
    AFFL_ST_ID: 12,
    INTYP: 14,
    Inst_Cat: "FD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1458,
    INABV: "FCEOBUDU-DEG",
    INName:
      "FEDERAL COLLEGE OF EDUCATION, OBUDU, (AFFL TO UNICAL), CROSS RIVER STATE",
    InSt: 9,
    AFFL_ST_ID: 9,
    INTYP: 14,
    Inst_Cat: "FD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1459,
    INABV: "EDUMUNZ",
    INName:
      "FEDERAL COLLEGE OF EDU. (TECH.)UMUNZE( AFF TO NNAMDI AZIKIWE UNI), ANAMBRA STATE",
    InSt: 4,
    AFFL_ST_ID: 4,
    INTYP: 14,
    Inst_Cat: "FD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1460,
    INABV: "EDOMOK",
    INName:
      "FEDERAL COLLEGE OF EDUCATION (TECH.) ,OMOKU, (AFFL TO UNN), RIVERS STATE",
    InSt: 32,
    AFFL_ST_ID: 14,
    INTYP: 14,
    Inst_Cat: "FD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1461,
    INABV: "FCEYOLA-DEG",
    INName:
      "FEDERAL COLLEGE OF EDUCATION, YOLA, ADAMAWA STATE (AFFL TO UNIV OF UNIMAID)",
    InSt: 2,
    AFFL_ST_ID: 8,
    INTYP: 14,
    Inst_Cat: "FD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1462,
    INABV: "EDGUMEL",
    INName:
      "JIGAWA STATE COLLEGE OF EDUCATION, GUMEL, (AFFL TO BAYERO UNI), JIGAWA STATE",
    InSt: 17,
    AFFL_ST_ID: 19,
    INTYP: 14,
    Inst_Cat: "SD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1463,
    INABV: "EDKANO",
    INName:
      "SAADATU RIMI COLLEGE OF EDU, KUMBOTSO, KANO STATE (AFFL TO BAYERO UNIV, KANO)",
    InSt: 19,
    AFFL_ST_ID: 19,
    INTYP: 14,
    Inst_Cat: "SD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1464,
    INABV: "UHIELE",
    INName:
      "SEMINARY OF ALL SAINTS, EKPOMA, EDO STATE, (AFFL TO UNIBEN, BENIN)",
    InSt: 12,
    AFFL_ST_ID: 12,
    INTYP: 14,
    Inst_Cat: "PD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1465,
    INABV: "TRINITY",
    INName:
      "TRINITY THEOLOGICAL COLLEGE, UMUAHIA, ABIA STATE (AFFL TO UNIV OF NIG, NSUKKA)",
    InSt: 1,
    AFFL_ST_ID: 14,
    INTYP: 14,
    Inst_Cat: "PD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1466,
    INABV: "UMCA",
    INName: "UMCA, ILORIN (AFFLIATED TO UNIVERSITY OF IBADAN), KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 30,
    INTYP: 14,
    Inst_Cat: "PD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1467,
    INABV: "UMAR",
    INName:
      "UMAR IBN IBRAHIM EL-KANEMI COL OF EDU, SCIENCE AND TECH, BAMA, (AFFL TO UNIMAID)",
    InSt: 8,
    AFFL_ST_ID: 8,
    INTYP: 14,
    Inst_Cat: "SD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1468,
    INABV: "USCOE",
    INName:
      "UMAR SULEIMAN COLLEGE OF EDUCATION, GASHUA (AFFL TO UNIMAID), YOBE STATE",
    InSt: 35,
    AFFL_ST_ID: 8,
    INTYP: 14,
    Inst_Cat: "SD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1469,
    INABV: "WARRI",
    INName:
      "COLLEGE OF EDUCATION, WARRI (AFFLIATED TO DELTA STATE UNI. ABRAKA), DELTA STATE",
    InSt: 10,
    AFFL_ST_ID: 10,
    INTYP: 14,
    Inst_Cat: "SD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1470,
    INABV: "WATSI",
    INName:
      "WEST AFRICA THEOLOGICAL SEMINARY, IPAJA, LAGOS (AFFL TO UNIV OF NIGERIA, NSUKKA)",
    InSt: 24,
    AFFL_ST_ID: 14,
    INTYP: 14,
    Inst_Cat: "PD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1471,
    INABV: "OYOSPECIAL",
    INName:
      "FEDERAL COLLEGE OF EDUC. (SPECIAL), OYO, OYO STATE (AFF TO UNI. IBADAN)",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 14,
    Inst_Cat: "FD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1472,
    INABV: "FTBICHI-DEG",
    INName:
      "FEDERAL COLLEGE OF EDUCATION (TECH.), BICHI, (AFFILIATED TO ATBU), KANO STATE",
    InSt: 19,
    AFFL_ST_ID: 5,
    INTYP: 14,
    Inst_Cat: "FD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1475,
    INABV: "FCETG-DEG",
    INName:
      "FEDERAL COLLEGE OF EDUCATION (TECH), GUSAU, (AFFL TO ABU ZARIA), ZAMFARA STATE",
    InSt: 36,
    AFFL_ST_ID: 18,
    INTYP: 14,
    Inst_Cat: "FD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1477,
    INABV: "EUPTC-ITU",
    INName:
      "ESSIEN UKPABIO PRESBYTERIAN THEOLOGICAL COLLEGE, ITU, (AFFL TO UNICAL)",
    InSt: 3,
    AFFL_ST_ID: 9,
    INTYP: 14,
    Inst_Cat: "PD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1478,
    INABV: "KAT-ALA DEG",
    INName:
      "COLLEGE OF EDUCATION, KATSINA-ALA, BENUE STATE, (AFFL TO UNIV OF CALABAR)",
    InSt: 7,
    AFFL_ST_ID: 9,
    INTYP: 14,
    Inst_Cat: "SD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1479,
    INABV: "KICOE-DEG",
    INName:
      "KASHIM IBRAHIM COLLEGE OF EDUCATION, MAIDUGURI , BORNO STATE (AFFL TO UNIMAID)",
    InSt: 8,
    AFFL_ST_ID: 8,
    INTYP: 14,
    Inst_Cat: "SD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1480,
    INABV: "ETSK",
    INName:
      "ECWA THEOLOGICAL SEMINARY, PMB 35, KAGORO, KADUNA STATE (AFFL TO UNIV OF JOS)",
    InSt: 18,
    AFFL_ST_ID: 31,
    INTYP: 14,
    Inst_Cat: "PD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1482,
    INABV: "NCBC",
    INName: "NIGERIAN CHRISTIAN BIBLE COLLEGE, ABAK, AKWA IBOM STATE",
    InSt: 3,
    AFFL_ST_ID: 3,
    INTYP: 14,
    Inst_Cat: "PD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1483,
    INABV: "SHAGARI-2",
    INName:
      "SHEHU SHAGARI COLLEGE OF EDUCATION, SOKOTO STATE (AFFL TO UDU, SOKOTO)",
    InSt: 33,
    AFFL_ST_ID: 33,
    INTYP: 14,
    Inst_Cat: "SD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1484,
    INABV: "KAITA_COE",
    INName:
      "ISA KAITA COLLEGE OF EDUCATION, DUTSINMA, (AFFL ABU, ZARIA), KATSINA STATE",
    InSt: 20,
    AFFL_ST_ID: 18,
    INTYP: 14,
    Inst_Cat: "SD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1485,
    INABV: "LIFE_SEMI",
    INName:
      "LIFE THEOLOGICAL SEMINARY, LAGOS, LAGOS STATE. (AFFL TO UNIVERSITY  OF IBADAN)",
    InSt: 24,
    AFFL_ST_ID: 30,
    INTYP: 14,
    Inst_Cat: "PD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1486,
    INABV: "ZING-DEG",
    INName:
      "COLLEGE OF EDUCATION, ZING, (AFFILATED TO ABU, ZARIA), TARABA STATE",
    InSt: 34,
    AFFL_ST_ID: 18,
    INTYP: 14,
    Inst_Cat: "SD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1487,
    INABV: "NTI-DEG",
    INName:
      "NATIONAL TEACHERS INSTITUTE, KADUNA (AFFL TO UNIMAID), KADUNA STATE",
    InSt: 18,
    AFFL_ST_ID: 8,
    INTYP: 14,
    Inst_Cat: "FD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1488,
    INABV: "ATAPOLY-DG",
    INName:
      "ABUBAKAR TATARI ALI POLYTECHNIC, BAUCHI STATE. (AFFILIATED TO ATBU BAUCHI)",
    InSt: 5,
    AFFL_ST_ID: 5,
    INTYP: 14,
    Inst_Cat: "SD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1489,
    INABV: "PACOT",
    INName:
      "PETER ACHIMUGU COLLEGE OF THEOLOGY, ANKPA, (AFF TO KOGI STATE UNI), KOGI STATE",
    InSt: 22,
    AFFL_ST_ID: 22,
    INTYP: 14,
    Inst_Cat: "PD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1490,
    INABV: "ST-ALBERT",
    INName:
      "ST. ALBERT THE GREAT MAJOR SEMINARY, ABEOKUTA, (AFFL TO UNIBEN), OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 12,
    INTYP: 14,
    Inst_Cat: "PD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1491,
    INABV: "GIDANWAYA-DEG",
    INName:
      "KADUNA STATE COLLEGE OF EDUCATION, GIDAN-WAYA (AFFLIATTED TO ABU), KADUNA STATE",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 14,
    Inst_Cat: "SD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1492,
    INABV: "EDKANO-2",
    INName:
      "SAADATU RIMI COLLEGE OF EDU, KUMBOTSO, KANO STATE (AFFILIATED TO ABU, ZARIA)",
    InSt: 19,
    AFFL_ST_ID: 18,
    INTYP: 14,
    Inst_Cat: "SD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1493,
    INABV: "HONG-DEG",
    INName:
      "COLLEGE OF EDUCATION, HONG (AFFILIATED TO ABU, ZARIA), ADAMAWA STATE",
    InSt: 2,
    AFFL_ST_ID: 18,
    INTYP: 14,
    Inst_Cat: "SD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1494,
    INABV: "IKWO-DEG",
    INName:
      "EBONYI STATE COLLEGE OF EDUCATION, IKWO, (AFFILIATED TO EBSU) EBONYI STATE",
    InSt: 11,
    AFFL_ST_ID: 11,
    INTYP: 14,
    Inst_Cat: "SD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1495,
    INABV: "RUFAI-DEG",
    INName:
      "A.D. RUFAI COL. FOR LEGAL AND ISLAMIC STUDIES (AFF TO BAYERO UNI), BAUCHI STATE",
    InSt: 5,
    AFFL_ST_ID: 19,
    INTYP: 14,
    Inst_Cat: "PD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1496,
    INABV: "BCT-BENIN",
    INName:
      "BAPTIST COLLEGE OF THEOLOGY, BENIN CITY, EDO STATE (AFFL TO AMBROSE ALLI UNIV)",
    InSt: 12,
    AFFL_ST_ID: 12,
    INTYP: 14,
    Inst_Cat: "PD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1497,
    INABV: "EL-TAYEB",
    INName:
      "SHEIK IBRAHIM EL-TAYEB INSTITUTE, KANO, (AFFL TO BAYERO UNIV, KANO), KANO STATE",
    InSt: 19,
    AFFL_ST_ID: 19,
    INTYP: 14,
    Inst_Cat: "PD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1498,
    INABV: "AFIT-DEG",
    INName: "AIR FORCE INSTITUTE OF TECHNOLOGY, KADUNA, KADUNA STATE",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 14,
    Inst_Cat: "FD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1499,
    INABV: "REDEEMED",
    INName:
      "REDEEMED CHRISTIAN BIBLE COLLEGE, REDEMPTION CAMP, OGUN STATE ( AFFL TO UI)",
    InSt: 27,
    AFFL_ST_ID: 30,
    INTYP: 14,
    Inst_Cat: "PD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1502,
    INABV: "NOUN-ELUON",
    INName: "NATIONAL OPEN UNIVERSITY OF NIGERIAN - Elearning, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 15,
    Inst_Cat: "EL",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1600,
    INABV: "NOK",
    INName: "NOK UNIVERSITY, KACHIA, KADUNA STATE",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1601,
    INABV: "AUST",
    INName: "AFRICAN UNIVERSITY OF SCIENCE AND TECHNOLOGY, GARKI, FCT-ABUJA",
    InSt: 37,
    AFFL_ST_ID: 37,
    INTYP: 16,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1602,
    INABV: "AL-ANSAR",
    INName: "AL-ANSAR UNIVERSITY, MAIDUGURI, BORNO STATE",
    InSt: 8,
    AFFL_ST_ID: 8,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1603,
    INABV: "PRU",
    INName: "PEN RESOURCE UNIVERSITY, GOMBE, GOMBE STATE",
    InSt: 15,
    AFFL_ST_ID: 15,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1604,
    INABV: "MLU",
    INName: "MARGARET LAWRENCE UNIVERSITY, GALILEE, DELTA STATE",
    InSt: 10,
    AFFL_ST_ID: 10,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1605,
    INABV: "NUS",
    INName: "NORTHWEST UNIVERSITY, SOKOTO, SOKOTO STATE",
    InSt: 33,
    AFFL_ST_ID: 33,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1606,
    INABV: "BAUK",
    INName: "BABA AHMED UNIVERSITY, KANO, KANO STATE",
    InSt: 19,
    AFFL_ST_ID: 19,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1607,
    INABV: "NBU",
    INName: "NIGERIAN BRITISH UNIVERSITY, ASA, ABIA STATE",
    InSt: 1,
    AFFL_ST_ID: 1,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1608,
    INABV: "KIRU",
    INName:
      "KHALIFA ISYAKU RABIU UNIVERSITY, GADON KAYA CITY GATE, KANO, KANO STATE",
    InSt: 19,
    AFFL_ST_ID: 19,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1609,
    INABV: "PETER-UNI",
    INName: "PETER UNIVERSITY, ACHINA-ONNEH, ANAMBRA STATE",
    InSt: 4,
    AFFL_ST_ID: 4,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1610,
    INABV: "SAISA",
    INName:
      "SAISA UNIVERSITY OF MEDICAL SCIENCE AND TECHNOLOGY, SOKOTO, SOKOTO STATE",
    InSt: 33,
    AFFL_ST_ID: 33,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1611,
    INABV: "EUN",
    INName: "EUROPEAN UNIVERSITY OF NIGERIA, DUBOYI, ABUJA, FCT",
    InSt: 37,
    AFFL_ST_ID: 37,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1612,
    INABV: "SPORTS-UNI",
    INName: "SPORTS UNIVERSITY, IDUMUJE UGBOKO, DELTA STATE",
    InSt: 10,
    AFFL_ST_ID: 10,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1613,
    INABV: "NEWGATE",
    INName: "NEWGATE UNIVERSITY, MINNA, NIGER STATE",
    InSt: 26,
    AFFL_ST_ID: 26,
    INTYP: 13,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 1801,
    INABV: "KWCOE-DEG",
    INName:
      "KWARA STATE COLLEGE OF EDUCATION, ILORIN, (AFFL TO EKITI STATE UNI), KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 13,
    INTYP: 18,
    Inst_Cat: "SD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1802,
    INABV: "GUSAU-DEG",
    INName:
      "FEDERAL COLLEGE OF EDUCATION, GUSAU, (AFFL TO FUT, MINNA), ZAMFARA STATE",
    InSt: 36,
    AFFL_ST_ID: 26,
    INTYP: 18,
    Inst_Cat: "FD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1803,
    INABV: "WAKABIU-DEG",
    INName:
      "COLLEGE OF EDUCATION, WAKA-BIU (AFFILIATED TO UNI MAID), BORNO STATE",
    InSt: 8,
    AFFL_ST_ID: 8,
    INTYP: 18,
    Inst_Cat: "SD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1804,
    INABV: "AKSCOE-DEG",
    INName:
      "AKWA IBOM STATE COLLEGE OF EDU, AFAHA-NSIT (AFFL TO UNI UYO), AKWA IBOM STATE",
    InSt: 3,
    AFFL_ST_ID: 3,
    INTYP: 18,
    Inst_Cat: "SD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1805,
    INABV: "ORO-DEG",
    INName:
      "KWARA STATE COLLEGE OF EDUCATION, ORO, (AFFL TO EKITI STATE UNI), KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 13,
    INTYP: 18,
    Inst_Cat: "SD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1806,
    INABV: "IMTENU-DEG",
    INName:
      "INSTITUTE OF MANAGEMENT AND TECHNOLOGY, (AFFILIATED TO UNN), ENUGU STATE",
    InSt: 14,
    AFFL_ST_ID: 14,
    INTYP: 18,
    Inst_Cat: "SD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1807,
    INABV: "AKAMKPA-DEG",
    INName:
      "CROSS RIVER STATE COL. OF EDU, AKAMKPA, (AFFL TO UNI CALABAR), CROSS RIVER STATE",
    InSt: 9,
    AFFL_ST_ID: 9,
    INTYP: 18,
    Inst_Cat: "SD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1808,
    INABV: "KADPOL-ABU",
    INName:
      "KADUNA POLYTECHNIC, (AFFILIATED TO AHMADU BELLO UNIVERSITY, ZARIA), KADUNA STATE",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 18,
    Inst_Cat: "FD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1809,
    INABV: "IJEBUODE-D",
    INName:
      "TAI SOLARIN COLLEGE OF EDUCATION, IJEBU-ODE, (AFFILITED TO OOU), OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 27,
    INTYP: 18,
    Inst_Cat: "PD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1810,
    INABV: "BIDA-DEG",
    INName:
      "FEDERAL POLYTECHNIC, BIDA, (AFFILIATED TO FUT, MINNA), NIGER STATE",
    InSt: 26,
    AFFL_ST_ID: 26,
    INTYP: 18,
    Inst_Cat: "FD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1811,
    INABV: "FEDPOOFFA-DEG",
    INName:
      "FEDERAL POLYTECHNIC, OFFA, (AFFILIATED TO FUT, MINNA), KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 26,
    INTYP: 18,
    Inst_Cat: "FD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1812,
    INABV: "AMUFU-DEG",
    INName:
      "COLLEGE OF EDUCATION, EHA-AMUFU, (AFFLILIATED TO UNN), ENUGU STATE",
    InSt: 14,
    AFFL_ST_ID: 14,
    INTYP: 18,
    Inst_Cat: "FD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1813,
    INABV: "OJU-DEG",
    INName:
      "COLLEGE OF EDUCATION, OJU, BENUE STATE (AFFILIATED TO BENUE STATE UNIVERSITY)",
    InSt: 7,
    AFFL_ST_ID: 7,
    INTYP: 18,
    Inst_Cat: "SD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1814,
    INABV: "MOSOGAR-DEG",
    INName:
      "DELTA STATE COLLEGE OF PHYSICAL EDUCATION, MOSOGAR (AFFILIATED TO UNI. OF BENIN)",
    InSt: 10,
    AFFL_ST_ID: 12,
    INTYP: 18,
    Inst_Cat: "SD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1815,
    INABV: "NSCOE-DG",
    INName:
      "NIGER STATE COLLEGE OF EDUCATION, MINNA, (AFFL TO USMANU DANFODIYO UNI, SOKOTO)",
    InSt: 26,
    AFFL_ST_ID: 33,
    INTYP: 18,
    Inst_Cat: "SD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1816,
    INABV: "ABU-ODL",
    INName:
      "AHMADU BELLO UNIVERSITY OPEN &amp; DISTANCE LEARNING PROGRAMME, ZARIA, KADUNA STATE",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 18,
    Inst_Cat: "FDL",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1817,
    INABV: "KATPOLY-DEG",
    INName:
      "HASSAN USMAN KATSINA POLYTECHNIC, KATSINA, (AFFL TO FUT, MINNA), KATSINA STATE",
    InSt: 20,
    AFFL_ST_ID: 26,
    INTYP: 18,
    Inst_Cat: "SD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1818,
    INABV: "FEDPOBAU-DEG",
    INName: "FEDERAL POLYTECHNIC, BAUCHI, (AFFL TO ATBU, BAUCHI), BAUCHI STATE",
    InSt: 5,
    AFFL_ST_ID: 5,
    INTYP: 18,
    Inst_Cat: "FD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1819,
    INABV: "NASE-DEG",
    INName:
      "NIGERIAN ARMY SCHOOL OF EDUCATION, ILORIN, ( AFFL TO TAI SOLARIN UNI OF EDU, IJA",
    InSt: 23,
    AFFL_ST_ID: 27,
    INTYP: 18,
    Inst_Cat: "FD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1820,
    INABV: "ZUBA-DEG",
    INName:
      "FCT COLLEGE OF EDUCATION, ZUBA ,( AFFILIATED TO ABU, ZARIA), FCT-ABUJA",
    InSt: 37,
    AFFL_ST_ID: 18,
    INTYP: 18,
    Inst_Cat: "SD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1821,
    INABV: "WARRICOE-DEG",
    INName: "COLLEGE OF EDUCATION, WARRI, DELTA STATE, (AFFLIATED TO UNIBEN)",
    InSt: 10,
    AFFL_ST_ID: 12,
    INTYP: 18,
    Inst_Cat: "SD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1822,
    INABV: "VOM-DEG",
    INName:
      "COLLEGE OF ANIMAL HEALTH &amp; PRO. TECH. VOM, (AFFL TO ATBU, BAUCHI), PLATEAU STATE",
    InSt: 31,
    AFFL_ST_ID: 31,
    INTYP: 18,
    Inst_Cat: "FD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1823,
    INABV: "MOSOGAR-DG2",
    INName:
      "COLLEGE OF EDUCATION, MOSOGAR, DELTA STATE. (AFFL TO DELTA STATE UNIV, ABRAKA)",
    InSt: 10,
    AFFL_ST_ID: 10,
    INTYP: 18,
    Inst_Cat: "SD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1824,
    INABV: "EDOMOK-UYO",
    INName:
      "FEDERAL COLLEGE OF EDUCATION (TECH.), OMOKU, (AFFL TO UNI. OF UYO), RIVERS STATE",
    InSt: 32,
    AFFL_ST_ID: 3,
    INTYP: 18,
    Inst_Cat: "FD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1825,
    INABV: "FCDTT-FUTO",
    INName:
      "FEDERAL COLLEGE OF DENTAL TECH. AND THERAPY, ENUGU, (AFFL TO FUTO), ENUGU STATE",
    InSt: 14,
    AFFL_ST_ID: 16,
    INTYP: 18,
    Inst_Cat: "FD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1826,
    INABV: "CST-NDU",
    INName:
      "COLLEGE OF SEMINARY AND THEOLOGY, IRRI, (AFFILIATED TO NDU), DELTA STATE",
    InSt: 10,
    AFFL_ST_ID: 6,
    INTYP: 18,
    Inst_Cat: "PD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1827,
    INABV: "MINILS-DEG",
    INName:
      "MICHAEL IMODU NAT. INST. FOR LABOUR STD. ILORIN,(AFFL TO UNIILORIN), KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 23,
    INTYP: 18,
    Inst_Cat: "PD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1828,
    INABV: "SAIK",
    INName:
      "ST. ALBERT INSTITUTE, KAFANCHAN, KADUNA STATE (AFFL TO UNIVERSITY JOS)",
    InSt: 18,
    AFFL_ST_ID: 31,
    INTYP: 14,
    Inst_Cat: "PD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1829,
    INABV: "UUST-DEG",
    INName:
      "UMA UKPAI SCHOOL OF THEOLOGY, UYO, AKWA IBOM STATE (AFFL TO UNIVERSITY OF UYO)",
    InSt: 3,
    AFFL_ST_ID: 3,
    INTYP: 18,
    Inst_Cat: "PD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1830,
    INABV: "FEDPOADO-DEG",
    INName:
      "FEDERAL POLYTECHNIC, ADO-EKITI, EKITI STATE (AFFILIATED TO FUT, MINNA)",
    InSt: 13,
    AFFL_ST_ID: 26,
    INTYP: 18,
    Inst_Cat: "FD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1831,
    INABV: "IJBCOE-DEG",
    INName:
      "ISAAC JASPER BORO COLLEGE OF EDUCTION, SAGBAMA, (AFFILIATED TO NIGER DELTA UNIV)",
    InSt: 6,
    AFFL_ST_ID: 6,
    INTYP: 18,
    Inst_Cat: "SD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1832,
    INABV: "ASPOL-DEG",
    INName:
      "ADAMAWA STATE POLYTECHNIC, YOLA (AFFILIATED TO UNIVERSITY OF MAIDUGURI)",
    InSt: 2,
    AFFL_ST_ID: 8,
    INTYP: 18,
    Inst_Cat: "SD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1833,
    INABV: "SITECH-DEG",
    INName:
      "SAVANNAH INSTITUTE OF TECH, ABAKALIKI (AFFILIATED TO FEDERAL UNIVERSITY, WUKARI)",
    InSt: 11,
    AFFL_ST_ID: 34,
    INTYP: 18,
    Inst_Cat: "PD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1834,
    INABV: "EMIRATES-D",
    INName:
      "EMIRATES COLLEGE OF HEALTH SCIENCES AND TECH, KANO (AFFL TO KWARA STATE UNIV)",
    InSt: 19,
    AFFL_ST_ID: 23,
    INTYP: 18,
    Inst_Cat: "PD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1835,
    INABV: "PCE-DEG",
    INName:
      "PEACELAND COLLEGE OF EDUCATION, ENUGU (AFFL TO ABIA STATE UNIVERSITY, UTURU)",
    InSt: 14,
    AFFL_ST_ID: 1,
    INTYP: 18,
    Inst_Cat: "PD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1836,
    INABV: "MCF-DEG",
    INName:
      "MICHAEL AND CECILIA FOUNDATION COLLEGE OF EDU, UGHELLI, (AFFL TO DELSU, ABRAKA)",
    InSt: 10,
    AFFL_ST_ID: 10,
    INTYP: 18,
    Inst_Cat: "PD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1837,
    INABV: "ILORIN-ODL",
    INName:
      "OPEN &amp; DISTANCE LEARNING PROGRAMME, UNIVERSITY OF ILORIN, KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 23,
    INTYP: 18,
    Inst_Cat: "FDL",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1838,
    INABV: "AICI",
    INName:
      "ADAMS IGBUDU CHRISTIAN INSTITUTE, EMEVOR, (AFFL TO UNIPORT), DELTA STATE",
    InSt: 10,
    AFFL_ST_ID: 32,
    INTYP: 18,
    Inst_Cat: "PD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 1839,
    INABV: "AIM",
    INName:
      "AUGUSTINIAN INSTITUTE, MAKURDI, (AFFL TO BENUE STATE UNIVERSITY) BENUE STATE",
    InSt: 7,
    AFFL_ST_ID: 7,
    INTYP: 18,
    Inst_Cat: "PD",
    category: "Degree Awarding Institutions (Affliate)",
  },
  {
    INID: 3101,
    INABV: "AFIT",
    INName: "AIR FORCE INSTITUTE OF TECHNOLOGY, KADUNA, KADUNA STATE",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 31,
    Inst_Cat: "FP",
    category: "Polytechnic",
  },
  {
    INID: 3102,
    INABV: "AUCHIPOLY",
    INName: "AUCHI POLYTECHNIC, AUCHI, EDO STATE",
    InSt: 12,
    AFFL_ST_ID: 12,
    INTYP: 31,
    Inst_Cat: "FP",
    category: "Polytechnic",
  },
  {
    INID: 3103,
    INABV: "FEDPO-ADO",
    INName: "FEDERAL POLYTECHNIC, ADO-EKITI, EKITI STATE",
    InSt: 13,
    AFFL_ST_ID: 13,
    INTYP: 31,
    Inst_Cat: "FP",
    category: "Polytechnic",
  },
  {
    INID: 3104,
    INABV: "FEDPO-AIU",
    INName: "AKANU IBIAM FEDERAL POLYTECHNIC, UNWANA, AFIKPO, EBONYI STATE",
    InSt: 11,
    AFFL_ST_ID: 11,
    INTYP: 31,
    Inst_Cat: "FP",
    category: "Polytechnic",
  },
  {
    INID: 3105,
    INABV: "FEDPO-BAU",
    INName: "FEDERAL POLYTECHNIC, BAUCHI, BAUCHI STATE",
    InSt: 5,
    AFFL_ST_ID: 5,
    INTYP: 31,
    Inst_Cat: "FP",
    category: "Polytechnic",
  },
  {
    INID: 3106,
    INABV: "FEDPO-BAL",
    INName: "FEDERAL POLYTECHNIC, BALI, TARABA STATE",
    InSt: 34,
    AFFL_ST_ID: 34,
    INTYP: 31,
    Inst_Cat: "FP",
    category: "Polytechnic",
  },
  {
    INID: 3107,
    INABV: "FEDPO-BID",
    INName: "FEDERAL POLYTECHNIC, BIDA, NIGER STATE",
    InSt: 26,
    AFFL_ST_ID: 26,
    INTYP: 31,
    Inst_Cat: "FP",
    category: "Polytechnic",
  },
  {
    INID: 3108,
    INABV: "FEDPO-DAM",
    INName: "FEDERAL POLYTECHNIC, DAMATURU, YOBE STATE",
    InSt: 35,
    AFFL_ST_ID: 35,
    INTYP: 31,
    Inst_Cat: "FP",
    category: "Polytechnic",
  },
  {
    INID: 3109,
    INABV: "FEDPO-EDE",
    INName: "FEDERAL POLYTECHNIC, EDE, OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 31,
    Inst_Cat: "FP",
    category: "Polytechnic",
  },
  {
    INID: 3110,
    INABV: "FEDPO-EKO",
    INName: "FEDERAL POLYTECHNIC, EKOWE, BAYELSA STATE",
    InSt: 6,
    AFFL_ST_ID: 6,
    INTYP: 31,
    Inst_Cat: "FP",
    category: "Polytechnic",
  },
  {
    INID: 3111,
    INABV: "FEDPO-IDAH",
    INName: "FEDERAL POLYTECHNIC, IDAH, KOGI STATE",
    InSt: 22,
    AFFL_ST_ID: 22,
    INTYP: 31,
    Inst_Cat: "FP",
    category: "Polytechnic",
  },
  {
    INID: 3112,
    INABV: "FEDPO-ILA",
    INName: "FEDERAL POLYTECHNIC, ILARO, OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 27,
    INTYP: 31,
    Inst_Cat: "FP",
    category: "Polytechnic",
  },
  {
    INID: 3113,
    INABV: "FEDPO-MUB",
    INName: "FEDERAL POLYTECHNIC, MUBI, ADAMAWA STATE",
    InSt: 2,
    AFFL_ST_ID: 2,
    INTYP: 31,
    Inst_Cat: "FP",
    category: "Polytechnic",
  },
  {
    INID: 3114,
    INABV: "FEDPO-NAM",
    INName: "FEDERAL POLYTECHNIC, KAURA/NAMODA, ZAMFARA STATE",
    InSt: 36,
    AFFL_ST_ID: 36,
    INTYP: 31,
    Inst_Cat: "FP",
    category: "Polytechnic",
  },
  {
    INID: 3115,
    INABV: "FEDPO-NAS",
    INName: "FEDERAL POLYTECHNIC, NASARAWA, NASARAWA STATE",
    InSt: 25,
    AFFL_ST_ID: 25,
    INTYP: 31,
    Inst_Cat: "FP",
    category: "Polytechnic",
  },
  {
    INID: 3116,
    INABV: "FEDPO-NEK",
    INName: "FEDERAL POLYTECHNIC, NEKEDE, IMO STATE",
    InSt: 16,
    AFFL_ST_ID: 16,
    INTYP: 31,
    Inst_Cat: "FP",
    category: "Polytechnic",
  },
  {
    INID: 3117,
    INABV: "FEDPO-OFFA",
    INName: "FEDERAL POLYTECHNIC, OFFA, KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 23,
    INTYP: 31,
    Inst_Cat: "FP",
    category: "Polytechnic",
  },
  {
    INID: 3118,
    INABV: "FEDPO-OKO",
    INName: "FEDERAL POLYTECHNIC, OKO, ANAMBRA STATE",
    InSt: 4,
    AFFL_ST_ID: 4,
    INTYP: 31,
    Inst_Cat: "FP",
    category: "Polytechnic",
  },
  {
    INID: 3119,
    INABV: "FEDCTEN",
    INName:
      "FEDERAL COLLEGE OF DENTAL TECHNOLOGY AND THERAPY, ENUGU, ENUGU STATE",
    InSt: 14,
    AFFL_ST_ID: 14,
    INTYP: 31,
    Inst_Cat: "FP",
    category: "Polytechnic",
  },
  {
    INID: 3120,
    INABV: "KAD-POLY",
    INName: "KADUNA POLYTECHNIC, KADUNA, KADUNA STATE",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 31,
    Inst_Cat: "FP",
    category: "Polytechnic",
  },
  {
    INID: 3121,
    INABV: "FEDPO-KAZAURE",
    INName: "HUSSAINI ADAMU FEDERAL POLYTECHNIC, KAZAURE, JIGAWA STATE",
    InSt: 17,
    AFFL_ST_ID: 17,
    INTYP: 31,
    Inst_Cat: "FP",
    category: "Polytechnic",
  },
  {
    INID: 3122,
    INABV: "NACEST",
    INName:
      "NIGERIA ARMY COLLEGE OF ENVIRONMENTAL SCIENCE AND TECH, MAKURDI, BENUE STATE",
    InSt: 7,
    AFFL_ST_ID: 7,
    INTYP: 31,
    Inst_Cat: "FP",
    category: "Polytechnic",
  },
  {
    INID: 3123,
    INABV: "WUFPOBK",
    INName: "WAZIRI UMARU FEDERAL POLYTECHNIC, BIRNIN KEBBI, KEBBI STATE",
    InSt: 21,
    AFFL_ST_ID: 21,
    INTYP: 31,
    Inst_Cat: "FP",
    category: "Polytechnic",
  },
  {
    INID: 3124,
    INABV: "YABATECH",
    INName: "YABA COLLEGE OF TECHNOLOGY, YABA, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 31,
    Inst_Cat: "FP",
    category: "Polytechnic",
  },
  {
    INID: 3125,
    INABV: "FEDPO-UKA",
    INName: "FEDERAL POLYTECHNIC, UKANA, AKWA IBOM STATE",
    InSt: 3,
    AFFL_ST_ID: 3,
    INTYP: 31,
    Inst_Cat: "FP",
    category: "Polytechnic",
  },
  {
    INID: 3127,
    INABV: "FEDPO-OLUJI",
    INName: "FEDERAL POLYTECHNIC, ILE-OLUJI, ONDO STATE",
    InSt: 28,
    AFFL_ST_ID: 28,
    INTYP: 31,
    Inst_Cat: "FP",
    category: "Polytechnic",
  },
  {
    INID: 3128,
    INABV: "NICT-UROMI",
    INName: "NATIONAL INSTITUTE OF CONSTRUCTION TECHNOLOGY, UROMI, EDO STATE",
    InSt: 12,
    AFFL_ST_ID: 12,
    INTYP: 31,
    Inst_Cat: "FP",
    category: "Polytechnic",
  },
  {
    INID: 3129,
    INABV: "FEDPO-OIL",
    INName: "FEDERAL POLYTECHNIC OF OIL AND GAS, BONNY, RIVERS STATE",
    InSt: 32,
    AFFL_ST_ID: 32,
    INTYP: 31,
    Inst_Cat: "FP",
    category: "Polytechnic",
  },
  {
    INID: 3130,
    INABV: "FEDPO-DAURA",
    INName: "FEDERAL POLYTECHNIC, DAURA, KATSINA STATE",
    InSt: 20,
    AFFL_ST_ID: 20,
    INTYP: 31,
    Inst_Cat: "FP",
    category: "Polytechnic",
  },
  {
    INID: 3131,
    INABV: "FEDPO-KALTUNGO",
    INName: "FEDERAL POLYTECHNIC, KALTUNGO, GOMBE STATE",
    InSt: 15,
    AFFL_ST_ID: 15,
    INTYP: 31,
    Inst_Cat: "FP",
    category: "Polytechnic",
  },
  {
    INID: 3132,
    INABV: "FEDPO-PLATEAU",
    INName: "FEDERAL POLYTECHNIC, PLATEAU STATE",
    InSt: 31,
    AFFL_ST_ID: 31,
    INTYP: 31,
    Inst_Cat: "FP",
    category: "Polytechnic",
  },
  {
    INID: 3133,
    INABV: "FEDPO-ENUGU",
    INName: "FEDERAL POLYTECHNIC, ENUGU STATE",
    InSt: 14,
    AFFL_ST_ID: 14,
    INTYP: 31,
    Inst_Cat: "FP",
    category: "Polytechnic",
  },
  {
    INID: 3134,
    INABV: "FP-OYO",
    INName: "FEDERAL POLYTECHNIC, AYEDE-OGO OLUWA, OYO STATE",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 31,
    Inst_Cat: "FP",
    category: "Polytechnic",
  },
  {
    INID: 3135,
    INABV: "FP-CR",
    INName: "FEDERAL POLYTECHNIC, CROSS RIVER STATE",
    InSt: 9,
    AFFL_ST_ID: 9,
    INTYP: 31,
    Inst_Cat: "FP",
    category: "Polytechnic",
  },
  {
    INID: 3136,
    INABV: "NITT-ND",
    INName:
      "NIGERIAN INSTITUTE OF TRANSPORT TECHNOLOGY (ND), ZARIA, KADUNA STATE",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 31,
    Inst_Cat: "FP",
    category: "Polytechnic",
  },
  {
    INID: 3201,
    INABV: "AAP",
    INName: "ABRAHAM ADESANYA POLYTECHNIC, IJEBU-IGBO, OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 27,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3202,
    INABV: "ABIAPOLY",
    INName: "ABIA STATE POLYTECHNIC, ABA, ABIA STATE",
    InSt: 1,
    AFFL_ST_ID: 1,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3203,
    INABV: "ACHTECH",
    INName:
      "ABIA STATE COLLEGE OF HEALTH SCIENCES AND MANAGEMENT TECHNOLOGY, ABA, ABIA STATE",
    InSt: 1,
    AFFL_ST_ID: 1,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3204,
    INABV: "AG-POLY",
    INName: "ABDU GUSAU POLYTECHNIC, TALATA-MAFARA, ZAMFARA STATE",
    InSt: 36,
    AFFL_ST_ID: 36,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3205,
    INABV: "AKCAS",
    INName:
      "AKWA IBOM STATE COLLEGE OF ARTS AND SCIENCE NUNG, UKIM, AKWA IBOM STATE",
    InSt: 3,
    AFFL_ST_ID: 3,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3206,
    INABV: "AKPOLY",
    INName: "AKWA-IBOM STATE POLYTECHNIC, IKOT-OSURUA, AKWA IBOM STATE",
    InSt: 3,
    AFFL_ST_ID: 3,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3207,
    INABV: "ALOOMA",
    INName: "MAI IDRIS ALOOMA POLYTECHNIC, GEIDAM, YOBE STATE",
    InSt: 35,
    AFFL_ST_ID: 35,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3208,
    INABV: "ATAPOLY",
    INName: "ABUBAKAR TATARI ALI POLYTECHNIC, BAUCHI, BAUCHI STATE",
    InSt: 5,
    AFFL_ST_ID: 5,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3209,
    INABV: "BYSPOLY",
    INName: "BAYELSA STATE POLYTECHNIC, ALEIBIRI, BAYELSA STATE",
    InSt: 6,
    AFFL_ST_ID: 6,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3210,
    INABV: "BEN-POLY",
    INName: "BENUE STATE POLYTECHNIC, UGBOKOLO, BENUE STATE",
    InSt: 7,
    AFFL_ST_ID: 7,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3211,
    INABV: "CABSP",
    INName:
      "COLLEGE OF ADMINISTRATIVE AND BUSINESS STUDIES, POTISKUM, YOBE STATE",
    InSt: 35,
    AFFL_ST_ID: 35,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3212,
    INABV: "D.S. ADEGBENRO",
    INName: "D.S. ADEGBENRO ICT POLYTECHNIC, ITORI, OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 27,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3214,
    INABV: "OGITECH",
    INName:
      "OGUN STATE INSTITUTE OF TECH. (FORMERLY GATEWAY ICT POLY), IGBESA, OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 27,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3215,
    INABV: "IBD-POLY",
    INName: "THE POLYTECHNIC, IBADAN, OYO STATE",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3216,
    INABV: "IMO-POLY",
    INName: "IMO STATE POLYTECHNIC, UMUAGWO, OWERRI, IMO STATE",
    InSt: 16,
    AFFL_ST_ID: 16,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3217,
    INABV: "IMT-ENU",
    INName: "INSTITUTE OF MANAGEMENT AND TECHNOLOGY, ENUGU, ENUGU STATE",
    InSt: 14,
    AFFL_ST_ID: 14,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3218,
    INABV: "JIG-POLY",
    INName: "JIGAWA STATE POLYTECHNIC, DUTSE, JIGAWA STATE",
    InSt: 17,
    AFFL_ST_ID: 17,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3219,
    INABV: "KAN-POLY",
    INName: "KANO STATE POLYTECHNIC, KANO, KANO STATE",
    InSt: 19,
    AFFL_ST_ID: 19,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3220,
    INABV: "KAT-POLY",
    INName: "HASSAN USMAN KATSINA POLYTECHNIC, KATSINA, KATSINA STATE",
    InSt: 20,
    AFFL_ST_ID: 20,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3221,
    INABV: "KOGI-POLY",
    INName: "KOGI STATE POLYTECHNIC, LOKOJA, KOGI STATE",
    InSt: 22,
    AFFL_ST_ID: 22,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3222,
    INABV: "KWA-POLY",
    INName: "KWARA STATE POLYTECHNIC, ILORIN, KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 23,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3223,
    INABV: "LASPOTECH",
    INName: "LAGOS STATE POLYTECHNIC, IKORODU, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3224,
    INABV: "MAPOLY",
    INName: "MOSHOOD ABIOLA POLYTECHNIC, ABEOKUTA, OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 27,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3225,
    INABV: "IMA-POLY",
    INName: "ISA MUSTAPHA AGWAI I POLYTECHNIC, LAFIA, NASARAWA STATE",
    InSt: 25,
    AFFL_ST_ID: 25,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3226,
    INABV: "NBPZ",
    INName: "NUHU BAMALLI POLYTECHNIC, ZARIA, KADUNA STATE",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3227,
    INABV: "OGWASHIUKU",
    INName: "DELTA STATE POLYTECHNIC, OGWASHI-UKU, DELTA STATE",
    InSt: 10,
    AFFL_ST_ID: 10,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3228,
    INABV: "OSUNPOLY",
    INName: "OSUN STATE POLYTECHNIC, IREE, OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3229,
    INABV: "OSUNTECH",
    INName: "OSUN STATE COLLEGE OF TECHNOLOGY, ESA-OKE, OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3230,
    INABV: "OTEFE",
    INName: "DELTA STATE POLYTECHNIC, OTEFE, DELTA STATE",
    InSt: 10,
    AFFL_ST_ID: 10,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3231,
    INABV: "OZORO",
    INName: "DELTA STATE POLYTECHNIC, OZORO, DELTA STATE",
    InSt: 10,
    AFFL_ST_ID: 10,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3232,
    INABV: "PLS-POLY",
    INName: "PLATEAU STATE POLYTECHNIC, BARKIN-LADI, PLATEAU STATE",
    InSt: 31,
    AFFL_ST_ID: 31,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3233,
    INABV: "UAS-POLY",
    INName: "UMARU ALI SHINKAFI POLYTECHNIC, SOKOTO, SOKOTO STATE",
    InSt: 33,
    AFFL_ST_ID: 33,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3234,
    INABV: "POLY-YOLA",
    INName: "ADAMAWA STATE POLYTECHNIC, YOLA, ADAMAWA STATE",
    InSt: 2,
    AFFL_ST_ID: 2,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3235,
    INABV: "RAMAT-POLY",
    INName: "RAMAT POLYTECHNIC, MAIDUGURI, BORNO STATE",
    InSt: 8,
    AFFL_ST_ID: 8,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3236,
    INABV: "PH-POLY",
    INName: "PORT HARCOURT POLYTECHNIC, RUMUOLA, PORT HARCOURT, RIVERS STATE",
    InSt: 32,
    AFFL_ST_ID: 32,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3237,
    INABV: "RIVCOST",
    INName:
      "RIVERS  STATE COLLEGE OF HEALTH SCIENCE, IKWERRE RD, RUMUEME, PORT HARCOURT",
    InSt: 32,
    AFFL_ST_ID: 32,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3238,
    INABV: "KENPOLY",
    INName: "KENULE BENSON SARO-WIWA POLYTECHNIC, BORI, RIVERS STATE",
    InSt: 32,
    AFFL_ST_ID: 32,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3239,
    INABV: "RUGIPO",
    INName: "RUFUS GIWA POLYTECHNIC, OWO, ONDO STATE",
    InSt: 28,
    AFFL_ST_ID: 28,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3240,
    INABV: "SAAPADE",
    INName: "GATEWAY ICT POLYTECHNIC, SAAPADE, OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 27,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3241,
    INABV: "USEN",
    INName: "EDO STATE POLYTECHNIC, USEN, EDO STATE",
    InSt: 12,
    AFFL_ST_ID: 12,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3242,
    INABV: "ZUNGERU",
    INName: "NIGER STATE POLYTECHNIC, ZUNGERU, NIGER STATE",
    InSt: 26,
    AFFL_ST_ID: 26,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3252,
    INABV: "ITM-UGEP",
    INName:
      "CROSS RIVER STATE INSTITUTE OF TECH AND MANAGEMENT, UGEP, CROSS RIVER STATE",
    InSt: 9,
    AFFL_ST_ID: 9,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3253,
    INABV: "GBOKO-POLY",
    INName: "GBOKO POLYTECHNIC, GBOKO, BENUE STATE",
    InSt: 7,
    AFFL_ST_ID: 7,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3254,
    INABV: "TAR-POLY",
    INName: "TARABA STATE POLYTECHNIC, SUNTAI, TARABA STATE",
    InSt: 34,
    AFFL_ST_ID: 34,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3255,
    INABV: "AO-POLY",
    INName: "ADESEUN OGUNDOYIN POLYTECHNIC, ERUWA, OYO STATE",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3256,
    INABV: "ENU-POLY",
    INName: "ENUGU STATE POLYTECHNIC, IWOLLO, ENUGU STATE",
    InSt: 14,
    AFFL_ST_ID: 14,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3257,
    INABV: "ANSPOLY",
    INName: "ANAMBRA STATE POLYTECHNIC, MGBAKWU, ANAMBRA STATE",
    InSt: 4,
    AFFL_ST_ID: 4,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3258,
    INABV: "RUFAI-ND",
    INName:
      "A.D RUFAI COL. OF EDUCATION, LEGAL &amp; GENERAL STUDIES (ND), MISAU, BAUCHI STATE",
    InSt: 5,
    AFFL_ST_ID: 5,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3259,
    INABV: "POLY-OMUO",
    INName: "THE POLYTECHNIC, OMUO-EKITI, EKITI STATE",
    InSt: 13,
    AFFL_ST_ID: 13,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3260,
    INABV: "GSPB",
    INName: "GOMBE STATE POLYTECHNIC, BAJOGA, GOMBE STATE",
    InSt: 15,
    AFFL_ST_ID: 15,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3261,
    INABV: "POLY-OJOKU",
    INName: "THE POLYTECHNIC, OJOKU, KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 23,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3301,
    INABV: "ALLOVER-POLY",
    INName:
      "ALLOVER CENTRAL POLYTECHNIC, LYNSON CHEMICAL AVENUE , OTA, OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 27,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3302,
    INABV: "CROWN",
    INName: "CROWN POLYTECHNIC, ODO, ADO-EKITI, EKITI STATE",
    InSt: 13,
    AFFL_ST_ID: 13,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3303,
    INABV: "COVENANT-POLY",
    INName: "COVENANT POLYTECHNIC, ABA, ABIA STATE",
    InSt: 1,
    AFFL_ST_ID: 1,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3304,
    INABV: "POLY-IRESI",
    INName: "THE POLYTECHNIC, IRESI, OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3305,
    INABV: "DORBEN",
    INName: "DORBEN POLYTECHNIC, BWARI-GARAM ROAD, FCT-ABUJA",
    InSt: 37,
    AFFL_ST_ID: 37,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3306,
    INABV: "FIDEIPOLY",
    INName: "FIDEI POLYTECHNIC, GBOKO, BENUE STATE",
    InSt: 7,
    AFFL_ST_ID: 7,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3307,
    INABV: "GRACE-POLY",
    INName: "GRACE POLYTECHNIC, LAGOS, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3308,
    INABV: "HERITAGE",
    INName: "HERITAGE POLYTECHNIC, IKOT UDOTA, AKWA IBOM STATE",
    InSt: 3,
    AFFL_ST_ID: 3,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3309,
    INABV: "IGBAJO-POL",
    INName: "IGBAJO POLYTECHNIC, IGBAJO, OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3310,
    INABV: "IFE-POLY",
    INName: "THE POLYTECHNIC, ILE-IFE, OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3311,
    INABV: "LAG-CITY",
    INName: "LAGOS CITY POLYTECHNIC, IKEJA, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3312,
    INABV: "LIGHTHOUSE",
    INName: "LIGHT HOUSE POLYTECHNIC, EUBUOBANOSA, EDO STATE",
    InSt: 12,
    AFFL_ST_ID: 12,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3313,
    INABV: "INTERLINK",
    INName: "KANMI ALO INTERLINK POLYTECHNIC, IJEBU JESHA, OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3314,
    INABV: "KINGS-POLY",
    INName: "KINGS POLYTECHNIC, UBIAJA, EDO STATE",
    InSt: 12,
    AFFL_ST_ID: 12,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3315,
    INABV: "NACABSPOLY",
    INName: "NACABS POLYTECHNIC, AKWANGA, NASARAWA STATE",
    InSt: 25,
    AFFL_ST_ID: 25,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3316,
    INABV: "NOGAK",
    INName: "NOGAK POLYTECHNIC, MILE 2 ROAD, IKOM, CROSS RIVER STATE",
    InSt: 9,
    AFFL_ST_ID: 9,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3317,
    INABV: "IMESI-POLY",
    INName: "VILLANOVA POLYTECHNIC, IMESI-ILE, OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3318,
    INABV: "OSISATECH",
    INName:
      "OUR SAVIOUR INSTITUTE OF SCIENCE AND TECHNOLOGY (POLYTECHNIC) ENUGU, ENUGU STATE",
    InSt: 14,
    AFFL_ST_ID: 14,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3319,
    INABV: "RONIK",
    INName: "RONIK POLYTECHNIC, IKEJA, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3320,
    INABV: "TEMPLEGATE",
    INName: "TEMPLE-GATE POLYTECHNIC, OSISIOMA, ABIA STATE",
    InSt: 1,
    AFFL_ST_ID: 1,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3321,
    INABV: "TOWER-POLY",
    INName: "TOWER POLYTECHNIC, 136, LIBERTY ROAD, IBADAN, OYO STATE",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3322,
    INABV: "SHAKA",
    INName: "SHAKA POLYTECHNIC, PRINCE SHAKA WAY, BENIN CITY, EDO STATE",
    InSt: 12,
    AFFL_ST_ID: 12,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3323,
    INABV: "WOLEX",
    INName: "WOLEX POLYTECHNIC, IKEJA, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3327,
    INABV: "OKE-OGUN",
    INName: "THE OKE-OGUN POLYTECHNIC, SAKI, OYO STATE",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3328,
    INABV: "KALAC_POLY",
    INName:
      "KALAC CHRISTAL POLYTECHNIC, 1A SIKIRU ADEWALE STR, SANGOTEDO, LEKKI, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3331,
    INABV: "UYOCITYPOL",
    INName: "UYO CITY POLYTECHNIC, UYO, AKWA-IBOM STATE",
    InSt: 3,
    AFFL_ST_ID: 3,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3332,
    INABV: "IGBOOWU",
    INName:
      "THE POLYTECHNIC IGBO-OWU, OFF ASA DAM, OSERE ROAD JUNCTION, ILORIN, KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 23,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3333,
    INABV: "BOLMOR-POLY",
    INName:
      "BOLMOR POLYTECHNIC, 33 OLD IFE ROAD, PMB 32, AGODI IBADAN, OYO STATE",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3334,
    INABV: "EASTERN-POLY",
    INName: "EASTERN POLYTECHNIC, PORT HARCOURT, RIVERS STATE",
    InSt: 32,
    AFFL_ST_ID: 32,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3335,
    INABV: "IBADAN-CITYPOLY",
    INName: "IBADAN CITY POLYTECHNIC, IBADAN, OYO STATE",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3336,
    INABV: "ST.MARYPOL",
    INName: "ST. MARY POLYTECHNIC, KWAMBA, SULEJA, NIGER STATE",
    InSt: 26,
    AFFL_ST_ID: 26,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3337,
    INABV: "VALLEYVIEW",
    INName: "VALLEY VIEW POLYTECHNIC, OHAFIA, ABIA STATE",
    InSt: 1,
    AFFL_ST_ID: 1,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3338,
    INABV: "PRIME-POLY",
    INName: "PRIME POLYTECHNICS, JIDA BASSA, AJAOKUTA, KOGI STATE",
    InSt: 22,
    AFFL_ST_ID: 22,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3339,
    INABV: "GLOBALPOLY",
    INName: "GLOBAL POLYTECHNIC, OYEMEKUN ROAD, AKURE, ONDO STATE",
    InSt: 28,
    AFFL_ST_ID: 28,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3340,
    INABV: "SURE-POLY",
    INName: "SURE FOUNDATION POLYTECHNIC, IKOT AKAI, AKWA IBOM STATE",
    InSt: 3,
    AFFL_ST_ID: 3,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3341,
    INABV: "CALVARYPOLY",
    INName: "CALVARY POLYTECHNIC, OWO-OYIBO, DELTA STATE",
    InSt: 10,
    AFFL_ST_ID: 10,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3342,
    INABV: "MARIST",
    INName: "MARIST POLYTECHNIC, UMUCHIGBO-DE NIKE EMENE-ENUGU, ENUGU STATE",
    InSt: 14,
    AFFL_ST_ID: 14,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3343,
    INABV: "TRINITYPOL",
    INName: "TRINITY POLYTECHNIC, UYO, AKWA IBOM STATE",
    InSt: 3,
    AFFL_ST_ID: 3,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3344,
    INABV: "LENS-POLY",
    INName: "LENS POLYTECHNIC, OFFA, KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 23,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3345,
    INABV: "AL-HIKMA-POLY",
    INName: "AL-HIKMA POLYTECHNIC, MARARABA, NASARAWA STATE",
    InSt: 25,
    AFFL_ST_ID: 25,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3346,
    INABV: "BESTPOTECH",
    INName: "BEST SOLUTION POLYTECHNIC, AKURE, ONDO STATE.",
    InSt: 28,
    AFFL_ST_ID: 28,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3347,
    INABV: "AJAYI-POLY",
    INName:
      "AJAYI POLYTECHNIC, ADO/IKERE EXPRESS WAY, IKERE-EKITI, EKITI STATE",
    InSt: 13,
    AFFL_ST_ID: 13,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3348,
    INABV: "UMA-UKPAI",
    INName: "UMA UKPAI POLYTECHNIC, OHAFIA, ASAGA OHAFIA, ABIA STATE",
    InSt: 1,
    AFFL_ST_ID: 1,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3349,
    INABV: "KONDUGA",
    INName: "COLLEGE OF BUSINESS AND MANAGEMENT STUDIES, KONDUGA, BORNO STATE",
    InSt: 8,
    AFFL_ST_ID: 8,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3351,
    INABV: "LNDMK-POLY",
    INName: "LANDMARK POLYTECHNIC, OYETORO, OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 27,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3352,
    INABV: "CITI-POLY",
    INName: "CITI POLYTECHNIC, ABUJA",
    InSt: 37,
    AFFL_ST_ID: 37,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3353,
    INABV: "SAF-POLY",
    INName: "SAF POLYTECHNIC, ISEYIN, OYO STATE",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3354,
    INABV: "ASHI-POLY",
    INName: "ASHI POLYTECHNIC, ANYIIN LOGO, BENUE STATE",
    InSt: 7,
    AFFL_ST_ID: 7,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3355,
    INABV: "MATER-DEI",
    INName: "MATER DEI POLYTECHNIC, UGWUOBA TOWN, OJI RIVER, ENUGU STATE",
    InSt: 14,
    AFFL_ST_ID: 14,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3356,
    INABV: "RECTEM",
    INName: "REDEEMER'S COLLEGE OF TECHNOLOGY AND MANAGEMENT, OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 27,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3357,
    INABV: "GRACELAND",
    INName: "GRACELAND POLYTECHNIC, OFFA, KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 23,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3358,
    INABV: "SPEEDWAY",
    INName: "SPEEDWAY POLYTECHNIC, OSOBA, OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 27,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3359,
    INABV: "TIMKAI",
    INName: "TIMEON KAIROS POLYTECHNIC, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3360,
    INABV: "GRUNDTVIG",
    INName: "GRUNDTVIG POLYTECHNIC, OBA, ANAMBRA STATE",
    InSt: 4,
    AFFL_ST_ID: 4,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3361,
    INABV: "NOVELTY",
    INName: "NOVELTY POLYTECHNIC, KISHI, OYO STATE",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3362,
    INABV: "COASTAL",
    INName: "COASTAL POLYTECHNIC, APAPA, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3363,
    INABV: "HARRY-PASS",
    INName: "HARRY PASS POLYTECHNIC, GBOKO, BENUE STATE",
    InSt: 7,
    AFFL_ST_ID: 7,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3364,
    INABV: "SA-POLY",
    INName: "SOUTHERN ATLANTIC POLYTECHNIC, UYO, AKWA IBOM STATE",
    InSt: 3,
    AFFL_ST_ID: 3,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3365,
    INABV: "STARS-POLY",
    INName: "STARS POLYTECHNIC, AGBEDI-ATAN, OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 27,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3366,
    INABV: "IWO-POLY",
    INName: "IWO CITY POLYTECHNIC, IWO, OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3367,
    INABV: "BELLARKS",
    INName: "BELLARKS POLYTECHNIC, KWALE, DELTA STATE",
    InSt: 10,
    AFFL_ST_ID: 10,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3368,
    INABV: "ICON-POLY",
    INName: "I-CON UNIVERSAL POLYTECHNIC, OSOGBO, OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3369,
    INABV: "HARVARDE",
    INName:
      "HARVARDE COLLEGE OF MANAGEMENT AND  BUSINESS STUDIES, ABEOKUTA, OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 27,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3370,
    INABV: "IBOM-POLY",
    INName: "IBOM METROPOLITAN POLYTECHNIC, ATAN OFFOT, UYO, AKWA IBOM STATE",
    InSt: 3,
    AFFL_ST_ID: 3,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3371,
    INABV: "ODUWA-POLY",
    INName: "ODUDUWA POLYTECHNIC, IDUMU, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3372,
    INABV: "CC-TECH",
    INName: "COMMONWEALTH COLLEGE OF  TECHNOLOGY, GBOKO, BENUE STATE",
    InSt: 7,
    AFFL_ST_ID: 7,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3373,
    INABV: "FOUNDATION",
    INName: "FOUNDATION POLYTECHNIC, IKOT IDEM, IKOT EKPENE, AKWA-IBOM STATE",
    InSt: 3,
    AFFL_ST_ID: 3,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3374,
    INABV: "TEEDEK",
    INName: "TEEDEK POLYTECHNIC, ILOGBO-EKITI, EKITI STATE",
    InSt: 13,
    AFFL_ST_ID: 13,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3375,
    INABV: "INSPOTECH",
    INName: "INSPIRE POLYTECHNICS, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3376,
    INABV: "UEC-TECH",
    INName: "ULTRA-EXCELLENCE COLLEGE OF TECHNOLOGY, UYO, AKWA IBOM STATE",
    InSt: 3,
    AFFL_ST_ID: 3,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3377,
    INABV: "NEWLAND",
    INName: "NEWLAND POLYTECHNIC, IDOFIAN, KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 23,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3378,
    INABV: "BRAINFILL",
    INName: "BRAINFILL POLYTECHNIC, IKOT EKPENE, AKWA IBOM STATE",
    InSt: 3,
    AFFL_ST_ID: 3,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3379,
    INABV: "WESTLAND-POLY",
    INName: "WESTLAND POLYTECHNIC, ILOBU, OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3401,
    INABV: "COLAGRIC",
    INName:
      "SAMARU COLLEGE OF AGRICULTURE (DIVISION OF AGRIC COL, ABU) ZARIA, KADUNA STATE",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3402,
    INABV: "FCAPT-KANO",
    INName:
      "FEDERAL COLLEGE OF AGRICULTURAL PRODUCE TECH, HOTORO GRA EXT, KANO, KANO STATE",
    InSt: 19,
    AFFL_ST_ID: 19,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3403,
    INABV: "FECOT",
    INName: "FEDERAL COLLEGE OF ORTHOPEDIC TECHNOLOGY, IGBOBI, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3404,
    INABV: "FEDAGRIC-IBD",
    INName:
      "FEDERAL COLLEGE OF AGRICULTURE, MOOR PLANTATION, IBADAN, OYO STATE",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3405,
    INABV: "FEDAGRIC-ISH",
    INName: "FEDERAL COLLEGE OF AGRICULTURE, ISHIAGU, EBONYI STATE",
    InSt: 11,
    AFFL_ST_ID: 11,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3406,
    INABV: "FEDAGRIC-AKR",
    INName: "FEDERAL COLLEGE OF AGRICULTURE, AKURE, ONDO STATE",
    InSt: 28,
    AFFL_ST_ID: 28,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3407,
    INABV: "FEDANIM-VOM",
    INName:
      "FEDERAL COLLEGE OF ANIMAL HEALTH AND PRODUCTION TECHNOLOGY, VOM, PLATEAU STATE",
    InSt: 31,
    AFFL_ST_ID: 31,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3408,
    INABV: "FEDANIM-IBD",
    INName:
      "FEDERAL COLLEGE OF ANIMAL HEALTH AND PRODUCTION TECHNOLOGY, IBADAN, OYO STATE",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3409,
    INABV: "FEDCOOP-IBD",
    INName:
      "FEDERAL COOPERATIVE COLLEGE, P.M.B. 5033, ELEYELE, IBADAN, OYO STATE",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3410,
    INABV: "FEDCOOP-OJI",
    INName: "FEDERAL COOPERATIVE COLLEGE, OJI RIVER, ENUGU STATE",
    InSt: 14,
    AFFL_ST_ID: 14,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3411,
    INABV: "FEDCOOP-KD",
    INName:
      "FEDERAL COOPERATIVE COLLEGE, KADUNA ABUJA RD JUNCTION, KADUNA, KADUNA STATE",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3412,
    INABV: "FEDFISH-BAGA",
    INName:
      "FEDERAL COLLEGE OF FRESHWATER FISHERIES TECHNOLOGY, BAGA, BORNO STATE",
    InSt: 8,
    AFFL_ST_ID: 8,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3413,
    INABV: "FEDFISH-NBUS",
    INName:
      "FEDERAL COLLEGE OF FRESHWATER FISHERIES TECHNOLOGY, NEW BUSSA, NIGER STATE",
    InSt: 26,
    AFFL_ST_ID: 26,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3414,
    INABV: "FECOFM-AFAKA",
    INName:
      "FEDERAL COLLEGE OF FORESTRY MECHANISATION, AFAKA, KADUNA, KADUNA STATE",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3415,
    INABV: "FEDCOFOR-IBD",
    INName: "FEDERAL COLLEGE OF FORESTRY TECHNOLOGY, IBADAN, OYO STATE",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3416,
    INABV: "FECOFOR-JOS",
    INName: "FEDERAL COLLEGE OF FORESTRY, JOS, PLATEAU STATE",
    InSt: 31,
    AFFL_ST_ID: 31,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3417,
    INABV: "FEDFISH-LAG",
    INName:
      "FEDERAL COLLEGE OF FISHERIES AND MARINE TECHNOLOGY, VICTORIA ISLAND, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3418,
    INABV: "FCLT-OWER",
    INName: "FEDERAL COLLEGE OF LAND RESOURCES TECHNOLOGY, OWERRI, IMO STATE",
    InSt: 16,
    AFFL_ST_ID: 16,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3419,
    INABV: "FEDHOR-GOM",
    INName: "FEDERAL COLLEGE OF HORTICULTURE, DADIN KOWA, GOMBE STATE",
    InSt: 15,
    AFFL_ST_ID: 15,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3421,
    INABV: "FCLRT-KURU",
    INName:
      "FEDERAL COLLEGE OF LAND RESOURCES TECHNOLOGY, KURU, JOS, PLATEAU STATE",
    InSt: 31,
    AFFL_ST_ID: 31,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3422,
    INABV: "FSS-OYO",
    INName: "FEDERAL SCHOOL OF SURVEYING, OYO, OYO STATE",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3423,
    INABV: "FEDWILD-NBUS",
    INName: "FEDERAL COLLEGE OF WILDLIFE MANAGEMENT, NEW BUSSA, NIGER STATE",
    InSt: 26,
    AFFL_ST_ID: 26,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3424,
    INABV: "MAN",
    INName: "MARITIME ACADEMY OF NIGERIA, ORON, AKWA IBOM STATE",
    InSt: 3,
    AFFL_ST_ID: 3,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3425,
    INABV: "MINILS",
    INName:
      "MICHAEL IMODU NATIONAL INSTITUTE FOR LABOUR STUDIES, ILORIN, KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 23,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3426,
    INABV: "NCAT-ZAR",
    INName: "NIGERIAN COLLEGE OF AVIATION TECHNOLOGY, ZARIA, KADUNA STATE",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3427,
    INABV: "NILEST",
    INName:
      "NIGERIAN INSTITUTE OF LEATHER TECHNOLOGY AND SCIENCE TECH. KADUNA, KADUNA STATE",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3428,
    INABV: "FSS-MANCHOK",
    INName: "FEDERAL SCHOOL OF STATISTICS, MANCHOK, KADUNA, KADUNA STATE",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3431,
    INABV: "PTI",
    INName: "PETROLEUM TRAINING INSTITUTE, EFFURUN, DELTA STATE",
    InSt: 10,
    AFFL_ST_ID: 10,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3432,
    INABV: "FSS-IBADAN",
    INName: "FEDERAL SCHOOL OF STATISTICS, IBADAN, OYO STATE",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3433,
    INABV: "NWRI-MANDO",
    INName: "NATIONAL WATER RESOURCES INSTITUTE, MANDO, KADUNA, KADUNA STATE",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3434,
    INABV: "NASFA",
    INName:
      "NIGERIAN ARMY SCHOOL OF FINANCE AND ADMINISTRATION, APAPA, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3435,
    INABV: "KONGO",
    INName: "INSTITUTE OF ADMINISTRATION, KONGO, ZARIA, KADUNA STATE",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3436,
    INABV: "NNFLC",
    INName:
      "NIGERIAN NAVY SCHOOL OF FINANCE AND LOGISTICS COLLEGE, OWERRINTA, ABIA STATE",
    InSt: 1,
    AFFL_ST_ID: 1,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3437,
    INABV: "FTC-KAD",
    INName: "FEDERAL TRAINING CENTRE, KADUNA, KADUNA STATE",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3438,
    INABV: "FTC-LAG",
    INName: "FEDERAL TRAINING CENTRE, IKOYI, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3439,
    INABV: "NACNM",
    INName:
      "NIGERIAN ARMY COLLEGE OF NURSING &amp; MIDWIFERY, YABA, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3440,
    INABV: "MISTECH",
    INName:
      "METEOROLOGICAL INSTITUTE OF SCIENCE AND TECHNOLOGY, KATSINA, KATSINA STATE",
    InSt: 20,
    AFFL_ST_ID: 20,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3441,
    INABV: "SN-OAUTHC",
    INName:
      "SCHOOL OF NURSING, OBAFEMI AWOLOWO UNIV. TEACHING HOSPITAL, ILE-IFE, OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3442,
    INABV: "NAF-CN",
    INName: "NIGERIAN AIR FORCE COLLEGE OF NURSING, KADUNA, KADUNA STATE",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3443,
    INABV: "NACOMEDS",
    INName: "NIGERIAN ARMY COLLEGE OF MEDICAL SCIENCES, OJO, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3444,
    INABV: "SNWGH",
    INName:
      "SCHOOL OF NURSING, WESLEY GUILDS HOSPITAL,OAUTHC, ILESA OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3445,
    INABV: "FSSW-EMENE",
    INName: "FEDERAL SCHOOL OF SOCIAL WORK, EMENE, ENUGU STATE",
    InSt: 14,
    AFFL_ST_ID: 14,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3446,
    INABV: "IHT-UBTH",
    INName: "INSTITUTE OF HEALTH TECHNOLOGY, UTH, BENIN CITY, EDO STATE",
    InSt: 12,
    AFFL_ST_ID: 12,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3448,
    INABV: "NIMG",
    INName:
      "NIGERIAN INSTITUTE OF MINING &amp; GEOSCIENCES, JOS, PLATEAU STATE",
    InSt: 31,
    AFFL_ST_ID: 31,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3449,
    INABV: "FEDCO-MAID",
    INName:
      "FEDERAL COLLEGE OF FOREST RESOURCES MANAGEMENT, MAIDUGURI, BORNO STATE",
    InSt: 8,
    AFFL_ST_ID: 8,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3450,
    INABV: "FEDCO-ISH",
    INName:
      "FEDERAL COLLEGE OF FOREST RESOURCES MANAGEMENT, ISHIAGU, EBONYI STATE",
    InSt: 11,
    AFFL_ST_ID: 11,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3451,
    INABV: "FEDCO-ED",
    INName: "FEDERAL COLLEGE OF FOREST RESOURCES MANAGEMENT, FUGAR, EDO STATE",
    InSt: 12,
    AFFL_ST_ID: 12,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3452,
    INABV: "CVMLT",
    INName:
      "FEDERAL COLLEGE OF VETERINARY AND MEDICAL LABORATORY TECH., VOM, PLATEAU STATE",
    InSt: 31,
    AFFL_ST_ID: 31,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3454,
    INABV: "NIS",
    INName: "NATIONAL INSTITUTE FOR SPORTS, SURULERE, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3455,
    INABV: "SHIM-ATBU",
    INName:
      "SCHOOL OF HEALTH INFORMATION MGT, ATBU TEACHING HOSPITAL, BAUCHI, BAUCHI STATE",
    InSt: 5,
    AFFL_ST_ID: 5,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3456,
    INABV: "SN-UBTH",
    INName:
      "SCHOOL OF NURSING, UNIVERSITY OF BENIN TEACHING HOSPITAL, BENIN CITY EDO STATE",
    InSt: 12,
    AFFL_ST_ID: 12,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3457,
    INABV: "SHIMATBUTH",
    INName: "SCHOOL OF HEALTH MANAGEMENT, ATBU TEACHING HOSPITAL, BAUCHI STATE",
    InSt: 5,
    AFFL_ST_ID: 5,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3458,
    INABV: "NICO",
    INName:
      "NATIONAL INSTITUTE FOR CULTURAL ORIENTATION, LAGOS TRAINING SCHOOL LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3459,
    INABV: "SLDCT-AKTH",
    INName:
      "SCHOOL OF LAUNDRY AND DRY-CLEANING TECH. AMINU KANO TEACHING HOSPITAL,KANO STATE",
    InSt: 19,
    AFFL_ST_ID: 19,
    INTYP: 34,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 3502,
    INABV: "AUDU-BAKO",
    INName: "AUDU BAKO COLLEGE OF AGRICULTURE DANBATTA, KANO, KANO STATE",
    InSt: 19,
    AFFL_ST_ID: 19,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3503,
    INABV: "COLAGRIGAR",
    INName: "PLATEAU STATE COLLEGE OF AGRICULTURE, GARKAWA, PLATEAU STATE",
    InSt: 31,
    AFFL_ST_ID: 31,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3504,
    INABV: "BUPOLY-HAD",
    INName: "BINYAMINU USMAN POLYTECHNIC, HADEJIA, JIGAWA STATE",
    InSt: 17,
    AFFL_ST_ID: 17,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3506,
    INABV: "COLAGRIYAN",
    INName: "AKPERAN ORSHI COLLEGE OF AGRICULTURE, YANDEV, BENUE STATE",
    InSt: 7,
    AFFL_ST_ID: 7,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3507,
    INABV: "COLAGRIC-IGU",
    INName: "EDO STATE COLLEGE OF AGRICULTURE, IGUORIAKHI, EDO STATE",
    InSt: 12,
    AFFL_ST_ID: 12,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3508,
    INABV: "COLAGRIC-KAB",
    INName: "COLLEGE OF AGRICULTURE, KABBA, KOGI STATE",
    InSt: 22,
    AFFL_ST_ID: 22,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3509,
    INABV: "COLAGRIC-JAL",
    INName: "COLLEGE OF AGRICULTURE, JALINGO, TARABA STATE",
    InSt: 34,
    AFFL_ST_ID: 34,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3510,
    INABV: "COLAGRIC-KAD",
    INName:
      "COLLEGE OF AGRICULTURE AND ANIMAL SCIENCE, MANDO ROAD, KADUNA, KADUNA STATE",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3511,
    INABV: "COLAGRIMOK",
    INName: "NIGER STATE COLLEGE OF AGRICULTURE, MOKWA, NIGER STATE",
    InSt: 26,
    AFFL_ST_ID: 26,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3512,
    INABV: "COLAGRIC-LAF",
    INName: "COLLEGE OF AGRICULTURE, LAFIA, NASARAWA STATE",
    InSt: 25,
    AFFL_ST_ID: 25,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3515,
    INABV: "COLAGRIC-BAK",
    INName: "COLLEGE OF AGRICULTURE AND ANIMAL SCIENCE, BAKURA, ZAMFARA STATE",
    InSt: 36,
    AFFL_ST_ID: 36,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3516,
    INABV: "COLAGRIC-ZUR",
    INName: "COLLEGE OF AGRICULTURE, ZURU, KEBBI STATE",
    InSt: 21,
    AFFL_ST_ID: 21,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3517,
    INABV: "COTECH-CAL",
    INName: "COLLEGE OF HEALTH TECHNOLOGY, CALABAR, CROSS RIVER STATE",
    InSt: 9,
    AFFL_ST_ID: 9,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3518,
    INABV: "COTECH-ZAW",
    INName: "COLLEGE OF HEALTH TECHNOLOGY, ZAWAN, PLATEAU STATE",
    InSt: 31,
    AFFL_ST_ID: 31,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3519,
    INABV: "OGCOHTECH",
    INName: "OGUN STATE COLLEGE OF HEALTH TECHNOLOGY, ILESE-IJEBU, OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 27,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3520,
    INABV: "ODSOHTECH",
    INName: "ONDO STATE SCHOOL OF HEALTH TECHNOLOGY, AKURE, ONDO STATE",
    InSt: 28,
    AFFL_ST_ID: 28,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3521,
    INABV: "JSIITK",
    INName:
      "JIGAWA STATE INSTITUTE OF INFORMATION TECHNOLOGY, KAZAURE, JIGAWA STATE",
    InSt: 17,
    AFFL_ST_ID: 17,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3522,
    INABV: "MLCA-MAI",
    INName: "MOHAMMED LAWAN COLLEGE OF AGRICULTURE, MAIDUGURI, BORNO STATE",
    InSt: 8,
    AFFL_ST_ID: 8,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3523,
    INABV: "OYSCATECH",
    INName:
      "OYO STATE COLLEGE OF AGRICULTURE AND TECHNOLOGY, IGBOORA, OYO STATE",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3526,
    INABV: "ZACAS",
    INName: "ZAMFARA STATE COLLEGE OF ARTS AND SCIENCE, GUSAU, ZAMFARA STATE",
    InSt: 36,
    AFFL_ST_ID: 36,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3527,
    INABV: "NKST-MKAR",
    INName: "NKST COLLEGE OF HEALTH TECHNOLOGY, MKAR, BENUE STATE",
    InSt: 7,
    AFFL_ST_ID: 7,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3528,
    INABV: "COLAGRIGUJ",
    INName: "YOBE STATE COLLEGE OF AGRICULTURE, GUJBA, YOBE STATE",
    InSt: 35,
    AFFL_ST_ID: 35,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3529,
    INABV: "BAU-COAGRI",
    INName: "BAUCHI STATE COLLEGE OF AGRICULTURE, BAUCHI, BAUCHI STATE",
    InSt: 5,
    AFFL_ST_ID: 5,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3532,
    INABV: "DELTACHEAL",
    INName: "DELTA STATE COLLEGE OF HEALTH TECHNOLOGY, UGHELLI, DELTA STATE",
    InSt: 10,
    AFFL_ST_ID: 10,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3533,
    INABV: "ANSCOHTECH",
    INName: "ANAMBRA STATE COLLEGE OF HEALTH TECHNOLOGY, OBOSI, ANAMBRA STATE",
    InSt: 4,
    AFFL_ST_ID: 4,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3534,
    INABV: "KWCOHTECH",
    INName: "KWARA STATE COLLEGE OF HEALTH TECHNOLOGY, OFFA, KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 23,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3535,
    INABV: "CONHS",
    INName:
      "IMO STATE COLLEGE OF NURSING AND HEALTH SCIENCE, AMAIGBO, IMO STATE",
    InSt: 16,
    AFFL_ST_ID: 16,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3536,
    INABV: "GSCHST-KALTUNGO",
    INName:
      "GOMBE STATE COLLEGE OF HEALTH SCIENCE AND TECHNOLOGY, KALTUNGO, GOMBE STATE",
    InSt: 15,
    AFFL_ST_ID: 15,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3537,
    INABV: "COTECH-LG",
    INName: "LAGOS STATE COLLEGE OF HEALTH TECHNOLOGY, YABA, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3538,
    INABV: "CHST-IJERO",
    INName:
      "COLLEGE OF HEALTH SCIENCE AND TECHNOLOGY, IJERO-EKITI, EKITI STATE",
    InSt: 13,
    AFFL_ST_ID: 13,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3539,
    INABV: "OSCOHST",
    INName:
      "OYO STATE COLLEGE OF HEALTH SCIENCE AND TECHNOLOGY, ELEYELE, IBADAN",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3540,
    INABV: "DSS-MARINE",
    INName: "DELTA STATE SCHOOL OF MARINE TECHNOLOGY, BURUTU, DELTA STATE",
    InSt: 10,
    AFFL_ST_ID: 10,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3542,
    INABV: "CHT-ILESA",
    INName: "OSUN STATE COLLEGE OF HEALTH TECHNOLOGY, ILESA, OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3543,
    INABV: "CHT-NINGI",
    INName: "BAUCHI STATE COLLEGE OF HEALTH TECHNOLOGY, NINGI, BAUCHI STATE",
    InSt: 5,
    AFFL_ST_ID: 5,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3544,
    INABV: "KANCOHTECH",
    INName:
      "KANO STATE COLLEGE OF HEALTH SCIENCES AND TECHNOLOGY, KANO, KANO STATE",
    InSt: 19,
    AFFL_ST_ID: 19,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3545,
    INABV: "BYSCOHTECH",
    INName:
      "BAYELSA STATE COLLEGE OF HEALTH TECHNOLOGY, OTUOGIDI, OGBIA TOWN, BAYELSA STATE",
    InSt: 6,
    AFFL_ST_ID: 6,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3546,
    INABV: "GSCNM",
    INName: "GOMBE STATE COLLEGE OF NURSING AND MIDWIFERY, GOMBE STATE",
    InSt: 15,
    AFFL_ST_ID: 15,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3547,
    INABV: "CAILS",
    INName: "COLLEGE OF ARABIC AND ISLAMIC LEGAL STUDIES, ILORIN, KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 23,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3548,
    INABV: "CHST-IDAH",
    INName: "COLLEGE OF HEALTH SCIENCE AND TECHNOLOGY, IDAH, KOGI STATE",
    InSt: 22,
    AFFL_ST_ID: 22,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3549,
    INABV: "KSCNM",
    INName: "KATSINA STATE COLLEGE OF NURSING AND MIDWIFERY, KATSINA STATE",
    InSt: 20,
    AFFL_ST_ID: 20,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3550,
    INABV: "EKITI-POLY",
    INName: "EKITI STATE POLYTECHNIC, ISAN-EKITI, EKITI STATE",
    InSt: 13,
    AFFL_ST_ID: 13,
    INTYP: 32,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 3551,
    INABV: "LASCON",
    INName: "LAGOS STATE COLLEGE OF NURSING AND MIDWIFERY, IGANDO, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3552,
    INABV: "COHESKAT",
    INName:
      "KATSINA STATE COLLEGE OF HEALTH SCIENCE TECHNOLOGY, KATSINA, KATSINA STATE",
    InSt: 20,
    AFFL_ST_ID: 20,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3553,
    INABV: "IITH",
    INName:
      "INTERNATIONAL INSTITUTE OF TOURISM AND HOSPITALITY, YENAGOA, BAYELSA STATE",
    InSt: 6,
    AFFL_ST_ID: 6,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3554,
    INABV: "LASCOCO",
    INName: "LAGOS SATE CO-OPERATIVE COLLEGE, AGEGE, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3555,
    INABV: "SSCN",
    INName:
      "SHEHU SULE COLLEGE OF NURSING &amp; MIDWIFERY, DAMATURU, YOBE STATE",
    InSt: 35,
    AFFL_ST_ID: 35,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3556,
    INABV: "KSCNS",
    INName:
      "KEBBI STATE COLLEGE OF NURSING SCIENCES, BIRNIN-KEBBI, KEBBI STATE",
    InSt: 21,
    AFFL_ST_ID: 21,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3557,
    INABV: "CLIM",
    INName:
      "CENTRE FOR ADVANCED LIBRARY AND INFORMATION MANAGEMENT, ENUGU, ENUGU STATE",
    InSt: 14,
    AFFL_ST_ID: 14,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3558,
    INABV: "OCOHSTECH",
    INName: "ONDO COLLEGE OF HEALTH SCIENCES AND TECHNOLOGY, ONDO, ONDO STATE",
    InSt: 28,
    AFFL_ST_ID: 28,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3559,
    INABV: "BACAF",
    INName: "BALA ABDULLAHI COLLEGE OF ADMINISTRATION, FUNTUA, KATSINA STATE",
    InSt: 20,
    AFFL_ST_ID: 20,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3560,
    INABV: "OSCNM",
    INName:
      "OYO STATE COLLEGE OF NURSING AND MIDWIFERY, ELEYELE-IBADAN, OYO STATE",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3561,
    INABV: "COH-EBONYI",
    INName: "COLLEGE OF HEALTH TECHNOLOGY, EZZANGBO, EBONYI STATE",
    InSt: 11,
    AFFL_ST_ID: 11,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3562,
    INABV: "ZAMCOHTECH",
    INName:
      "ZAMFARA STATE COLLEGE OF HEALTH SCIENCE AND TECHNOLOGY, TSAFE, ZAMFARA STATE",
    InSt: 36,
    AFFL_ST_ID: 36,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3563,
    INABV: "SN-LUTH",
    INName:
      "SCHOOL OF NURSING, LAGOS UNIVERSITY TEACHING HOSPITAL, LAGOS, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3564,
    INABV: "CNS-SOKOTO",
    INName: "COLLEGE OF NURSING SCIENCES, SOKOTO, SOKOTO STATE",
    InSt: 33,
    AFFL_ST_ID: 33,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3565,
    INABV: "ESUTH",
    INName: "EKITI STATE UNIVERSITY TEACHING HOSPITAL, ADO-EKITI, EKITI STATE",
    InSt: 13,
    AFFL_ST_ID: 13,
    INTYP: 35,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3601,
    INABV: "NIJ",
    INName: "NIGERIAN INSTITUTE OF JOURNALISM, IKEJA, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 36,
    Inst_Cat: "PM",
    category: "Polytechnic",
  },
  {
    INID: 3602,
    INABV: "WAVECREST",
    INName:
      "WAVECREST COLLEGE OF CATERING &amp; HOSPITALITY MANAGEMENT, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 36,
    Inst_Cat: "PM",
    category: "Polytechnic",
  },
  {
    INID: 3604,
    INABV: "POGIL",
    INName:
      "POGIL COLLEGE OF HEALTH TECHNOLOGY, OKE-ERI, IJEBU-ODE, OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 27,
    INTYP: 36,
    Inst_Cat: "PM",
    category: "Polytechnic",
  },
  {
    INID: 3605,
    INABV: "MLN-COL",
    INName: "MILLENNIUM COLLEGE OF HEALTH TECHNOLOGY, AKURE, ONDO STATE",
    InSt: 28,
    AFFL_ST_ID: 28,
    INTYP: 36,
    Inst_Cat: "PM",
    category: "Polytechnic",
  },
  {
    INID: 3606,
    INABV: "AMINU-DABO",
    INName:
      "AMINU DABO COLLEGE OF HEALTH SCIENCE AND TECHNOLOGY, KANO, KANO STATE",
    InSt: 19,
    AFFL_ST_ID: 19,
    INTYP: 36,
    Inst_Cat: "PM",
    category: "Polytechnic",
  },
  {
    INID: 3607,
    INABV: "EMIRATES",
    INName: "EMIRATES COLLEGE OF HEALTH SCIENCES AND TECHNOLOGY, KANO STATE",
    InSt: 19,
    AFFL_ST_ID: 19,
    INTYP: 36,
    Inst_Cat: "PM",
    category: "Polytechnic",
  },
  {
    INID: 3608,
    INABV: "SAVANNAH",
    INName: "SAVANNAH INSTITUTE OF TECHNOLOGY, ABAKALIKI, EBONYI STATE",
    InSt: 11,
    AFFL_ST_ID: 11,
    INTYP: 36,
    Inst_Cat: "PM",
    category: "Polytechnic",
  },
  {
    INID: 3609,
    INABV: "BACOHTECH",
    INName:
      "BARTHOLOMEW COLLEGE OF HEALTH TECHNOLOGY, SHAO, ILORIN, KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 23,
    INTYP: 36,
    Inst_Cat: "PM",
    category: "Polytechnic",
  },
  {
    INID: 3610,
    INABV: "APS",
    INName: "ABUJA SCHOOL OF PENSION AND RETIREMENT PLANNING, ABUJA",
    InSt: 37,
    AFFL_ST_ID: 37,
    INTYP: 36,
    Inst_Cat: "PM",
    category: "Polytechnic",
  },
  {
    INID: 3611,
    INABV: "ADECOM",
    INName:
      "ADECOM COLLEGE OF BUSINESS AND MANAGEMENT STUDIES, IBADAN, OYO STATE",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 36,
    Inst_Cat: "PM",
    category: "Polytechnic",
  },
  {
    INID: 3612,
    INABV: "ENVILLETECH",
    INName: "ENVILLE INSTITUTE OF MANAGEMENT &amp; TECH, EPE, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 36,
    Inst_Cat: "PM",
    category: "Polytechnic",
  },
  {
    INID: 3613,
    INABV: "MTI",
    INName: "METALLURGICAL TRAINING INSTITUTE, ONITSHA, ANAMBRA STATE",
    InSt: 4,
    AFFL_ST_ID: 4,
    INTYP: 36,
    Inst_Cat: "PM",
    category: "Polytechnic",
  },
  {
    INID: 3614,
    INABV: "LAPO",
    INName:
      "LAPO INSTITUTE FOR MICROFINANCE AND MANAGEMENT STUDIES, BENIN, EDO STATE",
    InSt: 12,
    AFFL_ST_ID: 12,
    INTYP: 36,
    Inst_Cat: "PM",
    category: "Polytechnic",
  },
  {
    INID: 3615,
    INABV: "MALIKIYA",
    INName: "MALIKIYA COLLEGE OF HEALTH AND SOCIAL SCIENCES, BAUCHI STATE",
    InSt: 5,
    AFFL_ST_ID: 5,
    INTYP: 36,
    Inst_Cat: "PM",
    category: "Polytechnic",
  },
  {
    INID: 3616,
    INABV: "NIHST",
    INName:
      "NAZARETH INSTITUTE OF HEALTH SCIENCE AND TECHNOLOGY, MAKURDI, BENUE STATE",
    InSt: 7,
    AFFL_ST_ID: 7,
    INTYP: 36,
    Inst_Cat: "PM",
    category: "Polytechnic",
  },
  {
    INID: 3617,
    INABV: "CHARKIN",
    INName: "CHARKIN MARITIME ACADEMY, PORT HARCOURT, RIVERS STATE",
    InSt: 32,
    AFFL_ST_ID: 32,
    INTYP: 36,
    Inst_Cat: "PM",
    category: "Polytechnic",
  },
  {
    INID: 3618,
    INABV: "IMIT",
    INName: "IHECHUKWU MADUBUIKE INSTITUTE OF TECHNOLOGY, ISUOCHI, ABIA STATE",
    InSt: 1,
    AFFL_ST_ID: 1,
    INTYP: 36,
    Inst_Cat: "PM",
    category: "Polytechnic",
  },
  {
    INID: 3619,
    INABV: "OAK",
    INName: "OAK BUSINESS SCHOOL, RIVER VIEW ESTATE, OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 27,
    INTYP: 36,
    Inst_Cat: "PM",
    category: "Polytechnic",
  },
  {
    INID: 3620,
    INABV: "ED-JOHN",
    INName:
      "ED-JOHN INSTITUTE OF MANAGEMENT AND TECHNOLOGY, IKORODU, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 36,
    Inst_Cat: "PM",
    category: "Polytechnic",
  },
  {
    INID: 3621,
    INABV: "TCHST",
    INName:
      "TROPICAL COLLEGE OF HEALTH SCIENCES &amp; TECHNOLOGY, KANO, KANO STATE",
    InSt: 19,
    AFFL_ST_ID: 19,
    INTYP: 36,
    Inst_Cat: "PM",
    category: "Polytechnic",
  },
  {
    INID: 3622,
    INABV: "ANIMTO",
    INName:
      "ALL NATIONS INSTITUTE OF MARINE AND TECHNOLOGY, OGHARA, DELTA STATE",
    InSt: 10,
    AFFL_ST_ID: 10,
    INTYP: 36,
    Inst_Cat: "PM",
    category: "Polytechnic",
  },
  {
    INID: 3623,
    INABV: "ADCNS",
    INName: "ALIKO DANGOTE COLLEGE OF NURSING SCIENCE, BAUCHI, BAUCHI STATE",
    InSt: 5,
    AFFL_ST_ID: 5,
    INTYP: 36,
    Inst_Cat: "PM",
    category: "Polytechnic",
  },
  {
    INID: 3624,
    INABV: "CENTCOHTECH",
    INName:
      "CENTENARY COLLEGE OF HEALTH TECHNOLOGY, EMOHUA, PORT-HARCOURT, RIVERS STATE",
    InSt: 32,
    AFFL_ST_ID: 32,
    INTYP: 36,
    Inst_Cat: "PM",
    category: "Polytechnic",
  },
  {
    INID: 3625,
    INABV: "BABCOHTECH",
    INName:
      "BABALOLA ACADEMY COLLEGE OF HEALTH TECHNOLOGY, ILORIN, KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 23,
    INTYP: 36,
    Inst_Cat: "PM",
    category: "Polytechnic",
  },
  {
    INID: 3626,
    INABV: "MUSON",
    INName: "MUSON SCHOOL OF MUSIC, MARINA ONIKAN, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 36,
    Inst_Cat: "PM",
    category: "Polytechnic",
  },
  {
    INID: 3627,
    INABV: "PEMGO-COL",
    INName:
      "PEMGO COLLEGE OF BUSINESS AND MANAGEMENT STUDIES LTD, KARU, NASARAWA STATE",
    InSt: 25,
    AFFL_ST_ID: 25,
    INTYP: 36,
    Inst_Cat: "PM",
    category: "Polytechnic",
  },
  {
    INID: 3628,
    INABV: "FABOTAS",
    INName:
      "FABOTAS COLLEGE OF HEALTH SCIENCE AND TECHNOLOGY, ADO EKITI, EKITI STATE",
    InSt: 13,
    AFFL_ST_ID: 13,
    INTYP: 36,
    Inst_Cat: "PM",
    category: "Polytechnic",
  },
  {
    INID: 3629,
    INABV: "DAGAMA-COH",
    INName:
      "DAGAMA'S COLLEGE OF HEALTH SCIENCE AND TECH. SABON GARI, ZARIA, KADUNA STATE",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 36,
    Inst_Cat: "PM",
    category: "Polytechnic",
  },
  {
    INID: 3630,
    INABV: "EZEALA",
    INName: "EZEALA COLLEGE OF NURSING SCIENCES, UMUSEKE NJABA, IMO STATE",
    InSt: 16,
    AFFL_ST_ID: 16,
    INTYP: 36,
    Inst_Cat: "PM",
    category: "Polytechnic",
  },
  {
    INID: 3631,
    INABV: "BILL-COHTECH",
    INName:
      "BILL AND MELINDA GATES COLLEGE OF HEALTH SCIENCES AND TECH. NINGI BAUCHI STATE",
    InSt: 5,
    AFFL_ST_ID: 5,
    INTYP: 36,
    Inst_Cat: "PM",
    category: "Polytechnic",
  },
  {
    INID: 3701,
    INABV: "ABUTH",
    INName: "AHMADU BELLO UNIVERSITY TEACHING HOSPITAL, ZARIA, KADUNA STATE",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 37,
    Inst_Cat: "SH",
    category: "Polytechnic",
  },
  {
    INID: 3702,
    INABV: "AKTH",
    INName:
      "AMINU KANO TEACHING HOSPITAL, SCHOOL OF HEALTH INFO MANAGEMENT, KANO, KANO STATE",
    InSt: 19,
    AFFL_ST_ID: 19,
    INTYP: 37,
    Inst_Cat: "SH",
    category: "Polytechnic",
  },
  {
    INID: 3703,
    INABV: "SHIM_LUTH",
    INName: "SCHOOL OF HEALTH INFORMATION MGT, LUTH, IDIARABA, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 37,
    Inst_Cat: "SH",
    category: "Polytechnic",
  },
  {
    INID: 3704,
    INABV: "SHIM_OAU",
    INName:
      "SCHOOL OF HEALTH INFORMATION MGT, OAU TEACHING HOSPITAL, ILE-IFE, OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 37,
    Inst_Cat: "SH",
    category: "Polytechnic",
  },
  {
    INID: 3705,
    INABV: "SHEHUIDRIS",
    INName:
      "SHEHU IDRIS COLLEGE OF HEALTH SCIENCES AND TECHNOLOGY, MAKARFI, KADUNA STATE",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 37,
    Inst_Cat: "SH",
    category: "Polytechnic",
  },
  {
    INID: 3706,
    INABV: "SHIM-ILORI",
    INName:
      "SCHOOL OF HEALTH INFORMATION MGT, UNIV OF ILORIN TEACHING HOSPITAL, KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 23,
    INTYP: 37,
    Inst_Cat: "SH",
    category: "Polytechnic",
  },
  {
    INID: 3707,
    INABV: "SHIM_NAU",
    INName:
      "SCHOOL OF HEALTH INFORMATION MGT, NNAMDI AZIKIWE UNIV TEACHING HOSPITAL, NNEWI",
    InSt: 4,
    AFFL_ST_ID: 4,
    INTYP: 37,
    Inst_Cat: "SH",
    category: "Polytechnic",
  },
  {
    INID: 3708,
    INABV: "SHIM-CAL",
    INName:
      "UNICAL. TEACHING HOSPITAL SCHOOL OF HEALTH INFORMATION MGT. CROSS RIVER STATE",
    InSt: 9,
    AFFL_ST_ID: 9,
    INTYP: 37,
    Inst_Cat: "SH",
    category: "Polytechnic",
  },
  {
    INID: 3709,
    INABV: "SHIM-UBTH",
    INName:
      "SCHOOL OF  HEALTH  INFORMATION MGT (UNIBEN TEACHING HOSPITAL), BENIN, EDO STATE",
    InSt: 12,
    AFFL_ST_ID: 12,
    INTYP: 37,
    Inst_Cat: "SH",
    category: "Polytechnic",
  },
  {
    INID: 3710,
    INABV: "SHIM-UMTH",
    INName:
      "SCHOOL OF  HEALTH  INFORMATION MGT. (UNIMAID TEACHING HOSPITAL), BORNO STATE",
    InSt: 8,
    AFFL_ST_ID: 8,
    INTYP: 37,
    Inst_Cat: "SH",
    category: "Polytechnic",
  },
  {
    INID: 3711,
    INABV: "SHIM-UCH",
    INName: "SCHOOL OF  HEALTH  INFORMATION MGT (UCH, IBADAN), OYO STATE",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 37,
    Inst_Cat: "SH",
    category: "Polytechnic",
  },
  {
    INID: 3712,
    INABV: "SOCAST-KAN",
    INName:
      "SCHOOL OF ORTHOPAEDIC CAST TECH, NATIONAL ORTHOPAEDIC HOSPITAL DALA, KANO STATE",
    InSt: 19,
    AFFL_ST_ID: 19,
    INTYP: 37,
    Inst_Cat: "SH",
    category: "Polytechnic",
  },
  {
    INID: 3713,
    INABV: "SOCAST-ENU",
    INName: "SCHOOL OF ORTHOPAEDIC CAST TECHNOLOGY, ENUGU, ENUGU STATE",
    InSt: 14,
    AFFL_ST_ID: 14,
    INTYP: 37,
    Inst_Cat: "SH",
    category: "Polytechnic",
  },
  {
    INID: 3714,
    INABV: "SOCAST-ILO",
    INName:
      "SCHOOL OF ORTHOPAEDIC CAST TECH, UNIVERSITY OF ILORIN TEACHING HOSP, KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 23,
    INTYP: 37,
    Inst_Cat: "SH",
    category: "Polytechnic",
  },
  {
    INID: 3715,
    INABV: "BIOMED_MAI",
    INName: "SCHOOL OF BIOMEDICAL ENGINEERING, MAIDUGURI, BORNO STATE",
    InSt: 8,
    AFFL_ST_ID: 8,
    INTYP: 37,
    Inst_Cat: "SH",
    category: "Polytechnic",
  },
  {
    INID: 3716,
    INABV: "SOCAST-LAG",
    INName:
      "SCHOOL OF ORTHOPAEDIC CAST TECH, NATIONAL ORTHOPAEDIC HOSPI, IGBOBI, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 37,
    Inst_Cat: "SH",
    category: "Polytechnic",
  },
  {
    INID: 3717,
    INABV: "ADOKA-POLY",
    INName: "THE POLYTECHNIC, ADOKA, BENUE STATE",
    InSt: 7,
    AFFL_ST_ID: 7,
    INTYP: 37,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 3720,
    INABV: "BIOMED_BEN",
    INName:
      "SCHOOL OF BIOMEDICAL ENGINEERING, UNIVERSITY OF BENIN TEACHING HOSP, EDO STATE",
    InSt: 12,
    AFFL_ST_ID: 12,
    INTYP: 37,
    Inst_Cat: "SH",
    category: "Polytechnic",
  },
  {
    INID: 3721,
    INABV: "NEW GATE",
    INName: "NEW GATE COLLEGE OF HEALTH TECHNOLOGY, MINNA, NIGER STATE",
    InSt: 26,
    AFFL_ST_ID: 26,
    INTYP: 37,
    Inst_Cat: "SH",
    category: "Polytechnic",
  },
  {
    INID: 3722,
    INABV: "UNIPORT-TH",
    INName:
      "UNIVERSITY OF PORT HARCOURT TEACHING HOSPITAL, PORT HARCOURT , RIVER STATE",
    InSt: 32,
    AFFL_ST_ID: 32,
    INTYP: 37,
    Inst_Cat: "SH",
    category: "Polytechnic",
  },
  {
    INID: 3723,
    INABV: "SHIM-UDUTH",
    INName:
      "SCHOOL OF HEALTH INFORMATION MANAGEMENT (UDU TEACHING HOSPITAL), SOKOTO STATE",
    InSt: 33,
    AFFL_ST_ID: 33,
    INTYP: 37,
    Inst_Cat: "SH",
    category: "Polytechnic",
  },
  {
    INID: 3724,
    INABV: "SHIM-UUTH",
    INName:
      "SCHOOL OF HEALTH INFORMATION MGT, UNIUYO TEACHING HOSPITAL, UYO, AKWA IBOM STATE",
    InSt: 3,
    AFFL_ST_ID: 3,
    INTYP: 37,
    Inst_Cat: "SH",
    category: "Polytechnic",
  },
  {
    INID: 3725,
    INABV: "NNS-OFFA",
    INName: "NIGERIAN NAVY SCHOOL OF HEALTH SCIENCE, OFFA, KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 23,
    INTYP: 37,
    Inst_Cat: "SH",
    category: "Polytechnic",
  },
  {
    INID: 3726,
    INABV: "SBE-LUTH",
    INName:
      "FEDERAL SCHOOL OF BIOMEDICAL ENGINEERING, (LUTH), IDI-ARABA, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 37,
    Inst_Cat: "SH",
    category: "Polytechnic",
  },
  {
    INID: 3727,
    INABV: "CHT-KEFFI",
    INName:
      "NASARAWA STATE COLLEGE OF HEALTH SCIENCE AND TECHNOLOGY, KEFFI, NASARAWA STATE",
    InSt: 25,
    AFFL_ST_ID: 25,
    INTYP: 37,
    Inst_Cat: "SM",
    category: "Polytechnic",
  },
  {
    INID: 3728,
    INABV: "SCNM-ATBU",
    INName:
      "SCHOOL OF NURSING AND MIDWIFERY ABUBAKAR TAFAWA BALEWA UNIVERSITY, BAUCHI",
    InSt: 5,
    AFFL_ST_ID: 5,
    INTYP: 37,
    Inst_Cat: "SH",
    category: "Polytechnic",
  },
  {
    INID: 3729,
    INABV: "SBE-ABUTH",
    INName:
      "SCHOOL OF BIOMEDICAL ENGINEERING, AHMADU BELLO UNIVERSITY TEACHING HOSPITAL",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 37,
    Inst_Cat: "FM",
    category: "Polytechnic",
  },
  {
    INID: 4300,
    INABV: "SENTONA",
    INName:
      "SENTONA INSTITUTE OF MANAGEMENT AND TECHNOLOGY, NEW NYANYA, NASARAWA STATE",
    InSt: 25,
    AFFL_ST_ID: 25,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4301,
    INABV: "ACOMAT",
    INName:
      "AMBASSADOR COLLEGE OF MANAGEMENT AND TECH, PORT HARCOURT, RIVERS STATE",
    InSt: 32,
    AFFL_ST_ID: 32,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4302,
    INABV: "ACOTECH",
    INName:
      "AMBASSADOR COLLEGE OF TECHNOLOGY AND PARALEGAL STUDIES, FESTAC TOWN, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4303,
    INABV: "AFLON",
    INName: "AFLON DIGITAL INSTITUTE, ABUJA, FCT",
    InSt: 37,
    AFFL_ST_ID: 37,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4304,
    INABV: "AFRIHUB",
    INName: "AFRIHUB ICT INSTITUTE, ABUJA, FCT",
    InSt: 37,
    AFFL_ST_ID: 37,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4305,
    INABV: "AQUATECH",
    INName: "AQUATECH COLLEGE OF AGRICULTURE AND TECHNOLOGY, IBADAN, OYO STATE",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4306,
    INABV: "AREWA",
    INName: "AREWA PARALEGAL INNOVATION ENTERPRISE, KADUNA, KADUNA STATE",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4307,
    INABV: "BONNY",
    INName: "BONNY VOCATIONAL CENTRE, ISLAND, RIVERS STATE",
    InSt: 32,
    AFFL_ST_ID: 32,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4308,
    INABV: "BEEC",
    INName:
      "BUSINESS EDUCATION EXAMINATION COUNCIL, (BEEC), APAPA, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4309,
    INABV: "BISBAS",
    INName:
      "BENSON IDAHOSA SCHOOL OF BASIC AND APPLIED STUDIES, BENIN CITY, EDO STATE",
    InSt: 12,
    AFFL_ST_ID: 12,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4310,
    INABV: "BUCKINGHAM",
    INName:
      "BUCKINGHAM ACADEMY OF MANAGEMENT AND TECHNOLOGY, OGIDI, ANAMBRA STATE",
    InSt: 4,
    AFFL_ST_ID: 4,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4311,
    INABV: "CAFITECH",
    INName: "CALVARY FOUNDATION INSTITUTE OF TECHNOLOGY, ENUGU, ENUGU STATE",
    InSt: 14,
    AFFL_ST_ID: 14,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4312,
    INABV: "CCAE",
    INName: "CENTRE FOR CREATIVE ARTS EDUCATION, PORT HARCOURT, RIVERS STATE",
    InSt: 32,
    AFFL_ST_ID: 32,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4313,
    INABV: "CCTI",
    INName:
      "COMPLETE COMPUTERS AND TECH INST, 11 ADESUWA GRAMMAR SCH RD, BENIN, EDO STATE",
    InSt: 12,
    AFFL_ST_ID: 12,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4314,
    INABV: "CHERISH",
    INName: "CHERISH ENTERPRISE INSTITUTE, BATSARI, KATSINA STATE",
    InSt: 20,
    AFFL_ST_ID: 20,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4315,
    INABV: "CIFMAN",
    INName: "CIFMAN INST OF TECH AND MGT, 6 LANCASTER RD, YABA, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4316,
    INABV: "CIMT",
    INName:
      "COMPREHENSIVE INSTITUTE OF MANAGEMENT AND TECHNOLOGY, BYAZIN, KUBWA, ABUJA",
    InSt: 37,
    AFFL_ST_ID: 37,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4317,
    INABV: "CISN",
    INName:
      "THE CERTIFIED INSTITUTE OF SHIPPING OF NIGERIA, APAPA, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4318,
    INABV: "CITYGATE",
    INName:
      "CITY GATE INSTITUTE OF INNOVATION AND TECHNOLOGY, IBADAN, OYO STATE",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4319,
    INABV: "CLARA-MPA",
    INName:
      "CLARA SCHOOL OF MEDIA AND PERFORMING ARTS, SAW MILL DRIVE,YENAGOA, BAYELSA STATE",
    InSt: 6,
    AFFL_ST_ID: 6,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4320,
    INABV: "DALEWARES",
    INName: "DALEWARES INSTITUTE OF TECHNOLOGY, SURULERE, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4321,
    INABV: "DIABO",
    INName: "DIABO PARALEGAL TRAINING INSTITUTE, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4322,
    INABV: "DIALOGUE",
    INName: "DIALOGUE COMPUTER INSTITUTE, KATSINA, KATSINA STATE",
    InSt: 20,
    AFFL_ST_ID: 20,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4323,
    INABV: "DOTS",
    INName:
      "DOTS ICT INSTITUTE OF TECHNOLOGY, IJEMO AGBADU ROAD, ABEOKUTA, OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 27,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4324,
    INABV: "LENS",
    INName: "LENS SCHOOL OF MANAGEMENT AND TECHNOLOGY, OFFA, KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 23,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4325,
    INABV: "LEI",
    INName: "LADELA EDUCATIONAL INSTITUTTE, GARKI ABUJA, FCT",
    InSt: 37,
    AFFL_ST_ID: 37,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4326,
    INABV: "EMDEE",
    INName:
      "EMDEE SHIPPING AND MARITIME COLLEGE, SUNSHINE BUS STOP, APAPA, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4327,
    INABV: "ESTAC",
    INName:
      "ESTAC INSTITUTE OF CONSTRUCTION TECHNOLOGY, TRANS-EKULU, ENUGU STATE",
    InSt: 14,
    AFFL_ST_ID: 14,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4328,
    INABV: "FABA",
    INName: "THE FILM AND  BROADCAST ACADEMY WESTIN HALL, DELTA STATE",
    InSt: 10,
    AFFL_ST_ID: 10,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4330,
    INABV: "AFAKA-IEI",
    INName:
      "FEDERAL COLLEGE OF FORESTRY, MECHANISATION, AFAKA, KADUNA, KADUNA STATE",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4331,
    INABV: "FIRSTMARK",
    INName:
      "FIRSTMARK INNOVATION SCHOOL OF TECH, UREJE QTRS, OFF IKERE RD, ADO-EKITI",
    InSt: 13,
    AFFL_ST_ID: 13,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4332,
    INABV: "FLYINGDOVE",
    INName: "FLYINGDOVE INSTITUTE OF INFORMATION TECHNOLOGY, ABUJA, FCT",
    InSt: 37,
    AFFL_ST_ID: 37,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4333,
    INABV: "FCTI",
    INName:
      "FOUNDATION COLLEGE OF TECHNOLOGY, IKOT IDEM, IKOT EKPENE, AKWA IBOM STATE",
    InSt: 3,
    AFFL_ST_ID: 3,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4334,
    INABV: "GIPI",
    INName: "GATEWAY INDUSTRIAL AND PETRO-GAS INSTITUTE, ONI, OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 27,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4335,
    INABV: "GLOBAL",
    INName: "GLOBAL MARITIME ACADEMY, OGONI-OLOMU, UGHELLI SOUTH, DELTA STATE",
    InSt: 10,
    AFFL_ST_ID: 10,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4336,
    INABV: "HIGHLAND",
    INName:
      "HIGHLAND COLLEGE OF TECHNOLOGY AND FURTHER EDUCATION, IBADAN, OYO STATE",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4337,
    INABV: "IBOM-IMT",
    INName:
      "IBOM INSTITUTE OF MANAGEMENT AND TECHNOLOGY, IKOT EKPENE, AKWA IBOM STATE",
    InSt: 3,
    AFFL_ST_ID: 3,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4338,
    INABV: "ICCN",
    INName:
      "INT'L CHAPLAINCY CORPS NETWORK INST. OF SECURITY TECH AND MGT, ORLU, IMO STATE",
    InSt: 16,
    AFFL_ST_ID: 16,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4339,
    INABV: "IFOTECH",
    INName: "IFO COLLEGE OF MANAGEMENT AND TECHNOLOGY, IFO, OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 27,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4340,
    INABV: "IICT",
    INName:
      "INSTITUTE OF INFORMATION AND COMPUTER TECHNOLOGY, AWGU, ENUGU STATE",
    InSt: 14,
    AFFL_ST_ID: 14,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4341,
    INABV: "IIIT",
    INName: "INNOVATION INSTITUTE OF TECHNOLOGY, AKURE, ONDO STATE",
    InSt: 28,
    AFFL_ST_ID: 28,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4342,
    INABV: "IITK",
    INName: "INNOVATION INSTITUTE OF TECHNOLOGY, KADUNA, KADUNA STATE",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4343,
    INABV: "IMFI",
    INName: "IMFI, ICT ACADEMY, UYO, AKWA IBOM STATE",
    InSt: 3,
    AFFL_ST_ID: 3,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4344,
    INABV: "IZISCO",
    INName:
      "IZISCO OBOS INSTITUTE OF MARITIME STUDIES AND TECHNOLOGY, WARRI, DELTA STATE",
    InSt: 10,
    AFFL_ST_ID: 10,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4345,
    INABV: "KARROX",
    INName:
      "KARROX -UGRL COMPUTER EDUCATION AND TRAINING CENTRE, IKEJA, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4346,
    INABV: "KBS",
    INName: "KADUNA BUSINESS SCHOOL, KADUNA, KADUNA STATE",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4347,
    INABV: "KIEI",
    INName:
      "KAZAURE INNOVATION ENTERPRISE INST, HUSSAINI ADAMU FED. POLY, JIGAWA STATE",
    InSt: 17,
    AFFL_ST_ID: 17,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4348,
    INABV: "LAGOS-CITY",
    INName: "LAGOS CITY COMPUTER COLLEGE, LAGOS, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4349,
    INABV: "LAMB",
    INName: "LAGOS AVIATION AND MARITIME BUS ACADEMY, IYANA IPAJA, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4351,
    INABV: "LAPA",
    INName: "LUFODO ACADEMY OF PERFORMING ARTS, IKOYI, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4352,
    INABV: "LASER",
    INName: "LASER PETROLEUM GEO-SCIENCES CENTRE, PORT HARCOURT, RIVERS STATE",
    InSt: 32,
    AFFL_ST_ID: 32,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4353,
    INABV: "LITERACY",
    INName:
      "LITERACY COLLEGE OF MGT AND TECH(FORMERLY THE LITERACY CENTRE), ABA, ABIA STATE",
    InSt: 1,
    AFFL_ST_ID: 1,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4354,
    INABV: "LIVINGSPRI",
    INName:
      "LIVING SPRING COLLEGE OF TECHNOLOGY AND INNOVATION, OSHOGBO, OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4355,
    INABV: "LPII",
    INName: "LINET PAUL INNOVATIVE INSTITUTE, ETEGWE-YENAGOA, BAYELSA STATE",
    InSt: 6,
    AFFL_ST_ID: 6,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4356,
    INABV: "MALLAM",
    INName: "YAHAYA HAMZA INSTITUTE OF ADVANCE  STUDIES, KADUNA, KADUNA STATE",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4357,
    INABV: "MAURID",
    INName: "MAURID INSTITUTE OF MGT AND TECH, MBIASO, AKWA IBOM STATE",
    InSt: 3,
    AFFL_ST_ID: 3,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4358,
    INABV: "MARVEL",
    INName:
      "MARVEL INSTITUTE OF MANAGEMENT AND TECHNOLOGY, KUCHIKAU, NASARAWA STATE",
    InSt: 25,
    AFFL_ST_ID: 25,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4359,
    INABV: "MIT",
    INName: "METROPOLITAN INSTITUTE OF TECHNOLOGY, BENIN CITY, EDO STATE",
    InSt: 12,
    AFFL_ST_ID: 12,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4360,
    INABV: "NAOWA",
    INName:
      "NAOWA INSTITUTE OF MANAGEMENT AND TECHNOLOGY, MAMBILLA BARRACKS, ASOKORO, ABUJA",
    InSt: 37,
    AFFL_ST_ID: 37,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4361,
    INABV: "WOMEN-DEV",
    INName: "NATIONAL COUNCIL FOR WOMEN DEVELOPMENT, ABUJA, FCT",
    InSt: 37,
    AFFL_ST_ID: 37,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4362,
    INABV: "NFI",
    INName: "NATIONAL FILM INSTITUTE, JOS, PLATEAU STATE",
    InSt: 31,
    AFFL_ST_ID: 31,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4363,
    INABV: "NIEI",
    INName:
      "NIGER-DELTA INNOVATION ENTERPRISE INSTITUTE, YENAGOA, BAYELSA STATE",
    InSt: 6,
    AFFL_ST_ID: 6,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4364,
    INABV: "NIIT",
    INName: "NIIT IDM, 9/20, OBA AKRAN AVENUE, IKEJA, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4365,
    INABV: "NSPIRE",
    INName: "NSPIRE SCHOOL OF MANAGEMENT AND TECHNOLOGY LIMITED, ABUJA, FCT",
    InSt: 37,
    AFFL_ST_ID: 37,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4366,
    INABV: "OASIS",
    INName:
      "OLAWOYIN AWOSIKA SCHOOL OF INNOVATIVE STUDIES, ISHERI, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4367,
    INABV: "ONIT",
    INName: "ONIT INSTITUTE OF TECHNOLOGY, MBAUKWU, ANAMBRA STATE",
    InSt: 4,
    AFFL_ST_ID: 4,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4368,
    INABV: "OSENI",
    INName: "OSENI ELAMAH INSTITUTE, AUCHI, EDO STATE",
    InSt: 12,
    AFFL_ST_ID: 12,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4369,
    INABV: "PANAFRICAN",
    INName: "PAN-AFRICAN INSTITUTE OF PARALEGAL STUDIES, GWARIMPA, ABUJA, FCT",
    InSt: 37,
    AFFL_ST_ID: 37,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4370,
    INABV: "PEFTI",
    INName: "PEFTI FILM INSTITUTE, LAGOS, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4371,
    INABV: "PRIME",
    INName: "PRIME INNOVATION INSTITUTE OF TECHNOLOGY, AJAH, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4372,
    INABV: "RCA",
    INName: "RISK CONTROL ACADEMY, ROYAL CEDAR PLAZA, AJAH, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4373,
    INABV: "ROCANA",
    INName: "ROCANA INSTITUTE OF TECHNOLOGY, EMENE, ENUGU, ENUGU STATE",
    InSt: 14,
    AFFL_ST_ID: 14,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4374,
    INABV: "BANK_LAG",
    INName: "SCHOOL OF BANKING HONOURS, LAGOS, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4375,
    INABV: "SIMTECH",
    INName:
      "THE SCHOOL OF INFORMATION AND MEDIA TECHNOLOGY, ABAKALIKI, EBONYI STATE",
    InSt: 11,
    AFFL_ST_ID: 11,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4376,
    INABV: "SOUTH-EAST",
    INName:
      "SOUTHERN EASTERN COL. FOR COMPUTER ENGINEERING AND INFO. TECH. OWERRI, IMO STATE",
    InSt: 16,
    AFFL_ST_ID: 16,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4377,
    INABV: "SOUTHERNIG",
    INName:
      "SOUTHERN-NIGERIA INSTITUTE OF INNOVATIVE TECHNOLOGY, IFEWARA, OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4378,
    INABV: "ST. RITA",
    INName: "ST. RITA INNOVATION INSTITUTE, ONITSHA, ANAMBRA STATE",
    InSt: 4,
    AFFL_ST_ID: 4,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4379,
    INABV: "STARS",
    INName:
      "STARS MARITIME ACADEMY (MERCHANT NAVY INSTITUTE), AYOBO, OTTA RD, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4380,
    INABV: "STAUG",
    INName:
      "ST. AUGUSTINE INNOVATION ENTERPRISE INSTITUTE, ADEBARE STR, OGUDU, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4381,
    INABV: "STWINI",
    INName: "ST. WINIFRED INNOVATION INSTITUTE  IBADAN, OYO STATE",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4382,
    INABV: "OCMTECH",
    INName: "OWU COLLEGE OF MANAGEMENT TECHNOLOGY, ITELE-OTA, OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 27,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4383,
    INABV: "TOTAL",
    INName: "TOTAL HUMAN CAPITAL DEVELOPMENT INSTITUTE, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4384,
    INABV: "TOWER-IEI",
    INName: "TOWER INNOVATION COLLEGE, IBADAN, OYO STATE",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4385,
    INABV: "TRIZAS",
    INName: "TRIZAS INSTITUTE FOR INNOVATIVE EDUCATION, ENUGU STATE",
    InSt: 14,
    AFFL_ST_ID: 14,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4386,
    INABV: "UMUEBE",
    INName: "UMUEBE FARM COLLEGE, ABAKALIKI, EBONYI STATE",
    InSt: 11,
    AFFL_ST_ID: 11,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4387,
    INABV: "UNITECH",
    INName:
      "UNITED SCHOOL OF SCIENCE AND TECHNOLOGY GANAJIGO, JOS, PLATEAU STATE",
    InSt: 31,
    AFFL_ST_ID: 31,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4388,
    INABV: "UPTONVILLE",
    INName: "UPTONVILLE OIL AND GAS INSTITUTE, PORT HARCOURT, RIVERS STATE",
    InSt: 32,
    AFFL_ST_ID: 32,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4389,
    INABV: "UA-COLLEGE",
    INName: "UA COLLEGE OF SCIENCE AND TECHNOLOGY, OSUOFIA, ANAMBRA STATE",
    InSt: 4,
    AFFL_ST_ID: 4,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4390,
    INABV: "JA-ACADEMY",
    INName:
      "HON. JUSTICE L. O . AREMU'S ACADEMY FOR BASICS STUDIES, IBADAN,OYO STATE",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4391,
    INABV: "SEAMAN",
    INName:
      "MERCHANT SEAMAN ACADEMY, IKOT ADAUTO ESSIEN ACADEMY, AKWA IBOM STATE",
    InSt: 3,
    AFFL_ST_ID: 3,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4392,
    INABV: "ECWA-ICT",
    INName: "ECWA INTERNATIONAL COLLEGE OF TECHNOLOGY, JOS, PLATEAU STATE",
    InSt: 31,
    AFFL_ST_ID: 31,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4398,
    INABV: "NIIT-OFFA",
    INName: "NIIT STUDY CENTRE, OFFA, KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 23,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4399,
    INABV: "CIMT-IDOGBO",
    INName:
      "CITY INSTITUTE OF MGT. &amp; TECH; IDOGBO EDU. CENTRE, MARARABA, NASARAWA STATE",
    InSt: 25,
    AFFL_ST_ID: 25,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4400,
    INABV: "KSITM",
    INName:
      "KATSINA STATE INSTITUTE OF TECHNOLOGY AND MANAGEMENT, KATSINA STATE",
    InSt: 20,
    AFFL_ST_ID: 20,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4401,
    INABV: "CORNER-IMT",
    INName:
      "CORNERSTONE INSTITUTE OF MANAGEMENT AND TECHNOLGY, UYO, AKWA IBOM STATE",
    InSt: 3,
    AFFL_ST_ID: 3,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4403,
    INABV: "IBF",
    INName: "INSTITUTE OF BANKING AND FINANCE, IBADAN, OYO STATE",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4404,
    INABV: "BGBFS",
    INName:
      "BEST GLOBAL BUSINESS AND FINANCIAL SCHOOL, CICMA HOUSE, KACHIA RD, KADUNA STATE",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4405,
    INABV: "UNIVAL",
    INName: "UNIVAL INSTITUTE OF TECHNOLOGY, BENIN CITY, EDO STATE",
    InSt: 12,
    AFFL_ST_ID: 12,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4406,
    INABV: "COBFI",
    INName: "COLLEGE OF BANKING AND FINANCE, IBADAN, OYO STATE",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4407,
    INABV: "ZIBEH",
    INName: "ZIBEH  INSTITUTE OF  TECHNOLOGY, JOS, PLATEAU STATE",
    InSt: 31,
    AFFL_ST_ID: 31,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4408,
    INABV: "YENAGOA",
    INName: "YENAGOA ENTERPRISE SCHOOL, YENOGOA, BAYELSA STATE",
    InSt: 6,
    AFFL_ST_ID: 6,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4409,
    INABV: "OFFER",
    INName: "OFFER CENTRE INSTITUTE OF AGRICULTURE, OLUPONNA, IWO, OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4411,
    INABV: "CALVARY-TECH",
    INName: "CALVARY COLLEGE OF TECHNOLOGY, PAPALANTO, OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 27,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4412,
    INABV: "GLOBAL-ICT",
    INName:
      "GLOBAL INSTITUTE OF COMMERCE AND TECHNOLOGY, GWARIMPA ESTATE, ABUJA, FCT",
    InSt: 37,
    AFFL_ST_ID: 37,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4413,
    INABV: "ROYAL-ARTS",
    INName: "ROYAL ARTS ACADEMY, SURULERE, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4414,
    INABV: "UNICEM",
    INName:
      "UNICEM COMMUNITY DEV. INITIATIVE TRAIN. INST. MFAMOSING, CROSS RIVER STATE",
    InSt: 9,
    AFFL_ST_ID: 9,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4415,
    INABV: "DEL-YORK",
    INName: "DEL-YORK CREATIVE ACADEMY, IKOYI, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4416,
    INABV: "GRAIG-PHILLIPS",
    INName: "GRAIG-PHILLIPS COLLEGE OF TECHNOLOGY, MARYLAND, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4417,
    INABV: "SMARTIMAGE",
    INName: "SMART IMAGE FILM AND THEATRE INSTITUTE, AGBOWO-UI, OYO STATE",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4418,
    INABV: "YEFMADEC",
    INName:
      "YEFMADEC INSTITUTE OF SCIENCE AND TECHNOLOGY, MOSOGAR, DELTA STATE",
    InSt: 10,
    AFFL_ST_ID: 10,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4419,
    INABV: "FILAPS",
    INName: "FILAPS MEDIA TRAINING INSTITUTE, GYADI-GYADI, KANO, KANO STATE",
    InSt: 19,
    AFFL_ST_ID: 19,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4420,
    INABV: "GLORY-LAND",
    INName: "GLORY LAND INSTITUTE OF MANAGEMENT, ANKPA, KOGI STATE",
    InSt: 22,
    AFFL_ST_ID: 22,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4421,
    INABV: "DIALOGUE-KD",
    INName: "DIALOGUE COMPUTER INSTITUTE, KADUNA, KADUNA STATE",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4422,
    INABV: "DBI-KANO",
    INName: "DIGITAL BRIDGE INSTITUTE, KANO, KANO STATE",
    InSt: 19,
    AFFL_ST_ID: 19,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4423,
    INABV: "ENVILLE",
    INName:
      "ENVILLE INSTITUTE OF ENVIROMENTAL AND SAFETY MANAGEMENT, IKEJA, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4424,
    INABV: "DBI-LAGOS",
    INName: "DIGITAL BRIDGE INSTITUTE, OSHODI, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4425,
    INABV: "SAGAMUBAI",
    INName: "SAGAMU BUSINESS AND ART INSTITUTE, SAGAMU, OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 27,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4426,
    INABV: "ITFMSTC",
    INName:
      "INDUSTRIAL TRAINING FUND MODELS SKILLS TRAINING CENTRE, MAITAMA, ABUJA, FCT",
    InSt: 37,
    AFFL_ST_ID: 37,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4427,
    INABV: "MITI",
    INName:
      "MINNA INSTITUTE OF TECHNOLOGY AND INNOVATION, KPAKUNGU ROAD, NIGER STATE",
    InSt: 26,
    AFFL_ST_ID: 26,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4428,
    INABV: "MYPROJECT",
    INName:
      "MY PROJECT INSTITUTE OF SECURITY AND TECHNOLOGY MANAGEMENT, ZUBA, ABUJA, FCT",
    InSt: 37,
    AFFL_ST_ID: 37,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4429,
    INABV: "EHIME",
    INName: "MBARA OZIOMA COLLEGE OF TECHNOLOGY, EHIME-MBANO, IMO STATE",
    InSt: 16,
    AFFL_ST_ID: 16,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4430,
    INABV: "TIMEON",
    INName:
      "TIMEON KAIROS EDUCATIONAL &amp; VOCATIONAL INSTITUTE, AGEGE, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4431,
    INABV: "CIBTECH",
    INName: "CENTRAL INSTITUTE OF BUSINESS AND TECHNOLOGY, EGBA, EDO STATE",
    InSt: 12,
    AFFL_ST_ID: 12,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4432,
    INABV: "NTA-TVC",
    INName:
      "NIGERIAN TELEVISION AUTHORITY, TELEVISION COLLEGE, RAY FIELD, JOS, PLATEAU STATE",
    InSt: 31,
    AFFL_ST_ID: 31,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4433,
    INABV: "ITMT",
    INName: "INSTITUTE OF TRANSPORT  AND MANAGEMENT TECHNOLOGY, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4434,
    INABV: "GYALLESU",
    INName:
      "TASHIBA INSTITUTE OF INFORMATION TECHNOLOGY, GYALLESU, ZARIA, KADUNA STATE",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4435,
    INABV: "ICSIS",
    INName:
      "INSTITUTE OF CORPORATE SECURITY AND INTELLIGENCE STUDIES, KANO, KANO STATE",
    InSt: 19,
    AFFL_ST_ID: 19,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4436,
    INABV: "ROLOF",
    INName: "ROLOF COMPUTER ACADEMY, WARRI, DELTA STATE",
    InSt: 10,
    AFFL_ST_ID: 10,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4437,
    INABV: "MARIO",
    INName:
      "MARIO INSTITUE OF HOSPITALITY MGT AND ENTREPRENEURSHIP EDUC. NSUKKA, ENUGU STATE",
    InSt: 14,
    AFFL_ST_ID: 14,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4438,
    INABV: "MAGIC",
    INName: "MAGIC BRUSH FILM SCHOOL, PORT HARCOURT, RIVERS STATE",
    InSt: 32,
    AFFL_ST_ID: 32,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4439,
    INABV: "CONARINA",
    INName: "CONARINA MARITIME ACADEMY, ORIA, ABRAKA, DELTA STATE",
    InSt: 10,
    AFFL_ST_ID: 10,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4440,
    INABV: "IFE-BUS",
    INName: "IFE BUSINESS SCHOOL, IIE-IFE, OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4441,
    INABV: "ICCN-IFIM",
    INName:
      "ICCN-INSTITUTE OF FINANCE AND INVESTMENT MANAGEMENT, UMUKO, IMO STATE",
    InSt: 16,
    AFFL_ST_ID: 16,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4442,
    INABV: "DAMSON",
    INName: "DAMSON INSTITUTE OF MANAGEMENT AND TECHNOLOGY, FCT, ABUJA",
    InSt: 37,
    AFFL_ST_ID: 37,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4443,
    INABV: "NIG-KOREA",
    INName:
      "NIGERIA-KOREA FRIENDSHIP INST. OF VOCATIONAL &amp; ADVANCED TECH, LOKOJA, KOGI STATE",
    InSt: 22,
    AFFL_ST_ID: 22,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4444,
    INABV: "HOMIK",
    INName:
      "HOMIK OIL AND GAS FACILITY ENGINEERING SCHOOL, PORT HARCOURT, RIVERS STATE",
    InSt: 32,
    AFFL_ST_ID: 32,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4445,
    INABV: "SOTERIA",
    INName: "SOTERIA BUSINESS SCHOOL, OKE ADO, IBADAN, OYO STATE",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4446,
    INABV: "MATAN-FADA",
    INName:
      "ENTREPRENEURSHIP, VOCATIONAL TECHNOLOGY LIMITED, MATAN FADA, KANO STATE",
    InSt: 19,
    AFFL_ST_ID: 19,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4447,
    INABV: "EKO-CMT",
    INName: "EKO COLLEGE OF MANAGEMENT AND TECHNOLOGY, IKOTUN, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4448,
    INABV: "DIMT",
    INName:
      "DISTINCT INSTITUTE OF MANAGEMENT AND TECHNOLOGY, OSOGBO, OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4449,
    INABV: "FEHIC-TECH",
    INName:
      "FOUNDATION FOR ENERGY HEALTH INT. COL. OF SCIENCE AND TECH., AWGU, ENUGU STATE",
    InSt: 14,
    AFFL_ST_ID: 14,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4450,
    INABV: "PEACE-IMT",
    INName: "PEACE INSTITUTE OF MANAGEMENT TECHNOLOGY, ABAK, AKWA IBOM STATE",
    InSt: 3,
    AFFL_ST_ID: 3,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4451,
    INABV: "NIIT-FESTA",
    INName: "NIIT-IDM FESTAC CENTRE, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4452,
    INABV: "NIIT-SURUL",
    INName: "NIIT-IDM SURULERE CENTRE, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4453,
    INABV: "IRISH-BS",
    INName: "IRISH BUSINESS SCHOOL, EGBEDA IDUMU, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4454,
    INABV: "ICCT",
    INName: "ILESHA CITY COLLEGE OF TECHNOLOGY, ILASE-IJESHA, OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4455,
    INABV: "IPETU-ICT",
    INName: "IPETU-IJESHA COLLEGE OF TECHNOLOGY, OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4456,
    INABV: "GRACE",
    INName:
      "GRACE CITY INSTITUTE OF TECHNOLOGY &amp; MANAGEMENT SCIENCE LTD, OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4457,
    INABV: "NIIT-IKORO",
    INName: "NIIT-IDM IKORODU CENTRE, IKORODU, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4458,
    INABV: "NIIT-UYO",
    INName: "NIIT-UYO CENTRE, AKWA IBOM STATE",
    InSt: 3,
    AFFL_ST_ID: 3,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4459,
    INABV: "NIIT-WARRI",
    INName: "NIIT-WARRI CENTRE, EFFURUN, DELTA STATE",
    InSt: 10,
    AFFL_ST_ID: 10,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4460,
    INABV: "K&amp;Q-BEAUTY",
    INName: "KINGS AND QUEENS BEAUTY INSTITUTE, ABUJA, FCT",
    InSt: 37,
    AFFL_ST_ID: 37,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4461,
    INABV: "NIIT-OTA",
    INName: "NIIT OTA CENTRE, OTA, OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 27,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4462,
    INABV: "IMS",
    INName: "INSTITUTE OF MARITIME AND SAFETY, EJIGBO, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4463,
    INABV: "NIIT-OWERR",
    INName: "KUNOCH EDUCATION AND TRAINING COLLEGE, OWERRI, IMO STATE",
    InSt: 16,
    AFFL_ST_ID: 16,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4464,
    INABV: "DAGAMA",
    INName: "DAGAMA'S COLLEGE OF APPLIED SCIENCE, ZARIA, KADUNA STATE",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4465,
    INABV: "IMAN",
    INName: "IMAN COLLEGE OF BUSINESS TECHNOLOGY, KANO, KANO STATE",
    InSt: 19,
    AFFL_ST_ID: 19,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4466,
    INABV: "OLUAKA",
    INName: "OLUAKA INSTITUTE OF TECHNOLOGY, OWERRI, IMO STATE",
    InSt: 16,
    AFFL_ST_ID: 16,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4467,
    INABV: "LAUREL",
    INName: "LAUREL SCHOOL OF MINES, OGUDU, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4468,
    INABV: "IVTEC",
    INName:
      "INTERNATIONAL VOCATIONAL, TECH &amp; ENTREPRENEURSHIP COLL. AJASE-IPO, KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 23,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4469,
    INABV: "AFEMEMS",
    INName:
      "ARMED FORCES ELECTRICAL AND MECH. ENG. MECHAT. SCH. MOGADISHU CANTONMENT, ABUJA",
    InSt: 37,
    AFFL_ST_ID: 37,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4470,
    INABV: "LINCOLN",
    INName: "LINCOLN COLLEGE OF SCIENCE, MANAGEMENT AND TECHNOLOGY, FCT, ABUJA",
    InSt: 37,
    AFFL_ST_ID: 37,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4471,
    INABV: "EIT",
    INName: "ELERINMOSA INSTITUTE OF TECHNOLOGY, ERIN-OSUN, OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4472,
    INABV: "NBCT",
    INName: "NATION BUILDERS COLLEGE OF TECHNOLOGY, ASABA, DELTA STATE",
    InSt: 10,
    AFFL_ST_ID: 10,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4473,
    INABV: "EL-ACADEMY",
    INName: "EL-ACADEMY, CADASTRAL DISTRICT, GADUWA, FCT ABUJA",
    InSt: 37,
    AFFL_ST_ID: 37,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4474,
    INABV: "NITT",
    INName: "NIGERIA INSTITUTE OF TRANSPORT TECHNOLOGY, ZARIA, KADUNA STATE",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4475,
    INABV: "ASREA",
    INName: "ASTEVEN ENERGY INSTITUTE, ASESE, OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 27,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4476,
    INABV: "IMPERIAL",
    INName: "IMPERIAL COLLEGE OF ADVANCED STUDIES, KANO, KANO STATE",
    InSt: 19,
    AFFL_ST_ID: 19,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4477,
    INABV: "GRAJOS",
    INName: "GRAJOS COLLEGE OF MASS COMMUNICATION, OJOKORO, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4478,
    INABV: "PAN",
    INName: "PAN LEARNING CENTRE, KUKURI, KADUNA STATE",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4480,
    INABV: "MASTERMIND",
    INName: "MASTERMIND CATERING AND CULINARY INSTITUTE, GWARIMPA, ABUJA-FCT",
    InSt: 37,
    AFFL_ST_ID: 37,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4481,
    INABV: "ACAVATS",
    INName:
      "AFRICAN COLLEGE OF AGRICULTURE, VOCATIONAL AND TECH. STUDIES, AKURE, ONDO STATE",
    InSt: 28,
    AFFL_ST_ID: 28,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4482,
    INABV: "SOFTQUEST",
    INName: "SOFTQUEST INCORPORATED COLLEGE, OGBOMOSO, OYO STATE",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4483,
    INABV: "RAINDROP",
    INName:
      "RAINDROP COLLEGE OF MANAGEMENT AND TECHNOLOGY, AMANNACHI, EBENATOR, IMO STATE",
    InSt: 16,
    AFFL_ST_ID: 16,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4484,
    INABV: "AL-MUHIBBA",
    INName: "AL-MUHIBBA INSTITUTE ICT AND ENTREPRENEURSHIP, KUBWA, ABUJA",
    InSt: 37,
    AFFL_ST_ID: 37,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4485,
    INABV: "NORTHBRIDG",
    INName: "NORTHBRIDGE COLLEGE OF SCIENCE AND TECHNOLOGY, KANO, KANO STATE",
    InSt: 19,
    AFFL_ST_ID: 19,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4486,
    INABV: "METROCITI",
    INName: "METROCITI COLLEGE OF TECHNOLOGY, AGEGE, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4487,
    INABV: "HIN",
    INName: "HAJJ INSTITUTE OF NIGERIA, FCT-ABUJA",
    InSt: 37,
    AFFL_ST_ID: 37,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4488,
    INABV: "IPCASTECH",
    INName:
      "INTERNATIONAL PROFESSIONAL COLL. OF ADMIN. SCIENCE AND TECH, NASARAWA STATE",
    InSt: 25,
    AFFL_ST_ID: 25,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4489,
    INABV: "BAII",
    INName: "BROADCAST ARTS INSTITUTE, IKORODU, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4490,
    INABV: "NCNM-TECH",
    INName:
      "NIGERIA COLLEGE OF NATURAL MEDICINE TECHNOLOGY, VICTORIA ISLAND, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 4491,
    INABV: "AKPOLY-IEI",
    INName: "AKWA-IBOM STATE POLYTECHNIC (IEI), IKOT-OSURUA, AKWA IBOM STATE",
    InSt: 3,
    AFFL_ST_ID: 3,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 5101,
    INABV: "ADEYEMI",
    INName: "ADEYEMI COLLEGE OF EDUCATION, ONDO, ONDO STATE",
    InSt: 28,
    AFFL_ST_ID: 28,
    INTYP: 51,
    Inst_Cat: "FC",
    category: "College of Education",
  },
  {
    INID: 5102,
    INABV: "ALVAN",
    INName: "ALVAN IKOKU COLLEGE OF EDUCATION, OWERRI, IMO STATE",
    InSt: 16,
    AFFL_ST_ID: 16,
    INTYP: 51,
    Inst_Cat: "FC",
    category: "College of Education",
  },
  {
    INID: 5103,
    INABV: "FCE-ABEO",
    INName: "FEDERAL COLLEGE OF EDUCATION, ABEOKUTA, OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 27,
    INTYP: 51,
    Inst_Cat: "FC",
    category: "College of Education",
  },
  {
    INID: 5104,
    INABV: "FCE-AMUFU",
    INName: "FEDERAL COLLEGE OF EDUCATION, EHA-AMUFU, ENUGU STATE",
    InSt: 14,
    AFFL_ST_ID: 14,
    INTYP: 51,
    Inst_Cat: "FC",
    category: "College of Education",
  },
  {
    INID: 5105,
    INABV: "FCE-KANO",
    INName: "FEDERAL COLLEGE OF EDUCATION, KANO, KANO STATE",
    InSt: 19,
    AFFL_ST_ID: 19,
    INTYP: 51,
    Inst_Cat: "FC",
    category: "College of Education",
  },
  {
    INID: 5106,
    INABV: "FCE-KATSINA",
    INName: "FEDERAL COLLEGE OF EDUCATION, KATSINA, KATSINA STATE",
    InSt: 20,
    AFFL_ST_ID: 20,
    INTYP: 51,
    Inst_Cat: "FC",
    category: "College of Education",
  },
  {
    INID: 5107,
    INABV: "FCE-KONTAGORA",
    INName: "FEDERAL COLLEGE OF EDUCATION, KONTAGORA, NIGER STATE",
    InSt: 26,
    AFFL_ST_ID: 26,
    INTYP: 51,
    Inst_Cat: "FC",
    category: "College of Education",
  },
  {
    INID: 5108,
    INABV: "FCE-OBUDU",
    INName: "FEDERAL COLLEGE OF EDUCATION, OBUDU, CROSS RIVER STATE",
    InSt: 9,
    AFFL_ST_ID: 9,
    INTYP: 51,
    Inst_Cat: "FC",
    category: "College of Education",
  },
  {
    INID: 5109,
    INABV: "FCE-OKENE",
    INName: "FEDERAL COLLEGE OF EDUCATION, OKENE, KOGI STATE",
    InSt: 22,
    AFFL_ST_ID: 22,
    INTYP: 51,
    Inst_Cat: "FC",
    category: "College of Education",
  },
  {
    INID: 5110,
    INABV: "FCE-PANKSHIN",
    INName: "FEDERAL COLLEGE OF EDUCATION, PANKSHIN, PLATEAU STATE",
    InSt: 31,
    AFFL_ST_ID: 31,
    INTYP: 51,
    Inst_Cat: "FC",
    category: "College of Education",
  },
  {
    INID: 5111,
    INABV: "FCS-OYO",
    INName: "FEDERAL COLLEGE OF EDUCATION (SPECIAL), OYO, OYO STATE",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 51,
    Inst_Cat: "FC",
    category: "College of Education",
  },
  {
    INID: 5112,
    INABV: "FCE-YOLA",
    INName: "FEDERAL COLLEGE OF EDUCATION, YOLA, ADAMAWA STATE",
    InSt: 2,
    AFFL_ST_ID: 2,
    INTYP: 51,
    Inst_Cat: "FC",
    category: "College of Education",
  },
  {
    INID: 5113,
    INABV: "FCE-ZARIA",
    INName: "FEDERAL COLLEGE OF EDUCATION, ZARIA, KADUNA STATE",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 51,
    Inst_Cat: "FC",
    category: "College of Education",
  },
  {
    INID: 5114,
    INABV: "FT-AKOKA",
    INName: "FEDERAL COLLEGE OF EDUCATION (TECH.), AKOKA, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 51,
    Inst_Cat: "FC",
    category: "College of Education",
  },
  {
    INID: 5115,
    INABV: "FT-ASABA",
    INName: "FEDERAL COLLEGE OF EDUCATION (TECH.), ASABA, DELTA STATE",
    InSt: 10,
    AFFL_ST_ID: 10,
    INTYP: 51,
    Inst_Cat: "FC",
    category: "College of Education",
  },
  {
    INID: 5116,
    INABV: "FT-BICHI",
    INName: "FEDERAL COLLEGE OF EDUCATION (TECH.), BICHI, KANO, KANO STATE",
    InSt: 19,
    AFFL_ST_ID: 19,
    INTYP: 51,
    Inst_Cat: "FC",
    category: "College of Education",
  },
  {
    INID: 5117,
    INABV: "FT-GOMBE",
    INName: "FEDERAL COLLEGE OF EDUCATION (TECH.), GOMBE, GOMBE STATE",
    InSt: 15,
    AFFL_ST_ID: 15,
    INTYP: 51,
    Inst_Cat: "FC",
    category: "College of Education",
  },
  {
    INID: 5118,
    INABV: "FT-GUSAU",
    INName:
      "FEDERAL COLLEGE OF EDUCATION (TECH.) (FEMALE ONLY), GUSAU, ZAMFARA STATE",
    InSt: 36,
    AFFL_ST_ID: 36,
    INTYP: 51,
    Inst_Cat: "FC",
    category: "College of Education",
  },
  {
    INID: 5119,
    INABV: "FT-OMOKU",
    INName: "FEDERAL COLLEGE OF EDUCATION (TECH.), OMOKU, RIVERS STATE",
    InSt: 32,
    AFFL_ST_ID: 32,
    INTYP: 51,
    Inst_Cat: "FC",
    category: "College of Education",
  },
  {
    INID: 5120,
    INABV: "FT-POTIS",
    INName: "FEDERAL COLLEGE OF EDUCATION (TECH.), POTISKUM, YOBE STATE",
    InSt: 35,
    AFFL_ST_ID: 35,
    INTYP: 51,
    Inst_Cat: "FC",
    category: "College of Education",
  },
  {
    INID: 5121,
    INABV: "FT-UMUNZE",
    INName: "FEDERAL COLLEGE OF EDUCATION (TECH.), UMUNZE, ANAMBRA STATE",
    InSt: 4,
    AFFL_ST_ID: 4,
    INTYP: 51,
    Inst_Cat: "FC",
    category: "College of Education",
  },
  {
    INID: 5122,
    INABV: "NTI-KAD",
    INName: "NATIONAL TEACHERS INSTITUTE, KADUNA, KADUNA STATE",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 51,
    Inst_Cat: "FC",
    category: "College of Education",
  },
  {
    INID: 5123,
    INABV: "NASE",
    INName: "NIGERIAN ARMY SCHOOL OF EDUCATION, ILORIN, KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 23,
    INTYP: 51,
    Inst_Cat: "FC",
    category: "College of Education",
  },
  {
    INID: 5124,
    INABV: "NINL",
    INName: "NATIONAL INSTITUTE FOR NIGERIAN LANGUAGES, ABA, ABIA STATE",
    InSt: 1,
    AFFL_ST_ID: 1,
    INTYP: 51,
    Inst_Cat: "FC",
    category: "College of Education",
  },
  {
    INID: 5125,
    INABV: "FCE-SOKOTO",
    INName: "FEDERAL COLLEGE OF EDUCATION, GIDAN MADI, TANGAZA, SOKOTO STATE",
    InSt: 33,
    AFFL_ST_ID: 33,
    INTYP: 51,
    Inst_Cat: "FC",
    category: "College of Education",
  },
  {
    INID: 5126,
    INABV: "FCE-JAMA'ARE",
    INName: "FEDERAL COLLEGE OF EDUCATION, JAMA'ARE, BAUCHI STATE",
    InSt: 5,
    AFFL_ST_ID: 5,
    INTYP: 51,
    Inst_Cat: "FC",
    category: "College of Education",
  },
  {
    INID: 5127,
    INABV: "FCE-BENUE",
    INName: "FEDERAL COLLEGE OF EDUCATION, ODUGBO, APA, BENUE STATE",
    InSt: 7,
    AFFL_ST_ID: 7,
    INTYP: 51,
    Inst_Cat: "FC",
    category: "College of Education",
  },
  {
    INID: 5128,
    INABV: "FCE-EBONYI",
    INName: "FEDERAL COLLEGE OF EDUCATION, ISU, ONICHA, EBONYI STATE",
    InSt: 11,
    AFFL_ST_ID: 11,
    INTYP: 51,
    Inst_Cat: "FC",
    category: "College of Education",
  },
  {
    INID: 5129,
    INABV: "FCE-IWO",
    INName: "FEDERAL COLLEGE OF EDUCATION, IWO, OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 51,
    Inst_Cat: "FC",
    category: "College of Education",
  },
  {
    INID: 5130,
    INABV: "FCE-EDO",
    INName:
      "FEDERAL COLLEGE OF EDUCATION (TECHNICAL), EKIADOLOR, OVIA NORTH, EDO STATE",
    InSt: 12,
    AFFL_ST_ID: 12,
    INTYP: 51,
    Inst_Cat: "FC",
    category: "College of Education",
  },
  {
    INID: 5201,
    INABV: "AGBOR",
    INName: "COLLEGE OF EDUCATION, AGBOR, DELTA STATE",
    InSt: 10,
    AFFL_ST_ID: 10,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5202,
    INABV: "AKAMKPA",
    INName:
      "CROSS RIVER STATE COLLEGE OF EDUCATION, AKAMKPA, CROSS RIVER STATE",
    InSt: 9,
    AFFL_ST_ID: 9,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5203,
    INABV: "AKSCOE",
    INName: "AKWA-IBOM STATE COLLEGE OF EDUCATION, AFAHA-NSIT, AKWA IBOM STATE",
    InSt: 3,
    AFFL_ST_ID: 3,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5204,
    INABV: "AKWANGA",
    INName: "COLLEGE OF EDUCATION, AKWANGA, NASARAWA STATE",
    InSt: 25,
    AFFL_ST_ID: 25,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5205,
    INABV: "ANKPA",
    INName: "COLLEGE OF EDUCATION, ANKPA, KOGI STATE",
    InSt: 22,
    AFFL_ST_ID: 22,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5206,
    INABV: "ARGUNGU",
    INName: "ADAMU AUGIE COLLEGE OF EDUCATION, ARGUNGU, KEBBI STATE",
    InSt: 21,
    AFFL_ST_ID: 21,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5207,
    INABV: "AROCHUKWU",
    INName: "ABIA STATE COLLEGE OF EDUCATION (TECHNICAL), ABIA STATE",
    InSt: 1,
    AFFL_ST_ID: 1,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5208,
    INABV: "ASCOEA",
    INName: "AMINU SALEH COLLEGE OF EDUCATION, AZARE, BAUCHI STATE",
    InSt: 5,
    AFFL_ST_ID: 5,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5209,
    INABV: "COE-BAMA",
    INName:
      "UMAR IBN EI-KANEMI COLLEGE OF EDUC. SCIENCE AND TECH, BAMA, BORNO STATE",
    InSt: 8,
    AFFL_ST_ID: 8,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5210,
    INABV: "DUTSINMA",
    INName: "ISA KAITA COLLEGE OF EDUCATION, DUTSINMA, KATSINA STATE",
    InSt: 20,
    AFFL_ST_ID: 20,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5211,
    INABV: "EACOE",
    INName: "EMMANUEL ALAYANDE COLLEGE OF EDUCATION, OYO, OYO STATE",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5212,
    INABV: "EKIADOLOR",
    INName: "COLLEGE OF EDUCATION, EKIADOLOR, EDO STATE",
    InSt: 12,
    AFFL_ST_ID: 12,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5213,
    INABV: "ESCE(T)",
    INName:
      "ENUGU STATE COLLEGE OF EDUCATION (TECHNICAL), ABAKALIKI RD, ENUGU STATE",
    InSt: 14,
    AFFL_ST_ID: 14,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5214,
    INABV: "GASHUA",
    INName: "UMAR SULEIMAN COLLEGE OF EDUCATION, GASHUA, YOBE STATE",
    InSt: 35,
    AFFL_ST_ID: 35,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5215,
    INABV: "GIDAN-WAYA",
    INName:
      "KADUNA STATE COLLEGE OF EDUCATION, GIDAN-WAYA, KAFANCHAN, KADUNA STATE",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5216,
    INABV: "GINDIRI",
    INName: "COLLEGE OF EDUCATION, GINDIRI, PLATEAU STATE",
    InSt: 31,
    AFFL_ST_ID: 31,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5217,
    INABV: "GUMEL",
    INName: "JIGAWA STATE COLLEGE OF EDUCATION, GUMEL, JIGAWA STATE",
    InSt: 17,
    AFFL_ST_ID: 17,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5219,
    INABV: "COE-HONG",
    INName: "COLLEGE OF EDUCATION, HONG, ADAMAWA STATE",
    InSt: 2,
    AFFL_ST_ID: 2,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5220,
    INABV: "IGUEBEN",
    INName: "COLLEGE OF EDUCATION, IGUEBEN, EDO STATE",
    InSt: 12,
    AFFL_ST_ID: 12,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5221,
    INABV: "JASPERBORO",
    INName: "ISAAC JASPER BORO COLLEGE OF EDUCATION, SAGBAMA, BAYELSA STATE",
    InSt: 6,
    AFFL_ST_ID: 6,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5222,
    INABV: "TASCOED",
    INName: "TAI SOLARIN COLLEGE OF EDUCATION, IJEBU-ODE, OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 27,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5223,
    INABV: "COE-IKERE",
    INName: "COLLEGE OF EDUCATION, IKERE, EKITI STATE",
    InSt: 13,
    AFFL_ST_ID: 13,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5224,
    INABV: "IKWO",
    INName: "EBONYI STATE COLLEGE OF EDUCATION, IKWO, EBONYI STATE",
    InSt: 11,
    AFFL_ST_ID: 11,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5225,
    INABV: "OSCOE-ILA",
    INName: "OSUN STATE COLLEGE OF EDUCATION, ILA-ORANGUN, OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5226,
    INABV: "COE-ILESHA",
    INName: "COLLEGE OF EDUCATION, ILESHA, OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5227,
    INABV: "ILORIN_COL",
    INName: "KWARA STATE COLLEGE OF EDUCATION, ILORIN, KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 23,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5228,
    INABV: "JIGCILS",
    INName:
      "JIGAWA STATE COLLEGE OF EDUCATION AND LEGAL STUDIES, RINGIM, JIGAWA STATE",
    InSt: 17,
    AFFL_ST_ID: 17,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5229,
    INABV: "RIMI-COE",
    INName: "SAADATU RIMI COLLEGE OF EDUCATION, KUMBOTSO, KANO STATE",
    InSt: 19,
    AFFL_ST_ID: 19,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5230,
    INABV: "KAT-ALA",
    INName: "COLLEGE OF EDUCATION, KATSINA-ALA, BENUE STATE",
    InSt: 7,
    AFFL_ST_ID: 7,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5232,
    INABV: "COET-KABBA",
    INName: "KOGI STATE COLLEGE OF EDUCATION (TECHNICAL), KABBA, KOGI STATE",
    InSt: 22,
    AFFL_ST_ID: 22,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5233,
    INABV: "LAFIAGI(T)",
    INName: "COLLEGE OF EDUCATION, (TECH), LAFIAGI, KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 23,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5234,
    INABV: "COE-MAID",
    INName: "KASHIM-IBRAHIM COLLEGE OF EDUCATION, MAIDUGURI, BORNO STATE",
    InSt: 8,
    AFFL_ST_ID: 8,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5235,
    INABV: "COE-MARU",
    INName: "COLLEGE OF EDUCATION, MARU, ZAMFARA STATE",
    InSt: 36,
    AFFL_ST_ID: 36,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5236,
    INABV: "COE-MINNA",
    INName: "COLLEGE OF EDUCATION, MINNA, NIGER STATE",
    InSt: 26,
    AFFL_ST_ID: 26,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5237,
    INABV: "MOCPED",
    INName:
      "MICHAEL OTEDOLA COLLEGE OF PRIMARY EDUCATION, NOFORIJA, EPE, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5238,
    INABV: "MOGOCLIS",
    INName:
      "MOHAMMED GONI COLLEGE OF LEGAL AND ISLAMIC STUDIES, MAIDUGURI, BORNO STATE",
    InSt: 8,
    AFFL_ST_ID: 8,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5239,
    INABV: "MOSOGAR",
    INName: "DELTA STATE COLLEGE OF PHYSICAL EDUCATION, MOSOGAR, DELTA STATE",
    InSt: 10,
    AFFL_ST_ID: 10,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5240,
    INABV: "NOCOEN",
    INName: "NWAFOR ORIZU COLLEGE OF EDUCATION, NSUGBE, ANAMBRA STATE",
    InSt: 4,
    AFFL_ST_ID: 4,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5241,
    INABV: "COE-OJU",
    INName: "COLLEGE OF EDUCATION, OJU, BENUE STATE",
    InSt: 7,
    AFFL_ST_ID: 7,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5242,
    INABV: "COE-ORO",
    INName: "COLLEGE OF EDUCATION, ORO, KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 23,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5243,
    INABV: "IJANIKIN",
    INName: "ADENIRAN OGUNSANYA COLLEGE OF EDUCATION, IJANIKIN, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5244,
    INABV: "SHAGARICOE",
    INName: "SHEHU SHAGARI COLLEGE OF EDUCATION, SOKOTO, SOKOTO STATE",
    InSt: 33,
    AFFL_ST_ID: 33,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5245,
    INABV: "COE-BIU",
    INName: "COLLEGE OF EDUCATION, WAKA-BIU, BORNO STATE",
    InSt: 8,
    AFFL_ST_ID: 8,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5246,
    INABV: "COE-WARRI",
    INName: "COLLEGE OF EDUCATION, WARRI, DELTA STATE",
    InSt: 10,
    AFFL_ST_ID: 10,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5247,
    INABV: "COE-ZING",
    INName: "COLLEGE OF EDUCATION, ZING, TARABA STATE",
    InSt: 34,
    AFFL_ST_ID: 34,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5248,
    INABV: "COE-ZUBA",
    INName: "FCT COLLEGE OF EDUCATION, ZUBA, ABUJA, FCT",
    InSt: 37,
    AFFL_ST_ID: 37,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5249,
    INABV: "COE-KURA",
    INName: "COLLEGE OF EDUCATION, KURA, KANO STATE",
    InSt: 19,
    AFFL_ST_ID: 19,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5250,
    INABV: "ATB-COE",
    INName: "ADAMU TAFAWA BALEWA COLLEGE OF EDUCATION, KANGERE, BAUCHI STATE",
    InSt: 5,
    AFFL_ST_ID: 5,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5251,
    INABV: "LANLATECOE",
    INName: "COLLEGE OF EDUCATION, LANLATE, OYO STATE",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5252,
    INABV: "RUFAI-COL",
    INName:
      "A.D. RUFAI COLLEGE OF EDU. FOR LEGAL AND ISLAMIC STUDIES, MISAU, BAUCHI STATE",
    InSt: 5,
    AFFL_ST_ID: 5,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5253,
    INABV: "BOCOE",
    INName: "BILYAMINU OTHMAN COLLEGE OF EDUCATION, DASS, BAUCHI STATE",
    InSt: 5,
    AFFL_ST_ID: 5,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5254,
    INABV: "COE-BILLIRI",
    INName: "COLLEGE OF EDUCATION, BILLIRI, GOMBE STATE",
    InSt: 15,
    AFFL_ST_ID: 15,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5255,
    INABV: "IMSCOL",
    INName: "IMO STATE COLLEGE OF EDUCATION, IHITTE/UBOMA, IMO STATE",
    InSt: 16,
    AFFL_ST_ID: 16,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5256,
    INABV: "ULI-COE",
    INName: "ULI COLLEGE OF EDUCATION, ULI, ANAMBRA STATE",
    InSt: 4,
    AFFL_ST_ID: 4,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5257,
    INABV: "CLIS-NGURU",
    INName:
      "ATIKU ABUBAKAR COLLEGE OF LEGAL AND ISLAMUIC STUDIES, NGURU, YOBE STATE",
    InSt: 35,
    AFFL_ST_ID: 35,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5258,
    INABV: "COE-DARAZO",
    INName: "COLLEGE OF EDUCATION, DARAZO, BAUCHI STATE",
    InSt: 5,
    AFFL_ST_ID: 5,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5259,
    INABV: "KZ-COE",
    INName: "KAZAURE COLLEGE OF EDUCATION, KAZAURE, JIGAWA STATE",
    InSt: 17,
    AFFL_ST_ID: 17,
    INTYP: 52,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5260,
    INABV: "AK-COE",
    INName: "AMINU KANO COLLEGE OF EDUCATION, KANO, KANO STATE",
    InSt: 19,
    AFFL_ST_ID: 19,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5261,
    INABV: "COE-EDO",
    INName: "EDO STATE COLLEGE OF EDUCATION, EDO STATE",
    InSt: 12,
    AFFL_ST_ID: 12,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5262,
    INABV: "COE-NAFADA",
    INName:
      "GOMBE STATE COLLEGE OF EDUCATION AND LEGAL STUDIES, NAFADA, GOMBE STATE",
    InSt: 15,
    AFFL_ST_ID: 15,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5263,
    INABV: "IIT-ZARIA",
    INName: "INSTITUTE OF INFORMATION TECHNOLOGY, ZARIA, KADUNA STATE",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5264,
    INABV: "COE-BUKURU",
    INName: "TCNN COLLEGE OF EDUCATION, BUKURU,PLATEAU STATE",
    InSt: 31,
    AFFL_ST_ID: 31,
    INTYP: 52,
    Inst_Cat: "SC",
    category: "College of Education",
  },
  {
    INID: 5301,
    INABV: "ACOE",
    INName: "ALHIKMA COLLEGE OF EDUCATION, ANKPA, KOGI STATE",
    InSt: 22,
    AFFL_ST_ID: 22,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5302,
    INABV: "AICOEP",
    INName: "ARABIC AND ISLAMIC COLLEGE OF EDUCATION, POTISKUM, YOBE STATE",
    InSt: 35,
    AFFL_ST_ID: 35,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5303,
    INABV: "AKLEGAL",
    INName: "AMINU KANO COLLEGE OF ISLAMIC AND LEGAL STUDIES, KANO, KANO STATE",
    InSt: 19,
    AFFL_ST_ID: 19,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5304,
    INABV: "ANGEL",
    INName: "ANGEL CROWN COLLEGE OF EDUCATION, GIDAN-DAYA, ABUJA, FCT",
    InSt: 37,
    AFFL_ST_ID: 37,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5305,
    INABV: "ANSAR",
    INName: "ANSAR-UD-DEEN COLLEGE OF EDUCATION, ISOLO, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5306,
    INABV: "APA-COL",
    INName: "APA COLLEGE OF EDUCATION, AIDO GODO-OKPOGA, BENUE STATE",
    InSt: 7,
    AFFL_ST_ID: 7,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5307,
    INABV: "ASSCOED",
    INName: "ASSANUSIYAH COLLEGE OF EDUCATION, IPETUMODU, OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5308,
    INABV: "ATCOI",
    INName:
      "AFRICAN THINKERS COMMUNITY OF INQUIRY COLLEGE OF EDUCATION, ENUGU STATE",
    InSt: 14,
    AFFL_ST_ID: 14,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5309,
    INABV: "AWORI",
    INName: "AWORI DISTRICT COLLEGE OF EDUCATION, OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 27,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5310,
    INABV: "KE-COE",
    INName: "KOGI EAST COLLEGE OF EDUCATION, KOGI STATE",
    InSt: 22,
    AFFL_ST_ID: 22,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5311,
    INABV: "BELCOED",
    INName: "BEST LEGACY COLLEGE OF EDUCATION, OGBOMOSO, OYO STATE",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5312,
    INABV: "BETHEL",
    INName: "BETHEL COLLEGE OF EDUCATION, IJARE, ONDO STATE",
    InSt: 28,
    AFFL_ST_ID: 28,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5313,
    INABV: "BIAIS",
    INName: "BAUCHI INSTITUTE FOR ARABIC AND ISLAMIC STUDIES, BAUCHI STATE",
    InSt: 5,
    AFFL_ST_ID: 5,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5314,
    INABV: "CALVIN",
    INName: "CALVIN FOUNDATION COLLEGE OF EDUCATION, NAKA, BENUE STATE",
    InSt: 7,
    AFFL_ST_ID: 7,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5315,
    INABV: "CITY",
    INName: "CITY COLLEGE OF EDUCATION, GARKI, ABUJA, FCT",
    InSt: 37,
    AFFL_ST_ID: 37,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5316,
    INABV: "COE-ERO",
    INName: "COLLEGE OF EDUCATION, ERO, AKURE, ONDO STATE",
    InSt: 28,
    AFFL_ST_ID: 28,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5317,
    INABV: "COEN",
    INName: "THE COLLEGE OF EDUCATION, NSUKKA, ENUGU STATE",
    InSt: 14,
    AFFL_ST_ID: 14,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5318,
    INABV: "COE-OFFA",
    INName: "COLLEGE OF EDUCATION, IRRA ROAD, ILEMONA, KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 23,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5319,
    INABV: "CORNER",
    INName: "CORNERSTONE COLLEGE OF EDUCATION, LAGOS, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5320,
    INABV: "DELAR",
    INName: "DELAR COLLEGE OF EDUCATION, IBADAN, OYO STATE",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5321,
    INABV: "EBENEZER",
    INName: "EBENEZER COLLEGE OF EDUCATION, EDDA, EBONYI STATE",
    InSt: 11,
    AFFL_ST_ID: 11,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5322,
    INABV: "ELDER-OYAMA",
    INName:
      "ELDER OYAMA MEMORIAL COLLEGE OF EDUCATION, OFAT, OBUBRA, CROSS RIVER STATE",
    InSt: 9,
    AFFL_ST_ID: 9,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5323,
    INABV: "GBOKO-COE",
    INName: "GBOKO COLLEGE OF EDUCATION, GBOKO, BENUE STATE",
    InSt: 7,
    AFFL_ST_ID: 7,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5324,
    INABV: "HAVARD",
    INName: "HARVARD WILSON COLLEGE OF EDUCATION, ABA, ABIA STATE",
    InSt: 1,
    AFFL_ST_ID: 1,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5325,
    INABV: "HILL-COE",
    INName: "HILL COLLEGE OF EDUCATION, GWANJI, AKWANGA,  NASARAWA STATE",
    InSt: 25,
    AFFL_ST_ID: 25,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5326,
    INABV: "IECE",
    INName:
      "INSTITUTE OF ECUMENICAL EDUCATION, THINKER CORNER, ENUGU, ENUGU STATE",
    InSt: 14,
    AFFL_ST_ID: 14,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5327,
    INABV: "COE-IPERE",
    INName: "IPERE COLLEGE OF EDUCATION, AGYARAGU, NASARAWA STATE",
    InSt: 25,
    AFFL_ST_ID: 25,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5328,
    INABV: "JIBWIS",
    INName: "JIBWIS COLLEGE OF EDUCATION, GOMBE, GOMBE STATE",
    InSt: 15,
    AFFL_ST_ID: 15,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5329,
    INABV: "KINSEY",
    INName: "KINSEY COLLEGE OF EDUCATION, ILORIN, KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 23,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5330,
    INABV: "KWARARAFAC",
    INName: "KWARARAFA COLLEGE OF EDUCATION, OTUKPO, BENUE STATE",
    InSt: 7,
    AFFL_ST_ID: 7,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5331,
    INABV: "LESSEL",
    INName: "LESSEL COLLEGE OF EDUCATION, LESSEL, USHONGO LGA, BENUE STATE",
    InSt: 7,
    AFFL_ST_ID: 7,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5332,
    INABV: "LINKS-MORO",
    INName:
      "COLLEGE OF EDUCATION, FOREIGN LINKS CAMPUS MORO, IFE NORTH, OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5334,
    INABV: "MUHYIDEEN",
    INName: "MUHYIDEEN COLLEGE OF EDUCATION, ILORIN, KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 23,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5335,
    INABV: "MULCOED",
    INName:
      "MUFTAU’ LANIHUN COLLEGE OF EDUCATION, IBADAN-LAGOS EXP WAY,OREMEJI, OYO STATE",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5336,
    INABV: "MURITADHA",
    INName: "MURITADHA COLLEGE OF EDUCATION, OLODO, IBADAN, OYO STATE",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5337,
    INABV: "NANA-COE",
    INName: "NANA AISHAT MEMORIAL COLLEGE OF EDUCATION, KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 23,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5339,
    INABV: "ONITCOE",
    INName: "ONIT COLLEGE OF EDUCATION, MBAUKWU, ANAMBRA STATE",
    InSt: 4,
    AFFL_ST_ID: 4,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5340,
    INABV: "OSISATEC",
    INName:
      "OUR SAVIOUR INSTITUTE OF SCIENCE &amp; TECH. COLLEGE OF EDUCATION, OGUI, ENUGU STATE",
    InSt: 14,
    AFFL_ST_ID: 14,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5341,
    INABV: "PEACELAND",
    INName: "PEACE LAND COLLEGE OF EDUCATION, ENUGU, ENUGU STATE",
    InSt: 14,
    AFFL_ST_ID: 14,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5342,
    INABV: "PIAGET",
    INName: "PIAGET COLLEGE OF EDUCATION, IBARA, ABEOKUTA, OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 27,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5343,
    INABV: "ROYAL-CITY",
    INName: "ROYAL CITY COLLEGE OF EDUCATION, IYESI, OTTA, OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 27,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5344,
    INABV: "TIM-COE",
    INName:
      "ST. AUGUSTINE COLLEGE OF EDUCATION (PROJECT TIME), YABA, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5345,
    INABV: "STEADYFLOW",
    INName:
      "STEADY FLOW COLLEGE OF EDUCATION, AKARABONG, IKOM, CROSS RIVER STATE",
    InSt: 9,
    AFFL_ST_ID: 9,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5346,
    INABV: "TACCOED",
    INName: "THE AFRICAN CHURCH COLLEGE OF EDUCATION, LAGOS, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5347,
    INABV: "TIJANI-COE",
    INName: "BAYO TIJANI COLLEGE OF EDUCATION, EGAN-IGANDO, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5348,
    INABV: "TOPMOST",
    INName: "TOPMOST COLLEGE OF EDUCATION, IPAJA, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5349,
    INABV: "UNITY-COE",
    INName: "UNITY COLLEGE OF EDUCATION, AUKPA-ADOKA, BENUE STATE",
    InSt: 7,
    AFFL_ST_ID: 7,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5350,
    INABV: "YBUCLGS",
    INName:
      "YUSUF BALA USMAN COLLEGE OF LEGAL AND GENERAL STUDIES, DAURA, KATSINA STATE",
    InSt: 20,
    AFFL_ST_ID: 20,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5351,
    INABV: "YEWA",
    INName: "YEWA CENTRAL COLLEGE OF EDUCATION, ABEOKUTA, OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 27,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5352,
    INABV: "GCOE-JOS",
    INName: "GLOBAL COLLEGE OF EDUCATION, JOS, PLATEAU STATE",
    InSt: 31,
    AFFL_ST_ID: 31,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5353,
    INABV: "ECWA-COE",
    INName: "ECWA COLLEGE OF EDUCATION, IGBAJA, KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 23,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5358,
    INABV: "ECWA-JOS",
    INName: "ECWA COLLEGE OF EDUCATION, JOS, PLATEAU STATE",
    InSt: 31,
    AFFL_ST_ID: 31,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5360,
    INABV: "CORONA",
    INName: "CORONA COLLEGE OF EDUCATION, ILUPEJU, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5361,
    INABV: "ASCAS",
    INName: "AMEER SHEHU IDRIS COLLEGE OF ADVANCE STUDIES, ZARIA, KADUNA STATE",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5363,
    INABV: "PAN-AFRCOE",
    INName: "PAN AFRICAN COLLEGE OF EDUCATION, OFFA, KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 23,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5364,
    INABV: "DA'AWA-COE",
    INName: "DA'AWAH COLLEGE OF EDUCATION, GAME VILLAGE, BAUCHI, BAUCHI STATE",
    InSt: 5,
    AFFL_ST_ID: 5,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5365,
    INABV: "FESTMED",
    INName: "FESTMED COLLEGE OF EDUCATION, AJOWA,ONDO STATE",
    InSt: 28,
    AFFL_ST_ID: 28,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5370,
    INABV: "HOPE-COE",
    INName: "HOPE AND ANCHOR COLLEGE OF EDUCATION, SULEJA, NIGER STATE",
    InSt: 26,
    AFFL_ST_ID: 26,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5371,
    INABV: "AL-IBADAN",
    INName: "AL-IBADAN COLLEGE OF EDUCATION, IBADAN, OYO STATE",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5372,
    INABV: "UPLAND-COE",
    INName: "UPLAND COLLEGE OF EDUCATION, IPESI-IFIRA, AKOKO, ONDO STATE",
    InSt: 28,
    AFFL_ST_ID: 28,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5373,
    INABV: "EBIJE-COE",
    INName: "EMMANUEL EBIJE IKWUE COLLEGE OF EDUCATION, OTUKPO, BENUE STATE",
    InSt: 7,
    AFFL_ST_ID: 7,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5374,
    INABV: "MCF-COE",
    INName:
      "MICHAEL AND CECILIA FOUNDATION COLLEGE OF EDUC. AGBRHA-OTOR,UGHELLI, DELTA STATE",
    InSt: 10,
    AFFL_ST_ID: 10,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5375,
    INABV: "SUNNAH-COE",
    INName: "SUNNAH COLLEGE OF EDUCATION, BAUCHI, BAUCHI STATE",
    InSt: 5,
    AFFL_ST_ID: 5,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5376,
    INABV: "OSWALD-COE",
    INName: "OSWALD WALLER COLLEGE OF EDUCATION, SHENDAM, PLATEAU STATE",
    InSt: 31,
    AFFL_ST_ID: 31,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5377,
    INABV: "DIAMOND",
    INName: "DIAMOND COLLEGE OF EDUCATION, ABA, ABIA STATE",
    InSt: 1,
    AFFL_ST_ID: 1,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5378,
    INABV: "ELI-COE",
    INName:
      "ELIZABETH MEMORIAL COLLEGE OF EDUCATION, ODENIGBO, NSUKKA, ENUGU STATE",
    InSt: 14,
    AFFL_ST_ID: 14,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5379,
    INABV: "COE-ILORI",
    INName: "ILORI COLLEGE OF EDUCATION, EDE, OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5380,
    INABV: "SARKIN-YAM",
    INName:
      "SARKIN YAMMA COMMUNITY COLLEGE OF EDUCATION, TILDEN FULANI, BAUCHI STATE",
    InSt: 5,
    AFFL_ST_ID: 5,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5381,
    INABV: "COE-KHATAB",
    INName: "UMAR BUN KHATAB COLLEGE OF EDUCATION, KADUNA STATE",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5382,
    INABV: "ADESHINA",
    INName: "ADESHINA COLLEGE OF EDUCATION, SHARE, KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 23,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5383,
    INABV: "CRESTFIELD",
    INName: "CRESTFIELD COLLEGE OF EDUCATION, ERIN OSUN, OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5384,
    INABV: "MOJE-COE",
    INName: "MOJE COLLEGE OF EDUCATION, ERIN-ILE, KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 23,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5385,
    INABV: "SINAI-COE",
    INName:
      "SINAI COLLEGE OF EDUCATION AND ENTREPRENEURIAL STUDIES, GBOKO, BENUE STATE",
    InSt: 7,
    AFFL_ST_ID: 7,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5386,
    INABV: "JIBWIS-BAU",
    INName: "JIBWIS COLLEGE OF EDUCATION, JAMA'ARE, BAUCHI STATE",
    InSt: 5,
    AFFL_ST_ID: 5,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5387,
    INABV: "LIFE-GATE",
    INName: "LIFE GATE COLLEGE OF EDUCATION, AFON, ASA, KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 23,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5388,
    INABV: "PEACE-COE",
    INName: "PEACE COLLEGE OF EDUCATION, ENOKPOLI, KOGI  STATE",
    InSt: 22,
    AFFL_ST_ID: 22,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5389,
    INABV: "HAMZAINAB",
    INName: "HAMZAINAB COLLEGE OF EDUCATION, OSOGBO, OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5390,
    INABV: "AGZ-BAJOGA",
    INName: "AGZ COLLEGE OF EDUCATION, BAJOGA, GOMBE STATE",
    InSt: 15,
    AFFL_ST_ID: 15,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5391,
    INABV: "PEACOCK",
    INName: "PEACOCK COLLEGE OF EDUCATION, JALINGO, TARABA STATE",
    InSt: 34,
    AFFL_ST_ID: 34,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5392,
    INABV: "HAMZAT-COE",
    INName: "IMAM HAMZAT COLLEGE OF EDUCATION, ILORIN, KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 23,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5393,
    INABV: "METRO-COE",
    INName: "METRO COLLEGE OF EDUCATION, ADOGI, NASARAWA STATE",
    InSt: 25,
    AFFL_ST_ID: 25,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5394,
    INABV: "IMAM-SAIDU",
    INName: "IMAM SAIDU COLLEGE OF EDUCATION, FUNTUA, KATSINA STATE",
    InSt: 20,
    AFFL_ST_ID: 20,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5395,
    INABV: "COVENANT-COE",
    INName: "COVENANT COLLEGE OF EDUCATION, ABA, ABIA STATE",
    InSt: 1,
    AFFL_ST_ID: 1,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5396,
    INABV: "GS-COE",
    INName: "GOOD SHEPHERD COLLEGE OF EDUCATION, DANLEMO ALAKUKO, OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 27,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5397,
    INABV: "NOSAKHARE",
    INName: "NOSAKHARE COLLEGE OF EDUCATION, BENIN CITY, EDO STATE",
    InSt: 12,
    AFFL_ST_ID: 12,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5398,
    INABV: "AMEENUDEEN",
    INName: "AMEENUDEEN COLLEGE OF EDUCATION, KANO, KANO STATE",
    InSt: 19,
    AFFL_ST_ID: 19,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5399,
    INABV: "JIBWIS-KEF",
    INName: "JIBWIS COLLEGE OF EDUCATION, KEFFI, NASARAWA STATE",
    InSt: 25,
    AFFL_ST_ID: 25,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5401,
    INABV: "KADPOLY-ED",
    INName: "KADUNA POLYTECHNIC (NCE), KADUNA, KADUNA STATE",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 54,
    Inst_Cat: "NP",
    category: "College of Education",
  },
  {
    INID: 5402,
    INABV: "WUFPOBK-ED",
    INName: "WAZIRI UMARU FEDERAL POLYTECHNIC (NCE), BIRNIN KEBBI, KEBBI STATE",
    InSt: 21,
    AFFL_ST_ID: 21,
    INTYP: 54,
    Inst_Cat: "NP",
    category: "College of Education",
  },
  {
    INID: 5403,
    INABV: "ATAPOLY-ED",
    INName: "ABUBAKAR TATARI ALI POLYTECHNIC (NCE), BAUCHI, BAUCHI STATE",
    InSt: 5,
    AFFL_ST_ID: 5,
    INTYP: 54,
    Inst_Cat: "NP",
    category: "College of Education",
  },
  {
    INID: 5404,
    INABV: "KATPOLY-ED",
    INName: "HASSAN USMAN KATSINA POLYTECHNIC (NCE), KATSINA, KATSINA STATE",
    InSt: 20,
    AFFL_ST_ID: 20,
    INTYP: 54,
    Inst_Cat: "NP",
    category: "College of Education",
  },
  {
    INID: 5406,
    INABV: "NBPZ-ED",
    INName: "NUHU BAMALLI POLYTECHNIC (NCE), ZARIA, KADUNA STATE",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 54,
    Inst_Cat: "NP",
    category: "College of Education",
  },
  {
    INID: 5407,
    INABV: "OSUNPO-ED",
    INName: "OSUN STATE POLYTECHNIC (NCE), IREE, OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 54,
    Inst_Cat: "NP",
    category: "College of Education",
  },
  {
    INID: 5408,
    INABV: "PLSPO-ED",
    INName: "PLATEAU STATE POLYTECHNIC (NCE), BARKIN-LADI, PLATEAU STATE",
    InSt: 31,
    AFFL_ST_ID: 31,
    INTYP: 54,
    Inst_Cat: "NP",
    category: "College of Education",
  },
  {
    INID: 5411,
    INABV: "KASCEPS",
    INName:
      "KANO STATE COLLEGE OF EDUCATION AND PRELIMINARY STUDIES, KANO,  KANO STATE",
    InSt: 19,
    AFFL_ST_ID: 19,
    INTYP: 54,
    Inst_Cat: "NP",
    category: "College of Education",
  },
  {
    INID: 5412,
    INABV: "ANISE",
    INName: "ANNUR INSTITUTE FOR ISLAMIC EDUC, KWALAJAWA, RANGAZA, KANO STATE",
    InSt: 19,
    AFFL_ST_ID: 19,
    INTYP: 54,
    Inst_Cat: "NP",
    category: "College of Education",
  },
  {
    INID: 5413,
    INABV: "BIGA-COED",
    INName:
      "BIGA COLLEGE OF EDUCATION, ARKILLA, FEDERAL-LOW COST, NASARAWA, SOKOTO STATE",
    InSt: 33,
    AFFL_ST_ID: 33,
    INTYP: 54,
    Inst_Cat: "NP",
    category: "College of Education",
  },
  {
    INID: 5414,
    INABV: "KANPOLY-ED",
    INName: "KANO STATE POLYTECHNIC (NCE), KANO, KANO STATE",
    InSt: 19,
    AFFL_ST_ID: 19,
    INTYP: 54,
    Inst_Cat: "NP",
    category: "College of Education",
  },
  {
    INID: 5701,
    INABV: "TCIS",
    INName: "TURATH COLLEGE OF ISLAMIC STUDIES, GORON DUTSE, KANO STATE",
    InSt: 19,
    AFFL_ST_ID: 19,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5702,
    INABV: "GRACE-COE",
    INName: "GRACE COLLEGE OF EDUCATION, OSHOGBO, OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5703,
    INABV: "MEADOW",
    INName: "MEADOW HALL COLLEGE OF EDUCATION, ALMA BEACH ESTATE, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5704,
    INABV: "GARKUWA",
    INName: "ADAMU GARKUWA COLLEGE OF EDUCATION, TORO, BAUCHI STATE",
    InSt: 5,
    AFFL_ST_ID: 5,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5705,
    INABV: "JIBWIS-POT",
    INName: "JIBWIS COLLEGE OF EDUCATION, POTISKUM, YOBE STATE",
    InSt: 35,
    AFFL_ST_ID: 35,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5706,
    INABV: "BOGORO-COE",
    INName: "BOGORO COLLEGE OF EDUCATION, BOGORO, BAUCHI STATE",
    InSt: 5,
    AFFL_ST_ID: 5,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5707,
    INABV: "ICE-KARU",
    INName: "INNOVATIVE COLLEGE OF EDUCATION, KARU, NASARAWA STATE",
    InSt: 25,
    AFFL_ST_ID: 25,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5708,
    INABV: "OLEKAMBA",
    INName: "OLEKAMBA COLLEGE OF EDUCATION, AFIN-AKURE, ONDO STATE",
    InSt: 28,
    AFFL_ST_ID: 28,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5709,
    INABV: "EMAMOR",
    INName: "EMAMOR COLLEGE OF EDUCATION, ABUA, RIVERS STATE",
    InSt: 32,
    AFFL_ST_ID: 32,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5710,
    INABV: "CLIMAX",
    INName: "CLIMAX COLLEGE OF EDUCATION, BAUCHI, BAUCHI STATE",
    InSt: 5,
    AFFL_ST_ID: 5,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5711,
    INABV: "COE-DUT",
    INName: "COLLEGE OF EDUCATION, DUTSEN-TANSHI, BAUCHI STATE",
    InSt: 5,
    AFFL_ST_ID: 5,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5712,
    INABV: "GPI-COE",
    INName:
      "GOD'S POWER INTERNATIONAL COLLEGE OF EDUCATION, ANYIGBA, KOGI STATE",
    InSt: 22,
    AFFL_ST_ID: 22,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5713,
    INABV: "RAPHAT",
    INName: "RAPHAT COLLEGE OF EDUCATION, OBAAGUN, OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5714,
    INABV: "ELISBEST",
    INName: "ELIBEST COLLEGE OF EDUCATION, AKURE, ONDO STATE",
    InSt: 28,
    AFFL_ST_ID: 28,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5715,
    INABV: "JIBWIS-JOS",
    INName: "JIBWIS COLLEGE OF EDUCATION, JOS, PLATEAU STATE",
    InSt: 31,
    AFFL_ST_ID: 31,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5716,
    INABV: "AL-FAJR",
    INName: "AL-FAJR COLLEGE OF EDUCATION, KANO, KANO STATE",
    InSt: 19,
    AFFL_ST_ID: 19,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5717,
    INABV: "TICOED",
    INName: "TIJJANI IBRAHIM COLLEGE OF EDUCATION, KANO, KANO STATE",
    InSt: 19,
    AFFL_ST_ID: 19,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5718,
    INABV: "DANYAYA",
    INName: "DANYAYA COLLEGE OF EDUCATION, NINGI, BAUCHI STATE",
    InSt: 5,
    AFFL_ST_ID: 5,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5719,
    INABV: "MUS'AB",
    INName:
      "MUS'AB BN UMAIR COMMUNITY COLLEGE OF EDUCATION, BAJOGA, GOMBE STATE",
    InSt: 15,
    AFFL_ST_ID: 15,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5720,
    INABV: "OMAGA",
    INName: "OMAGA EJIGBO COLLEGE OF EDUCATION, DEKINA, KOGI STATE",
    InSt: 22,
    AFFL_ST_ID: 22,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5721,
    INABV: "HIS-GRACE",
    INName: "HIS GRACE INDEPENDENT COLLEGE OF EDUCATION, ILORIN, KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 23,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5722,
    INABV: "ST.FRANCES",
    INName:
      "ST. FRANCES ASSISI COLLEGE OF EDUCATION, WUSASA - ZARIA, KADUNA STATE",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5723,
    INABV: "SACAS-COE",
    INName: "SACAS COLLEGE OF EDUCATION, KWALI, ABUJA, FCT",
    InSt: 37,
    AFFL_ST_ID: 37,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5724,
    INABV: "ST. PAUL'S",
    INName: "ST. PAUL'S COMMUNITY COLLEGE OF EDUCATION, NNEWI, ANAMBRA STATE",
    InSt: 4,
    AFFL_ST_ID: 4,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5725,
    INABV: "CHRIST-COE",
    INName: "CHRISTIAN COLLEGE OF EDUCATION, GOMBE, GOMBE STATE",
    InSt: 15,
    AFFL_ST_ID: 15,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5726,
    INABV: "JIBWIS-ZURU",
    INName: "JIBWIS COLLEGE OF EDUCATION, ZURU, KEBBI STATE",
    InSt: 21,
    AFFL_ST_ID: 21,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5727,
    INABV: "AHLUS-COE",
    INName: "AHLUS-SUFFAH COLLEGE OF EDUCATION, KADUNA, KADUNA STATE",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5728,
    INABV: "AL-UMMAH",
    INName: "AL-UMMAH COLLEGE OF EDUCATION, IWO, OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5729,
    INABV: "ROYAL-COE",
    INName: "ROYAL COLLEGE OF EDUCATION, IFO, OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 27,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5730,
    INABV: "JIBWIS-GUMAU",
    INName: "JIBWIS COLLEGE OF EDUCATION, GUMAU, BAUCHI STATE",
    InSt: 5,
    AFFL_ST_ID: 5,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5731,
    INABV: "GRAND-PLUS",
    INName: "GRAND-PLUS COLLEGE OF EDUCATION, ILORIN, KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 23,
    INTYP: 53,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 5732,
    INABV: "PREMIER-COE",
    INName: "PREMIER COLLEGE OF EDUCATION, EGBEDORE, OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 57,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 1264,
    INABV: "UNI-ILESA",
    INName: "UNIVERSITY OF ILESA, ILESA, OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 12,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 6001,
    INABV: "SUMAS",
    INName: "ENUGU STATE UNIVERSITY OF MEDICAL AND APPLIED SCIENCES",
    InSt: 14,
    AFFL_ST_ID: 14,
    INTYP: 11,
    Inst_Cat: "SU",
    category: "University",
  },
  {
    INID: 6002,

    INName: "FEDERAL UNIVERSITY OF TECHNOLOGY, IKOT-ABASI, AKWA IBOM STATE",
    InSt: 3,
    AFFL_ST_ID: 3,
    INTYP: 11,
    Inst_Cat: "FU",
    category: "University",
  },
  {
    INID: 6003,
    INName: "SHEHU SHAGARI UNIVERSITY OF EDUCATION, TAMBUWAL, SOKOTO STATE",
    InSt: 33,
    AFFL_ST_ID: 33,
    INTYP: 11,
    Inst_Cat: "FU",
    category: "University",
  },
  {
    INID: 6004,
    INName: "FEDERAL POLYTECHNIC, MONGUNO, BORNO STATE",
    InSt: 8,
    AFFL_ST_ID: 8,
    INTYP: 31,
    Inst_Cat: "FP",
    category: "Polytechnic",
  },
  {
    INID: 6005,
    INName: "FEDERAL POLYTECHNIC, WANNUNE, BENUE STATE",
    InSt: 7,
    AFFL_ST_ID: 7,
    INTYP: 31,
    Inst_Cat: "FP",
    category: "Polytechnic",
  },
  {
    INID: 6006,
    INName: "DE-POTTER COLLEGE OF HEALTH TECHNOLOGY, ORU, OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 27,
    INTYP: 31,
    Inst_Cat: "PC",
    category: "College of Education",
  },
  {
    INID: 6007,
    INName:
      "JIGAWA STATE COLLEGE OF REMIDIAL AND ADVANCED STUDIES, BABURA, JIGAWA STATE",
    InSt: 17,
    AFFL_ST_ID: 17,
    INTYP: 31,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 6008,
    INName:
      "JOEMARINE INSTITUTE OF NAUTICAL STUDIES AND RESEARCH, EFFURUN, DELTA STATE",
    InSt: 10,
    AFFL_ST_ID: 10,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 6009,
    INName: "GLORYLAND POLYTECHNIC, ANKPA, KOGI STATE",
    InSt: 22,
    AFFL_ST_ID: 22,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 6010,
    INName:
      "AWGU COLLEGE OF MEDICAL SCIENCE AND HEALTH TECHNOLOGY, ENUGU, ENUGU STATE",
    InSt: 14,
    AFFL_ST_ID: 14,
    INTYP: 43,
    Inst_Cat: "PI",
    category: "Private Innovation Institution",
  },
  {
    INID: 6011,
    INName: "DE-POTTER COLLEGE OF HEALTH TECHNOLOGY, ORU, OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 27,
    INTYP: 43,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 6012,
    INName: "PARAMOUNT POLYTECHNIC, EJIGBO, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 43,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 6012,
    INName: "USTEEM COLLEGE OF AGRICULTURE, OSOGBO, OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 43,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 6013,
    INName: "USTEEM COLLEGE OF AGRICULTURE, OSOGBO, OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 43,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 6014,
    INABV: "KSITM",
    INName:
      "KATSINA STATE INSTITUTE OF TECHNOLOGY AND MANAGEMENT, KATSINA STATE",
    InSt: 20,
    AFFL_ST_ID: 20,
    INTYP: 43,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 6015,
    INName:
      "SCHOOL OF ORTHOPAEDIC CAST TECHNOLOGY, LAGOS STATE UNIVERSITY TEACHING HOSPITAL (LASUTH)",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 43,
    Inst_Cat: "SP",
    category: "Polytechnic",
  },
  {
    INID: 6016,
    INName:
      "PEACELAND COLLEGE OF HEALTH SCIENCES AND TECHNOLOGY, ENUGU, ENUGU STATE",
    InSt: 14,
    AFFL_ST_ID: 14,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 6017,
    INName: "DISTINCT POLYTECHNIC, EKOSIN, OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },
  {
    INID: 6018,
    INName: "RAYHAAN UNIVERSITY, BIRNIN KEBBI, KEBBI STATE",
    InSt: 21,
    AFFL_ST_ID: 21,
    INTYP: 33,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 6019,
    INName: "MUHAMMAD KAMALUDEEN UNIVERSITY, ILORIN, KWARA STATE",
    InSt: 23,
    AFFL_ST_ID: 23,
    INTYP: 33,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 6020,
    INName: "SAM MARIS UNIVERSITY, SUPARE, ONDO STATE",
    InSt: 28,
    AFFL_ST_ID: 28,
    INTYP: 33,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 6021,
    INName: "ALETHEIA UNIVERSITY, AGO-IWOYE OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 27,
    INTYP: 33,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 6022,
    INName: "LUX MUNDI UNIVERSITY, UMUAHIA, ABIA STATE",
    InSt: 1,
    AFFL_ST_ID: 1,
    INTYP: 33,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 6023,
    INName: "MADUKA UNIVERSITY, EKWEGBE-NSUKKA, ENUGU STATE",
    InSt: 14,
    AFFL_ST_ID: 14,
    INTYP: 33,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 6024,
    INName: "PEACELAND UNIVERSITY, ENUGU, ENUGU STATE",
    InSt: 14,
    AFFL_ST_ID: 14,
    INTYP: 33,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 6024,
    INName: "AMADEUS UNIVERSITY, AMIZI, ABIA STATE",
    InSt: 1,
    AFFL_ST_ID: 1,
    INTYP: 33,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 6025,
    INName: "VISION UNIVERSITY, IKOGBO, OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 27,
    INTYP: 33,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 6026,
    INName: "AZMAN UNIVERSITY, KANO, KANO STATE",
    InSt: 19,
    AFFL_ST_ID: 19,
    INTYP: 33,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 6027,
    INName: "HUDA UNIVERSITY, GUSAU, ZAMFARA STATE",
    InSt: 36,
    AFFL_ST_ID: 36,
    INTYP: 33,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 6028,
    INName: "FRANCO BRITISH INTERNATIONAL UNIVERSITY, KADUNA, KADUNA STATE",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 33,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 6029,
    INName: "CANADIAN UNIVERSITY OF NIGERIA, ABUJA, FCT",
    InSt: 37,
    AFFL_ST_ID: 37,
    INTYP: 33,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 6030,
    INName: "MIVA OPEN UNIVERSITY, ABUJA, FCT",
    InSt: 37,
    AFFL_ST_ID: 37,
    INTYP: 33,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 6031,
    INName: "GERAR UNIVERSITY OF MEDICAL SCIENCES, IMPOPE-IJEBU, OGUN STATE",
    InSt: 27,
    AFFL_ST_ID: 27,
    INTYP: 33,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 6032,
    INName: "BRITISH CANADIAN UNIVERSITY, OBUDU, CROSS-RIVER STATE",
    InSt: 9,
    AFFL_ST_ID: 9,
    INTYP: 33,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 6033,
    INName: "HENSARD UNIVERSITY, TORU-ORUA, BAYELSA STATE",
    InSt: 6,
    AFFL_ST_ID: 6,
    INTYP: 33,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 6034,
    INName: "PHEONIX UNIVERISITY, AGWADA, NASARAWA STATE",
    InSt: 25,
    AFFL_ST_ID: 25,
    INTYP: 33,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 6035,
    INName: "WIGWE UNIVERISITY, ISIOKPO, RIVERS STATE",
    InSt: 32,
    AFFL_ST_ID: 32,
    INTYP: 33,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 6036,
    INName:
      "HILLSIDE UNIVERISITY OF SCIENCE AND TECHNOLOGY, OKEMESI-EKITI, EKITI STATE",
    InSt: 13,
    AFFL_ST_ID: 13,
    INTYP: 33,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 6037,
    INName: "UNIVERSITY ON THE NIGER, UMUNYA, ANAMBRA STATE",
    InSt: 4,
    AFFL_ST_ID: 4,
    INTYP: 33,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 6038,
    INName: "ELRAZI MEDICAL UNIVERSITY, KANO, KANO STATE",
    InSt: 19,
    AFFL_ST_ID: 19,
    INTYP: 33,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 6039,
    INName: "ELRAZI MEDICAL UNIVERSITY, KANO, KANO STATE",
    InSt: 19,
    AFFL_ST_ID: 19,
    INTYP: 33,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 6040,
    INName: "VENITE UNIVERSITY, ILORO-EKITI, EKITI STATE",
    InSt: 13,
    AFFL_ST_ID: 13,
    INTYP: 33,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 6041,
    INName: "SHANAHAN UNIVERSITY, ONITSHA, ANAMBRA STATE",
    InSt: 4,
    AFFL_ST_ID: 4,
    INTYP: 33,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 6042,
    INName: "THE DUKE MEDICAL UNIVERSITY, CALABAR, CROSS RIVER STATE",
    InSt: 9,
    AFFL_ST_ID: 9,
    INTYP: 33,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 6043,
    INName: "MERCY MEDICAL UNIVERSITY, IWARA, IWO, OSUN STATE",
    InSt: 29,
    AFFL_ST_ID: 29,
    INTYP: 33,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 6044,
    INName: "COSMOPOLITAN UNIVERSITY, ABUJA FCT",
    InSt: 37,
    AFFL_ST_ID: 37,
    INTYP: 33,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 6045,
    INName: "ICONIC OPEN UNIVERSITY SOKOTO, SOKOTO STATE",
    InSt: 33,
    AFFL_ST_ID: 33,
    INTYP: 33,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 6046,
    INName: "WEST MIDLAND OPEN UNIVERSITY, IBADAN, OYO STATE",
    InSt: 30,
    AFFL_ST_ID: 30,
    INTYP: 33,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 6047,
    INName: "AMAJ UNIVERSITY, ABUJA FCT",
    InSt: 37,
    AFFL_ST_ID: 37,
    INTYP: 33,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 6048,
    INName: "PRIME UNIVERSITY, ABUJA FCT",
    InSt: 37,
    AFFL_ST_ID: 37,
    INTYP: 33,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 6049,
    INName: "EL-AMIN UNIVERSITY, NIGER STATE",
    InSt: 26,
    AFFL_ST_ID: 26,
    INTYP: 33,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 6050,
    INName: "COLLEGE OF PETROLEUM AND ENERGY STUDIES KADUNA, KADUNA STATE",
    InSt: 18,
    AFFL_ST_ID: 18,
    INTYP: 33,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 6051,
    INName: "JEWEL UNIVERSITY, GOMBE, GOMBE STATE",
    InSt: 15,
    AFFL_ST_ID: 15,
    INTYP: 33,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 6052,
    INName:
      "NIGERIAN UNIVERSITY OF TECHNOLOGY AND MANAGEMENT, APAPA, LAGOS STATE",
    InSt: 24,
    AFFL_ST_ID: 24,
    INTYP: 33,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 6053,
    INName: "AL-MUHIBBAH OPEN UNIVERSITY, ABUJA, FCT",
    InSt: 37,
    AFFL_ST_ID: 37,
    INTYP: 33,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 6054,
    INName: "AL-BAYAN UNIVERSITY, ANKPA, KOGI STATE",
    InSt: 22,
    AFFL_ST_ID: 22,
    INTYP: 33,
    Inst_Cat: "PU",
    category: "University",
  },
  {
    INID: 6055,
    INName: "Federal College of Education (Technical) KEANA NASARAWA STATE",
    InSt: 25,
    AFFL_ST_ID: 25,
    INTYP: 51,
    Inst_Cat: "FC",
    category: "College of Education",
  },
  {
    INID: 6056,
    INName: "LOAM POLYTECHNIC, IKONO, AKWA IBOM STATE",
    InSt: 3,
    AFFL_ST_ID: 3,
    INTYP: 33,
    Inst_Cat: "PP",
    category: "Polytechnic",
  },

  // {
  //   INID: 6057,
  //   INName: "WIGWE UNIVERSITY, ISIOKPO, RIVERS STATE",
  //   InSt: 32,
  //   AFFL_ST_ID: 32,
  //   INTYP: 13,
  //   Inst_Cat: "PU",
  //   category: "University",
  // },
  // {
  //   INID: 6018,
  //   INName: "ZIBEH  INSTITUTE OF  TECHNOLOGY, JOS, PLATEAU STATE",
  //   InSt: 31,
  //   AFFL_ST_ID: 31,
  //   INTYP: 43,
  //   Inst_Cat: "P1",
  //   category: "Private Innovation Institution",
  // },
];

export const states = [
  {
    ST_ID: 1,
    ST_ABREVIATION: "ABI",
    ST_NAME: "ABIA",
    REGION: "South-East",
  },
  {
    ST_ID: 2,
    ST_ABREVIATION: "ADA",
    ST_NAME: "ADAMAWA",
    REGION: "North-East",
  },
  {
    ST_ID: 3,
    ST_ABREVIATION: "AKI",
    ST_NAME: "AKWA IBOM",
    REGION: "South-South",
  },
  {
    ST_ID: 4,
    ST_ABREVIATION: "ANA",
    ST_NAME: "ANAMBRA",
    REGION: "South-East",
  },
  {
    ST_ID: 5,
    ST_ABREVIATION: "BAU",
    ST_NAME: "BAUCHI",
    REGION: "North-East",
  },
  {
    ST_ID: 6,
    ST_ABREVIATION: "BAY",
    ST_NAME: "BAYELSA",
    REGION: "South-South",
  },
  {
    ST_ID: 7,
    ST_ABREVIATION: "BEN",
    ST_NAME: "BENUE",
    REGION: "North-Central",
  },
  {
    ST_ID: 8,
    ST_ABREVIATION: "BOR",
    ST_NAME: "BORNO",
    REGION: "North-East",
  },
  {
    ST_ID: 9,
    ST_ABREVIATION: "CRS",
    ST_NAME: "CROSS RIVER",
    REGION: "South-South",
  },
  {
    ST_ID: 10,
    ST_ABREVIATION: "DEL",
    ST_NAME: "DELTA",
    REGION: "South-South",
  },
  {
    ST_ID: 11,
    ST_ABREVIATION: "EBO",
    ST_NAME: "EBONYI",
    REGION: "South-East",
  },
  {
    ST_ID: 12,
    ST_ABREVIATION: "EDO",
    ST_NAME: "EDO",
    REGION: "South-South",
  },
  {
    ST_ID: 13,
    ST_ABREVIATION: "EKI",
    ST_NAME: "EKITI",
    REGION: "South-West",
  },
  {
    ST_ID: 14,
    ST_ABREVIATION: "ENU",
    ST_NAME: "ENUGU",
    REGION: "South-East",
  },
  {
    ST_ID: 15,
    ST_ABREVIATION: "GOM",
    ST_NAME: "GOMBE",
    REGION: "North-East",
  },
  {
    ST_ID: 16,
    ST_ABREVIATION: "IMO",
    ST_NAME: "IMO",
    REGION: "South-East",
  },
  {
    ST_ID: 17,
    ST_ABREVIATION: "JIG",
    ST_NAME: "JIGAWA",
    REGION: "North-West",
  },
  {
    ST_ID: 18,
    ST_ABREVIATION: "KAD",
    ST_NAME: "KADUNA",
    REGION: "North-West",
  },
  {
    ST_ID: 19,
    ST_ABREVIATION: "KAN",
    ST_NAME: "KANO",
    REGION: "North-West",
  },
  {
    ST_ID: 20,
    ST_ABREVIATION: "KAT",
    ST_NAME: "KATSINA",
    REGION: "North-West",
  },
  {
    ST_ID: 21,
    ST_ABREVIATION: "KEB",
    ST_NAME: "KEBBI",
    REGION: "North-West",
  },
  {
    ST_ID: 22,
    ST_ABREVIATION: "KOG",
    ST_NAME: "KOGI",
    REGION: "North-Central",
  },
  {
    ST_ID: 23,
    ST_ABREVIATION: "KWA",
    ST_NAME: "KWARA",
    REGION: "North-Central",
  },
  {
    ST_ID: 24,
    ST_ABREVIATION: "LAG",
    ST_NAME: "LAGOS",
    REGION: "South-West",
  },
  {
    ST_ID: 25,
    ST_ABREVIATION: "NAS",
    ST_NAME: "NASARAWA",
    REGION: "North-Central",
  },
  {
    ST_ID: 26,
    ST_ABREVIATION: "NIG",
    ST_NAME: "NIGER",
    REGION: "North-Central",
  },
  {
    ST_ID: 27,
    ST_ABREVIATION: "OGU",
    ST_NAME: "OGUN",
    REGION: "South-West",
  },
  {
    ST_ID: 28,
    ST_ABREVIATION: "OND",
    ST_NAME: "ONDO",
    REGION: "South-West",
  },
  {
    ST_ID: 29,
    ST_ABREVIATION: "OSU",
    ST_NAME: "OSUN",
    REGION: "South-West",
  },
  {
    ST_ID: 30,
    ST_ABREVIATION: "OYO",
    ST_NAME: "OYO",
    REGION: "South-West",
  },
  {
    ST_ID: 31,
    ST_ABREVIATION: "PLA",
    ST_NAME: "PLATEAU",
    REGION: "North-Central",
  },
  {
    ST_ID: 32,
    ST_ABREVIATION: "RIV",
    ST_NAME: "RIVERS",
    REGION: "South-South",
  },
  {
    ST_ID: 33,
    ST_ABREVIATION: "SOK",
    ST_NAME: "SOKOTO",
    REGION: "North-West",
  },
  {
    ST_ID: 34,
    ST_ABREVIATION: "TAR",
    ST_NAME: "TARABA",
    REGION: "North-East",
  },
  {
    ST_ID: 35,
    ST_ABREVIATION: "YOB",
    ST_NAME: "YOBE",
    REGION: "North-East",
  },
  {
    ST_ID: 36,
    ST_ABREVIATION: "ZAM",
    ST_NAME: "ZAMFARA",
    REGION: "North-West",
  },
  {
    ST_ID: 37,
    ST_ABREVIATION: "FCT",
    ST_NAME: "FCT",
    REGION: "North-Central",
  },
];

export const institutions = rawInstitutions.sort((a, b) =>
  a.INName > b.INName ? 1 : b.INName > a.INName ? -1 : 0
);

export const InstitutionType = [
  "University",
  "Degree Awarding Institutions (Affliate)",
  "Polytechnic",
  "College of Education",
  "Private Innovation Institution",
];
export const OtherInstitutions = [
  "Nigeria Association of the Blind",
  "Joint National Association of Persons with Disabilities",
  "JAMB",
  "WAEC",
  "NECO",
  "NABTEB",
  "The Albino Foundation",
  "Nigeria National Association of Deaf",
  "National Parents-Teachers Association of Nigeria",
  "Staff unions of universities, polytechnics and colleges of education",
  "NANS",
  "OTHERS",
];
export const OtherInsitutionsRoles = [
  "President",
  "Registrar",
  "CEO",
  "Director",
  "Proprietor",
  "Student",
  "Others",
];

const participants = [
  {
    role: "Vice Chancellor",
    category: "A",
  },
  { role: "Rector", category: "A" },
  { role: "Provost", category: "A" },
  { role: "Registrar", category: "B" },
  { role: "Director Academic Planning", category: "C" },
  { role: "Others", category: "X" },
  { role: "Student", category: "X" },
];

export const otherParticipants = [
  { role: "Registrar", category: "B" },
  { role: "Admissions Officer", category: "C" },
  { role: "Head of ICT", category: "D" },
];

export const universityParticipants = [
  participants[0],
  participants[6],
  participants[5],
];

export const polyTechnicParticipants = [
  participants[1],
  participants[6],

  // participants[3],
  // participants[4],
  participants[5],
];

export const ieiParticipants = [
  participants[1],
  participants[6],
  // participants[3],
  // participants[4],
  participants[5],
];

export const coeParticipants = [
  participants[2],
  participants[6],
  // participants[3],
  // participants[4],
  participants[5],
];

export const degAwardingParticipants = [
  participants[2],
  participants[6],
  // participants[3],
  // participants[4],
  participants[5],
];
export const trainingVenues = [
  "NEW 1000 SEATER AUDITORIUM, PERMANENT SITE, FCT, COE-ZUBA, ABUJA-FCT",
  "UNIVERISTY OF IBADAN, OYO STATE",
];

export const trainingVenue = (region) => {
  switch (region) {
    case "NORTH-EAST":
      return "SENATOR ALI WAKILI ASUP SECRATARIAT AUDITORIUM, FEDERAL POLYTECHNIC, BAUCHI, BAUCHI STATE";
    case "NORTH-WEST":
      return "AUDITORIUM, CBN CENTRE FOR ECONOMICS AND FINANCE, AHMADU BELLO UNIVERSITY, MAIN CAMPUS, SAMARU, ZARIA, KADUNA STATE.";
    case "NORTH-CENTRAL":
      return "NEW 1000 SEATER AUDITORIUM, PERMANENT SITE, FCT, COE-ZUBA, ABUJA-FCT";
    case "SOUTH-EAST":
      return "CBN CENTRE FOR ECONOMICS AND FINANCE AUDITORIUM, UNIVERSITY OF NIGERIA, ENUGU CAMPUS, ENUGU STATE";
    case "SOUTH-SOUTH":
      return "EBITIMI BANIGO AUDITORIUM, UNIVERSITY OF PORT HARCOURT, RIVERS STATE";
    case "SOUTH-WEST":
      return "UNIVERISTY OF IBADAN, OYO STATE";

    default:
      break;
  }
};
//"MAIN HALL UNIVERSITY OF NIGERIA NSUKKA, ENUGU CAMPUS (UNEC), ENUGU, ENUGU STATE";
export const trainingDate = (region) => {
  switch (region) {
    case "SOUTH-EAST":
      return "Date: Tuesday 13th Dec, 2022. Time: 08:00 AM";

    default:
      return "Date: Monday 12th Dec, 2022. Time: 08:00 AM";
  }
};

export const regionImages = [
  "https://images.unsplash.com/photo-1587572216308-c3ae99a2213b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTh8fG5pZ2VyaWF8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60",
  "https://images.unsplash.com/photo-1537372023620-37161b1ad8ac?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTJ8fG5pZ2VyaWF8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60",
  "https://images.unsplash.com/photo-1618828665011-0abd973f7bb8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bmlnZXJpYXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
  "https://images.unsplash.com/photo-1554457606-ed16c39db884?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8bmlnZXJpYXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
  "https://images.unsplash.com/photo-1473605768212-7e1f2c756179?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fGFmcmljYXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
  "https://images.unsplash.com/photo-1484318571209-661cf29a69c3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8YWZyaWNhfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
];

export const getRole = (roleCategory, institutionId) => {
  let category = "";
  const index = institutions.findIndex((c) => c.INID === institutionId);

  if (index !== -1) {
    if (institutions[index].category === "University") {
      if (roleCategory === "A") category = "Vice Chancelor";
      else if (roleCategory === "B") category = "Registrar";
      else if (roleCategory === "C") category = "Director Academic Planning";
      else if (roleCategory === "D") category = "Admissions Officer";
      else if (roleCategory === "E") category = "Head of ICT";
    } else if (
      institutions[index].category ===
        "Degree Awarding Institutions (Affliate)" ||
      institutions[index].category === "College of Education"
    ) {
      if (roleCategory === "A") category = "Provost";
      else if (roleCategory === "B") category = "Registrar";
      else if (roleCategory === "C") category = "Director Academic Planning";
      else if (roleCategory === "D") category = "Admissions Officer";
      else if (roleCategory === "E") category = "Head of ICT";
    } else if (
      institutions[index].category === "Polytechnic" ||
      institutions[index].category === "Private Innovation Institution"
    ) {
      if (roleCategory === "A") category = "Rector";
      else if (roleCategory === "B") category = "Registrar";
      else if (roleCategory === "C") category = "Director Academic Planning";
      else if (roleCategory === "D") category = "Admissions Officer";
      else if (roleCategory === "E") category = "Head of ICT";
    }
  }

  return category;
};
