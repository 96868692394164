import { LoadingButton } from "@mui/lab";
import { TextField, Typography } from "@mui/material";
import React, { useState, useContext } from "react";
import { httpService } from "../httpService";
import { AlertContext } from "../contexts/AlertContext";
import Swal from "sweetalert2";

export default function JambDataPage() {
  const [loading, setLoading] = useState(false);
  const { setAlertData } = useContext(AlertContext);
  const [email, setEmail] = useState("");
  const [participant, setParticipant] = useState(null);
  const [idCard, setIdCard] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const getData = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { data, error } = await httpService.post(
      "/api/retrievejambregistration",
      { email }
    );
    if (data) {
      setParticipant(data);
    }
    if (error) {
      setAlertData({ open: true, message: error, severity: "error" });
    }
    setLoading(false);
  };

  const editRegistration = (e) => {
    e.preventDefault();
    Swal.fire({
      icon: "question",
      title: "Edit Id Card",
      text: "Are you sure the ID Card provided is correct?",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setSubmitting(true);
        const { data, error } = await httpService.patch(
          `/api/editjambregistration/${participant._id}`,
          { idCard }
        );
        if (data) {
          // getData();
          setAlertData({ open: true, message: data, severity: "success" });
        }
        if (error) {
          setAlertData({ open: true, message: error, severity: "error" });
        }
        setSubmitting(false);
      }
    });
  };
  return (
    <div>
      <div className="mt-5 container">
        <Typography variant="h6" fontWeight={700}>
          JAMB STAFF REGISTRATION RETRIEVAL
        </Typography>
        <div className="mt-2 mb-2">
          <Typography color={"error"}>
            This page is restricted to JAMB staff only
          </Typography>
        </div>

        <div className="mt-3 col-lg-4">
          <form onSubmit={getData}>
            <TextField
              fullWidth
              required
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              label="Email Address"
              helperText="Please enter the email address you used in your registration"
            />
            <div className="mt-2">
              <LoadingButton
                loading={loading}
                type="submit"
                variant="contained"
              >
                retrieve registration
              </LoadingButton>
            </div>
          </form>
        </div>
        {participant && (
          <div className="mt-3 ">
            <div className="mb-3">
              <Typography variant="caption">Name</Typography>
              <Typography>
                {participant.firstName} {participant.lastName}
              </Typography>
            </div>
            <div className="mb-3">
              <Typography variant="caption">Role</Typography>
              <Typography>{participant.role}</Typography>
            </div>
            <div className="mb-3">
              <Typography variant="caption">Department</Typography>
              <Typography>{participant.department}</Typography>
            </div>
            {participant.idCard && (
              <div className="mb-3">
                <Typography variant="caption">ID Card:</Typography>
                <Typography textTransform={"uppercase"}>
                  {participant.idCard}
                </Typography>
              </div>
            )}
            <div className="mb-3 col-lg-4">
              <form onSubmit={editRegistration}>
                <TextField
                  fullWidth
                  label="ID Card number"
                  helperText="Please provide your ID Card number"
                  onChange={(e) => setIdCard(e.target.value)}
                />
                <div className="mt-2">
                  <LoadingButton
                    loading={submitting}
                    type="submit"
                    variant="outlined"
                  >
                    submit
                  </LoadingButton>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
