import { Typography } from "@mui/material";
import React from "react";

export default function Notification() {
  return (
    <div>
      <div className="mt-5 container">
        <Typography variant="h4" fontWeight={700} color="GrayText" gutterBottom>
          Registration has ended.
        </Typography>
        {/* <Typography>
          {" "}
          If you are a critical stakeholder please call: 08066507557 or
          09054731498
        </Typography> */}
      </div>
    </div>
  );
}
