import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.png";
function NavigationBar() {
  const navigate = useNavigate();

  const links = [
    { path: "/", name: "Home" },
    { path: "/registration", name: "Registration" },
    { path: "/programme", name: "Programme" },
    { path: "/retrieveregistration", name: "Retrieve Registration" },
    { path: "/jambpage", name: "Jamb Registration" },
  ];
  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="#home">
          <img src={logo} height={30} width={35} />
          JEOG
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {links.map((link) => (
              <Nav.Link onClick={() => navigate(link.path)}>
                {link.name}
              </Nav.Link>
            ))}
          </Nav>
          <Nav className="ms-auto">
            <Nav.Link onClick={() => navigate("/admin")}>Admin Login</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;
