export const jambDepartments = [
  "ABIA",
  "ADAMAWA",
  "ANAMBRA",
  "BAUCHI",
  "BAYELSA",
  "BENUE",
  "BORNO",
  "CROSS RIVER",
  "DELTA",
  "EBONYI",
  "EDO",
  "EKITI",
  "ENUGU",
  "GOMBE",
  "IMO",
  "JIGAWA",
  "KADUNA",
  "KANO",
  "KATSINA",
  "KEBBI",
  "KOGI",
  "KWARA",
  "LAGOS",
  "NASARAWA",
  "NIGER",
  "OGUN",
  "ONDO",
  "OSUN",
  "OYO",
  "PLATEAU",
  "RIVERS",
  "SOKOTO",
  "YOBE",
  "ZAMFARA",
  "FCT",
  "REGISTRAR'S OFFICE",
  "TEST ADMINISTRATION",
  "TEST DEVELOPMENT",
  "INFORMATION TECHNOLOGY SERVICES",
  "SPECIAL DUTIES",
  "GENERAL SERVICES",
  "QUALITY ASSURANCE",
  "FINANCE AND ACCOUNTS",
  "PSYCHOMETRICS",
  "ADMISSIONS",
  "HUMAN RESOURCES",
  "AUDIT",
  "LEGAL SERVICES",
];

export const jambRoles = [
  "Director",
  "State Coordinator",
  "Deputy Director",
  "Assistant Director",
  "Conraiss 12",
  "Conraiss 11",
  "Conraiss 10",
  "Conraiss 9",
  "Conraiss 8",
  "Conraiss 7",
];
