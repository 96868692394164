import {
  Button,
  CardActionArea,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState, useEffect, useContext, useRef } from "react";
import { Modal, Table } from "react-bootstrap";
import { httpService } from "../httpService";
import {
  Delete,
  FileDownloadRounded,
  Person,
  Troubleshoot,
} from "@mui/icons-material";
import { AlertContext } from "../contexts/AlertContext";
import Swal from "sweetalert2";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { LoadingButton } from "@mui/lab";

export default function RegistrationDashboard() {
  const [participants, setParticipants] = useState([]);
  const [loading, setLoading] = useState(false);
  const [jambStaff, setJambStaff] = useState(0);
  const [show, setShow] = useState(false);
  const [showO, setShowO] = useState(false);
  const [otherregistrants, setotherregistrants] = useState(0);

  const tableRef = useRef(null);
  const jambTableRef = useRef(null);
  const handleShow = () => {
    setShow(true);
  };
  const handleShowO = () => {
    setShowO(true);
  };

  const handleClose = () => {
    setShow(false);
  };
  const handleCloseO = () => {
    setShowO(false);
  };
  const getData = async () => {
    setLoading(true);
    const { data } = await httpService("/api/participants");
    if (data) {
      setParticipants(data);

      let figure = 0;
      let figure2 = 0;

      data.forEach((c) => c.organisation === "JAMB" && figure++);
      setJambStaff(figure);
      data.forEach((c) => c.organisation !== "JAMB" && figure2++);
      setotherregistrants(figure2);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      <div className="mt-5 mb-5 container">
        <div className="mb-2">
          <Typography variant="h4" fontWeight={700}>
            Registration Dashboard
          </Typography>
        </div>
        {loading && <CircularProgress />}
        <div className="row">
          <div className="col-lg-4 alert alert-danger">
            <Typography variant="h5">
              TOTAL REGISTRATIONS <Person />
            </Typography>
            <div className="mt-2">
              <Typography variant="h3">{participants.length}</Typography>
            </div>
          </div>
          <div className="col-lg-4">
            <CardActionArea
              onClick={handleShow}
              className="p-3 shadow-sm bg-info rounded-3"
            >
              <Typography variant="h5" color="white">
                JAMB STAFF <Person />
              </Typography>
              <div className="mt-2">
                <Typography variant="h3" color="white">
                  {jambStaff}
                </Typography>
              </div>
            </CardActionArea>
          </div>
          <div className="col-lg-4">
            <CardActionArea
              onClick={handleShowO}
              className="p-3 shadow-sm bg-warning rounded-3"
            >
              <Typography variant="h5" color="white">
                OTHERS <Person />
              </Typography>
              <div className="mt-2">
                <Typography variant="h3" color="white">
                  {otherregistrants}
                </Typography>
              </div>
            </CardActionArea>
          </div>
        </div>
        {participants.length > 0 && tableRef.current && (
          <DownloadTableExcel
            filename={"JEOG DATA"}
            sheet="registrations"
            currentTableRef={tableRef.current}
          >
            <Button variant="contained"> download data </Button>
          </DownloadTableExcel>
        )}
        {/* <Button variant="contained" startIcon={<FileDownloadRounded />}>
          download data
        </Button> */}
        <Table ref={tableRef} striped borderless>
          <thead>
            <tr>
              <th>S/N</th>
              <th>Name</th>
              <th>Role</th>
              <th>Institution/Organisation</th>
              <th>Email Address</th>
              <th>Code</th>
              <th>Phone Number</th>
              <th>Department</th>
              {/* <th>Edit</th> */}
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {participants
              .sort((a, b) =>
                a.participantId > b.participantId
                  ? 1
                  : b.participantId > a.participantId
                  ? -1
                  : 0
              )
              .map((c, i) => (
                <tr>
                  <td>{i + 1}</td>
                  <td>
                    <Typography textTransform={"uppercase"}>
                      {c.title} {c.firstName} {c.lastName}
                    </Typography>
                  </td>
                  <td>
                    <Typography>{c.role}</Typography>
                  </td>
                  <td>
                    <Typography>{c.institution || c.organisation}</Typography>
                  </td>
                  <td>
                    <Typography>{c.email}</Typography>
                  </td>
                  <td>
                    <Typography textTransform={"uppercase"}>
                      {c.participantId}
                    </Typography>
                  </td>
                  <td>
                    <Typography>{c.phoneNumber}</Typography>
                  </td>
                  <td>
                    <Typography>{c.department || "N/A"}</Typography>
                  </td>
                  {/* <td>
                    <ResolveDuplicate id={c._id} getData={getData} />
                  </td> */}
                  <td>
                    <DeleteItem participant={c} getData={getData} />
                  </td>
                </tr>
              ))}
          </tbody>
          <tfoot>
            <tr>
              <th>S/N</th>
              <th>Name</th>
              <th>Role</th>
              <th>Institution/Organisation</th>
              <th>Email Address</th>
              <th>Code</th>
              <th>Department</th>
              {/* <th>Edit</th> */}
              <th>Delete</th>
            </tr>
          </tfoot>
        </Table>
      </div>
      <Modal size="xl" onHide={handleClose} show={show}>
        <Modal.Header closeButton>
          <Modal.Title>JAMB STAFF</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {participants.filter((c) => c.organisation === "JAMB").length >
              0 && (
              <DownloadTableExcel
                filename={"JAMB PARTICIPANTS"}
                sheet="jamb participants"
                currentTableRef={jambTableRef.current}
              >
                <Button variant="contained"> download jamb data </Button>
              </DownloadTableExcel>
            )}
          </div>
          <Table ref={jambTableRef} striped borderless>
            <thead>
              <tr>
                <th>S/N</th>
                <th>Name</th>
                <th>Role</th>
                <th>Department</th>
                <th>ID Card</th>
                <th>Phone Number</th>
              </tr>
            </thead>
            <tbody>
              {participants
                .filter((c) => c.organisation === "JAMB")
                .map((c, i) => (
                  <tr>
                    <td>{i + 1}</td>
                    <td>
                      <Typography textTransform={"uppercase"}>
                        {c.title} {c.firstName} {c.lastName}
                      </Typography>
                    </td>
                    <td>
                      <Typography>{c.role}</Typography>
                    </td>
                    <td>
                      <Typography>{c.department || "N/A"}</Typography>
                    </td>
                    <td>
                      <Typography textTransform={"uppercase"}>
                        {c.idCard}
                      </Typography>
                    </td>
                    <td>
                      <Typography>{c.phoneNumber}</Typography>
                    </td>
                  </tr>
                ))}
            </tbody>
            <tfoot>
              <tr>
                <th>S/N</th>
                <th>Name</th>
                <th>Role</th>
                <th>Department</th>
                <th>ID Card</th>
                <th>Phone Number</th>
              </tr>
            </tfoot>
          </Table>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <Modal size="lg" onHide={handleCloseO} show={showO}>
        <Modal.Header closeButton>
          <Modal.Title>OTHERS</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped borderless>
            <thead>
              <tr>
                <th>S/N</th>
                <th>Name</th>
                <th>Role</th>
                <th>Institution/Organisation</th>
              </tr>
            </thead>
            <tbody>
              {participants
                .filter((c) => c.organisation !== "JAMB")
                .map((c, i) => (
                  <tr>
                    <td>{i + 1}</td>
                    <td>
                      <Typography textTransform={"uppercase"}>
                        {c.title} {c.firstName} {c.lastName}
                      </Typography>
                    </td>
                    <td>
                      <Typography>{c.role}</Typography>
                    </td>
                    <td>
                      <Typography>{c.institution || c.organisation}</Typography>
                    </td>
                  </tr>
                ))}
            </tbody>
            <tfoot>
              <tr>
                <th>S/N</th>
                <th>Name</th>
                <th>Role</th>
                <th>Institution/Organisation</th>
              </tr>
            </tfoot>
          </Table>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  );
}

function DeleteItem(props) {
  const [deleting, setDeleting] = useState(false);
  const { setAlertData } = useContext(AlertContext);
  const deleteRegistration = () => {
    Swal.fire({
      icon: "question",
      title: "Delete Registration",
      text: "Are you sure you want to delete the registration for this participant?",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: "info",
          title: "Password Required",
          text: "Please enter admin password to delete this registration",
          input: "password",
        }).then(async (value) => {
          if (value.value !== "jeog1234")
            return setAlertData({
              message: "Password provided is incorrect",
              severity: "error",
              open: true,
            });

          setDeleting(true);
          const { data } = await httpService.delete(
            `api/deleteregistration/${props.participant._id}`
          );
          if (data) {
            props.getData();
            setAlertData({ message: data, severity: "success", open: true });
          }

          setDeleting(false);
        });
      }
    });
  };
  return (
    <IconButton onClick={deleteRegistration}>
      {deleting ? (
        <CircularProgress color="error" size={15} />
      ) : (
        <Delete color="error" />
      )}
    </IconButton>
  );
}

function ResolveDuplicate({ id, getData }) {
  const [loading, setLoading] = useState(false);
  const { setAlertData } = useContext(AlertContext);
  const resolveDuplicate = async () => {
    setLoading(Troubleshoot);
    const { data } = await httpService(`/api/resolveduplicate/${id}`);

    if (data) {
      getData();
      setAlertData({ open: true, message: data, severity: "success" });
    }
    setLoading(false);
  };
  return (
    <LoadingButton loading={loading} onClick={resolveDuplicate}>
      Resolve duplicate
    </LoadingButton>
  );
}
