import { Button, CircularProgress, Typography } from "@mui/material";
import React, { useContext, useState, useEffect, useRef } from "react";
import logo from "../assets/images/logo.png";
import { useParams } from "react-router-dom";
import { httpService } from "../httpService";
import { AlertContext } from "../contexts/AlertContext";
import ReactToPrint from "react-to-print";
import { Print } from "@mui/icons-material";

export default function Printout() {
  const [participant, setParticipant] = useState(null);
  const { setAlertData } = useContext(AlertContext);
  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  const printRef = useRef();

  const getData = async () => {
    setLoading(true);
    const { data, error } = await httpService.post("/api/getdata", {
      _id: id,
    });

    if (data) {
      setParticipant(data);
    }
    if (error) {
      setAlertData({ open: true, message: error, severity: "error" });
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      <div className="container mt-5 mb-5">
        {loading && <CircularProgress />}
        {participant && (
          <>
            <div ref={printRef}>
              <div className="text-center">
                <img src={logo} width={120} height={100} alt="Jamb logo" />
                <Typography variant="h4" fontWeight={700}>
                  JAMB EQUAL OPPORTUNITY GROUP
                </Typography>
                <Typography>Registration Slip</Typography>
              </div>

              <div className="mt-3">
                <Typography variant="caption">Name:</Typography>
                <Typography variant="h6" fontWeight={700}>
                  {participant.title} {participant.firstName}{" "}
                  {participant.lastName}
                </Typography>
              </div>
              <div className="mt-3">
                <Typography variant="caption">Email Address:</Typography>
                <Typography variant="h6" fontWeight={700}>
                  {participant.email}
                </Typography>
              </div>
              <div className="mt-3">
                <Typography variant="caption">Phone Number:</Typography>
                <Typography variant="h6" fontWeight={700}>
                  {participant.phoneNumber}
                </Typography>
              </div>
              <div className="mt-3">
                <Typography variant="caption">
                  Organisation/Institution:
                </Typography>
                <Typography variant="h6" fontWeight={700}>
                  {participant.organisation || participant.institution}
                </Typography>
              </div>
              <div className="mt-3">
                <Typography variant="caption">Role:</Typography>
                <Typography variant="h6" fontWeight={700}>
                  {participant.role}
                </Typography>
              </div>
              <div className="mt-3">
                <Typography variant="caption">Participant ID:</Typography>
                <Typography
                  variant="h6"
                  fontWeight={700}
                  textTransform={"uppercase"}
                >
                  {participant.participantId}
                </Typography>
              </div>
            </div>
            <div className="mt-3">
              <ReactToPrint
                trigger={() => (
                  <Button variant="contained" endIcon={<Print />}>
                    print
                  </Button>
                )}
                bodyClass="p-5"
                content={() => printRef.current}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
