import axios from "axios";

const backendURL = process.env.REACT_APP_BACKEND_URL;
//const backendURL = "http://localhost:6789";
const axiosConfig = {
  baseURL: backendURL,
  timeout: 15000,
  // withCredentials: "include",
  headers: {
    "Content-Type": "application/json",
  },
};

const httpService = axios.create(axiosConfig);

httpService.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response) {
      return { error: error.response.data, status: error.response.status };
    }

    return { error: "Lost connection to the server" };
  }
);

export { httpService };
